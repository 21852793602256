import React, { useState, useEffect } from 'react'
import { useDebounce } from 'use-debounce'
import SimpleModal from '../../../../components/Modal/Modal'
import AdminTable from '../../../../components/tables/AdminTable'
import { axiosAuth as axios } from '../../../../util/axios-instance'
import latToCyr from '../../../../util/latToCyr'

const SchoolNotifications = () => {
    const [load, setLoad] = useState(false)
    const [notifications, setNotifications] = useState([])
    const [search, setSearch] = useState({ eng: '', key: '', srb: '' })
    const [searchDebounced] = useDebounce(search, 2000)
    const [totalResults, setTotalResults] = useState(0)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const getNotifications = async () => {
        try {
            setLoad(true)

            const url =
                // prettier-ignore
                searchDebounced.eng === ''
                ? `/api/school-notification?page=${page+1}&limit=${rowsPerPage}`
                : `/api/school-notification?eng=${searchDebounced.eng}&srb=${searchDebounced.srb
                }&page=${page+1}&limit=${rowsPerPage}`

            const response = await axios.get(url)

            const tableData = response?.data?.notifications?.reduce(
                (acc, currentEl) => {
                    // const mailPoslat = currentEl?.mailPoslat
                    //     ?.split('T')[0]
                    //     .split('-')
                    // const mailPotvrdjen = currentEl?.mailPotvrdjen
                    //     ?.split('T')[0]
                    //     .split('-')

                    const addElement = {
                        lokacija_ID: latToCyr(
                            currentEl.lokacija.lokacija_ID.toUpperCase()
                        ),
                        ustanova_ID: latToCyr(
                            currentEl.lokacija.ustanova_ID.toUpperCase()
                        ),
                        naziv: currentEl.lokacija.naziv,
                        naselje: currentEl.lokacija.naselje,
                        opstina: currentEl.lokacija.opstina,
                        mailPoslat: currentEl.mailPoslat,
                        potvrdaSkole: currentEl.potvrdaSkole ? 'ДА' : 'НЕ',
                        mailPotvrdjen:
                            currentEl.potvrdaSkole && currentEl.mailPotvrdjen
                                ? currentEl.mailPotvrdjen
                                : '/',
                    }
                    acc.push(addElement)
                    return acc
                },
                []
            )
            setTotalResults(response.data.totalResults)
            setNotifications(tableData)
            setLoad(false)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getNotifications()
    }, [page, rowsPerPage, searchDebounced.eng, searchDebounced.srb])

    return (
        <div style={{ padding: 50 }}>
            {load && <SimpleModal />}
            <AdminTable
                data={notifications}
                name="invSchoolNotifications"
                tableHeaders={[
                    { field: 'lokacija_ID', header: 'ИД локације' },
                    { field: 'ustanova_ID', header: 'ИД установе' },
                    { field: 'naziv', header: 'Назив' },
                    { field: 'naselje', header: 'Насеље' },
                    { field: 'opstina', header: 'Општина' },
                    { field: 'mailPoslat', header: 'Школа обавештена' },
                    { field: 'mailPotvrdjen', header: 'Датум потврде' },
                    { field: 'potvrdaSkole', header: 'Школа потврдила' },
                ]}
                setSearch={setSearch}
                search={search}
                totalResults={totalResults}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                onRowClick={() => {}}
                actionBodyTemplate={() => {}}
                model="school-notification"
                title="Списак обавештених школа"
                searchColumns={[
                    { datavalue: 'lokacija_ID', label: 'ИД локације' },
                    { datavalue: 'ustanova_ID', label: 'ИД установе' },
                    { datavalue: 'naziv', label: 'Назив' },
                    { datavalue: 'naselje', label: 'Насеље' },
                    { datavalue: 'opstina', label: 'Општина' },
                    { datavalue: 'mailPoslat', label: 'Школа обавештена' },
                    { datavalue: 'potvrdaSkole', label: 'Школа потврдила' },
                ]}
                exportFileName="Обавештене школе"
            />
        </div>
    )
}

export default SchoolNotifications
