import React from 'react'
import { Formik, Form, Field, FieldArray } from 'formik'

// import HighlightOffIcon from '@material-ui/icons/HighlightOff'

import {
    Button,
    Grid,
    Typography,
    makeStyles,
    CircularProgress,
    // Paper,
    Tooltip,
} from '@material-ui/core'

import PropTypes from 'prop-types'
import { trigger } from 'swr'
import { TextField } from 'formik-material-ui'
import { array, string, object } from 'yup'

import { useParams } from 'react-router-dom'
import { axiosAuth } from '../../../util/axios-instance'
import EditSelects from '../../../components/FormsUI/Select/EditSelects'

/* eslint-disable */
const useStyles = makeStyles((theme) => ({
    errorColor: {
        color: theme.palette.error.main,
    },
    noWrap: {
        [theme.breakpoints.up('sm')]: {
            flexWrap: 'nowrap',
        },
    },
    fieldWrapper: {
        marginRight: '1rem',
        marginBottom: '1rem',
    },

    add: {
        padding: '20px 0',
        background: '#FCFCFC',
        margin: 0,
    },
    btnAdd: {
        margin: '10px 0',
        background: '#1E8637',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#4a9e5e',
            color: '#fff',
        },
    },
    subtitleWrapper: {
        marginBottom: '2rem',
    },
    addAll: {
        marginTop: 30,
    },

    formWidth: {
        width: 300,
    },

    delbtn: {
        color: '#a52408',
        fontSize: '1.7rem',
        '&:hover': {
            color: '#ffff',
            backgroundColor: '#f36344',
            borderRadius: '50%',
            cursor: 'pointer',
        },
    },
    fieldWrapper: {
        margin: '10px 0',
    },
}))

const formData = [
    {
        naziv: 'Статус Амрес мреже',
        vrednost: '',
    },
    {
        naziv: 'Измерена брзина линка',
        vrednost: '',
    },
]

const A13FirstStep = ({
    setSeverity,
    setSnackbar,
    setError,
    handleNext,
    activity,
}) => {
    const classes = useStyles()
    const { id } = useParams()

    const amresOptions = [
        'Функционише у целој школи',
        'Функционише у делу школе',
        'Функционише али се не користи',
        'Не фунцкионише',
    ]

    const initialDataValues = {
        additionalData:
            activity?.podaci.length > 0 ? activity.podaci : [...formData],
    }
    const submitAdditionalData = async (values) => {
        try {
            const { data } = await axiosAuth.patch(`/api/activity/${id}`, {
                podaci: values.additionalData,
            })

            // console.log(data)
            setSeverity('success')
            setSnackbar({
                open: true,
                message: 'Успешно унесени подаци.',
            })
            trigger(`/api/activity/${id}`)
            handleNext()
        } catch (error) {
            setSeverity('warning')
            setSnackbar({
                open: true,
                message: 'Поља нису успешно додата!',
            })
            setError(error)
        }
    }

    // console.log(initialDataValues)
    return (
        <Grid container justifyContent="center">
            <Formik
                initialValues={initialDataValues}
                validationSchema={object({
                    additionalData: array(
                        object({
                            naziv: string()
                                .required('обавезно поље')
                                .min(1, 'минимално 1 карактер')
                                .max(50, 'максимално 50 карактера'),
                            vrednost: string().required('обавезно поље'),
                        })
                    ),
                })}
                onSubmit={submitAdditionalData}
            >
                {({ values, isSubmitting }) => (
                    <Form autoComplete="off">
                        <Grid container justifyContent="flex-start">
                            <Grid item>
                                <FieldArray name="additionalData">
                                    {() => (
                                        <>
                                            <Grid
                                                container
                                                justifyContent="flex-start"
                                                className={
                                                    classes.subtitleWrapper
                                                }
                                            >
                                                <Typography variant="body1">
                                                    Молимо Вас попуните следећа
                                                    поља:
                                                </Typography>
                                            </Grid>

                                            {values.additionalData.map(
                                                (data, idx) => (
                                                    <Grid
                                                        container
                                                        key={idx}
                                                        spacing={2}
                                                        alignItems="center"
                                                    >
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            className={
                                                                classes.fieldWrapper
                                                            }
                                                        >
                                                            {/* <Field
                                                                    name={`additionalData[${idx}].naziv`}
                                                                    component={
                                                                        TextField
                                                                    }
                                                                    label="Назив таксе"
                                                                    // className={
                                                                    //     classes.fieldWrapper
                                                                    // }
                                                                    variant="outlined"
                                                                /> */}
                                                            {idx === 0 && (
                                                                <EditSelects
                                                                    name={`additionalData[${idx}].vrednost`}
                                                                    options={
                                                                        amresOptions
                                                                    }
                                                                    label="Изаберите статус амрес мреже"
                                                                    variant="outlined"
                                                                    currentValue={
                                                                        values
                                                                            .additionalData[
                                                                            idx
                                                                        ]
                                                                            .vrednost &&
                                                                        values
                                                                            .additionalData[
                                                                            idx
                                                                        ]
                                                                            .vrednost !==
                                                                            'NULL'
                                                                            ? values
                                                                                  .additionalData[
                                                                                  idx
                                                                              ]
                                                                                  .vrednost
                                                                            : ''
                                                                    }
                                                                />
                                                            )}
                                                            {idx === 1 && (
                                                                <Tooltip
                                                                    title="Измерена брзина линка (https://www.speedtest.net/) (Унесите резултат speedtesta у форми линка (https://www.speedtest.net/result/8234883015))"
                                                                    placement="right"
                                                                >
                                                                    <Grid>
                                                                        <Field
                                                                            name={`additionalData[${idx}].vrednost`}
                                                                            component={
                                                                                TextField
                                                                            }
                                                                            label="Измерена брзина линка"
                                                                            // className={
                                                                            //     classes.fieldWrapper
                                                                            // }
                                                                            fullWidth
                                                                            variant="outlined"
                                                                        />
                                                                    </Grid>
                                                                </Tooltip>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                )
                                            )}
                                        </>
                                    )}
                                </FieldArray>
                            </Grid>
                            <Grid item xs={9}>
                                <Button
                                    className={classes.addAll}
                                    disabled={
                                        isSubmitting ||
                                        values.additionalData.length === 0
                                    }
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    startIcon={
                                        isSubmitting ? (
                                            <CircularProgress size="0.9rem" />
                                        ) : undefined
                                    }
                                >
                                    {isSubmitting ? 'Слање...' : 'Потврди'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Grid>
    )
}

export default A13FirstStep

A13FirstStep.propTypes = {
    setSeverity: PropTypes.func.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    handleNext: PropTypes.func.isRequired,
    activity: PropTypes.objectOf(PropTypes.any).isRequired,

    setError: PropTypes.func.isRequired,
}
