import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
// import TablePagination from '@material-ui/core/TablePagination'
import { Button, Grid, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { DataTable } from 'primereact/datatable'
import { InputText } from 'primereact/inputtext'
import { Column } from 'primereact/column'
import { axiosAuth as axios } from '../../util/axios-instance'
import PageTitle from '../../components/FormsUI/PageTitle/PageTitle'
import SimpleModal from '../../components/Modal/Modal'
import BackToButton from '../../components/FormsUI/Button/BackToButton'

const useStyles = makeStyles(() => ({
    table: {
        margin: 20,
    },
    connectButton: {
        backgroundColor: 'rgb(45, 85, 125)',
        color: 'white',
        '&:hover': {
            backgroundColor: 'rgb(45, 85, 125)',
        },
        margin: '0 20px',
    },
    pdng: {
        padding: '10px 50px',
    },
}))

const ContractsToLocations = () => {
    const [contracts, setContracts] = useState([])
    const [selection, setSelection] = useState(false)
    const classes = useStyles()
    const [globalFilter, setGlobalFilter] = useState('')
    const [load, setLoad] = useState(false)
    const history = useHistory()
    // const [refresh, setRefresh] = useState(false)
    // const [page, setPage] = useState(0)
    // const [rowsPerPage, setRowsPerPage] = useState(10)
    // const [totalResults, setTotalResults] = useState(0)
    const { id } = useParams()
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
    })
    const [severity, setSeverity] = useState('info')

    const handleSnackBarOpen = () => {
        setSnackbar((previous) => ({
            ...previous,
            open: false,
        }))
    }

    const getAllContracts = async () => {
        try {
            setLoad(true)
            const response = await axios.get('/api/contract')
            setContracts(response.data.contracts)
            setLoad(false)
        } catch (error) {
            console.log(error)
            // setContractsError()
        }
    }

    const getLocation = async () => {
        try {
            const response = await axios.get(`/api/location/${id}`)
            if (response.data.location.ugovor) {
                setSelection(response.data.location.ugovor)
            }
        } catch (error) {
            console.log(error)
        }
    }

    // const getUnlinkedContracts = async () => {
    //     try {
    //         // setRefresh(true)
    //         setLoad(true)
    //         const response = await axios.get('/api/link/contract')
    //         setContracts(response.data.documents)
    //         console.log(`UNLINKED CONTRACTS`, response)
    //         // setRefresh(false)
    //         setLoad(false)
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    useEffect(() => {
        getAllContracts()
        getLocation()
        // if (!refresh) {
        // ggetUnlinkedContracts()
        // }
    }, [])

    const connectContract = async (state) => {
        try {
            await axios.patch(`/api/link/location-to-contract/${state._id}`, {
                lokacije: [id],
            })
            history.push('/app/lokacije')
        } catch (error) {
            setSeverity('warning')
            setSnackbar({
                open: true,
                message: error.response.data.message,
            })
        }
        // console.log({
        //     idLokacije: id,
        //     idUgovora: state._id,
        // })
    }

    // const handleChangePage = (event, newPage) => {
    //     setPage(newPage)
    // }

    // const handleChangeRowsPerPage = (event) => {
    //     setRowsPerPage(parseInt(event.target.value, 10))
    //     setPage(0)
    // }

    const search = (
        <div className="table-header">
            {/* <Button
                type="button"
                label="Clear"
                className="p-button-outlined"
                icon="pi pi-filter-slash"
                // onClick={reset}
            /> */}
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    value={globalFilter}
                    onChange={(e) => setGlobalFilter(e.target.value)}
                    placeholder="Претрага"
                    className={classes.pdng}
                />
            </span>
        </div>
    )

    return (
        <div>
            <Snackbar
                open={snackbar.open}
                onClose={handleSnackBarOpen}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
                key="key"
            >
                <Alert onClose={handleSnackBarOpen} severity={severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            <BackToButton label="НАЗАД НА ЛИСТУ СВИХ ЛОКАЦИЈА" />

            <Grid container justifyContent="center">
                <PageTitle>Повезивање локација са уговором</PageTitle>
            </Grid>
            <div style={{ textAlign: 'left' }}>
                {selection ? (
                    <Button
                        className={classes.connectButton}
                        onClick={() => connectContract(selection)}
                    >
                        Повежи
                    </Button>
                ) : (
                    <Button disabled style={{ margin: '0 20px' }}>
                        Повежи
                    </Button>
                )}
            </div>
            {load ? (
                <SimpleModal />
            ) : (
                <>
                    <DataTable
                        className={classes.table}
                        value={contracts}
                        selection={selection}
                        onSelectionChange={(e) => {
                            setSelection(e.value)
                        }}
                        dataKey="_id"
                        selectionMode="checkbox"
                        globalFilter={globalFilter}
                        emptyMessage="Нема неповезаних уговора"
                    >
                        <Column
                            selectionMode="single"
                            headerStyle={{ width: '3em' }}
                        />
                        <Column field="naziv" header="Назив уговора" />
                        <Column field="status" header="Статус" />
                        <Column
                            header={search}
                            headerStyle={{ padding: '5px' }}
                        />
                    </DataTable>
                    {/* <TablePagination
                        component="div"
                        count={totalResults}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage="редова по страници"
                        labelDisplayedRows={({ from, to, count }) =>
                            `приказ страница ${from}-${to} od ${count}`
                        }
                    /> */}
                </>
            )}
        </div>
    )
}

export default ContractsToLocations
