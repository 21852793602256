import React from 'react'
import PropTypes, { objectOf } from 'prop-types'

import FolderOpenIcon from '@material-ui/icons/FolderOpen'
// import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { makeStyles, Grid, Typography } from '@material-ui/core'
// import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined'

import ActivityRow from '../../../components/Cards/ActivityRow'
import ActivitiFilesCard from './ActivitiFilesCard'
// contractDiv activityTitle icon fileDescription docDiv downloadButton fileDescription  htmlTable thconnected

const ActivityFiles = ({ activity, connected, allFiles }) => {
    const useStyles = makeStyles(() => ({
        htmlTable: {
            border: '1px solid black',
            borderCollapse: 'collapse',
            marginBottom: 20,
        },
        thConnected: {
            border: '1px solid #dddddd',
            textAlign: 'left',
            padding: 8,
            backgroundColor: '#417AB4',
            color: 'white',
            width: '100%',
        },
        th: {
            border: '1px solid #dddddd',
            textAlign: 'left',
            padding: 8,
            backgroundColor: '#417AB4',
            color: 'white',
            width: 300,
        },
        docDiv: {
            height: '3.5rem',
            alignItems: 'center',
            justifyContent: 'flex-start',
            margin: '10px 0',
        },

        activityTitle: {
            textAlign: 'left',
            margin: '10px 8px',
            fontSize: 25,
            color: '#417AB4',
            display: 'flex',
            alignItems: 'flex-end',
        },

        icon: {
            fontSize: 40,
            color: '#417AB4',
        },

        fileDescription: {
            margin: '20px 3px',
        },
        sectionMargin: {
            margin: '40px 0',
        },
        deleteButtonWrapper: {
            position: 'relative',
            height: '100%',
        },
        deleteButton: {
            position: 'absolute',
            color: '#a52408',
            top: -5,
            left: 5,
            '&:hover': {
                color: '#ffff',
                backgroundColor: '#f36344',
                borderRadius: '50%',
                cursor: 'pointer',
            },
            fontSize: '1.5rem',
        },
    }))
    const classes = useStyles()

    /* eslint-disable */

    return (
        <>
            <Grid
                item
                xs={6}
                className={`${classes.contractDiv} ${classes.sectionMargin}`}
            >
                <div className={classes.activityTitle}>
                    <FolderOpenIcon className={classes.icon} />
                    <span>Документа</span>
                </div>
                <Grid container className={classes.fileDescription}>
                    <Typography variant="subtitle2" color="primary">
                        {activity.dokumenta}
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    {allFiles && allFiles.length > 0 ? (
                        <>
                            {allFiles.map((dok) => (
                                <ActivitiFilesCard
                                    dok={dok}
                                    activity={activity}
                                    key={dok}
                                />
                            ))}
                        </>
                    ) : (
                        <Grid
                            container
                            justifyContent="flex-start"
                            className={classes.fileDescription}
                        >
                            <Typography variant="subtitle1" color="secondary">
                                Активност нема документа
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </Grid>

            {connected.length > 0 && (
                <Grid item xs={10} className={classes.sectionMargin}>
                    <div className={classes.activityTitle}>
                        <FolderOpenIcon className={classes.icon} />
                        <span>Документа повезаних активности</span>
                    </div>

                    <table className={classes.htmlTable}>
                        <tbody>
                            <tr>
                                <th className={classes.thConnected}>Назив</th>
                                <th className={classes.thConnected}>
                                    Документа
                                </th>
                            </tr>
                            {connected.map((el) => (
                                <ActivityRow
                                    key={el._id}
                                    activity={el}
                                    fromControl={true}
                                    lokId={el.old_ID}
                                />
                            ))}
                        </tbody>
                    </table>
                </Grid>
            )}
        </>
    )
}

export default ActivityFiles

ActivityFiles.propTypes = {
    // isControlActivity: PropTypes.bool,
    activity: objectOf(PropTypes.any).isRequired,
    connected: PropTypes.arrayOf(PropTypes.any).isRequired,
    allFiles: PropTypes.arrayOf(PropTypes.any).isRequired,
}

ActivityFiles.defaultProps = {
    // isControlActivity: false,
}
