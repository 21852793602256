import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useFormikContext } from 'formik'
import TextfieldWrapper from '../../../components/FormsUI/Textfield/TextfieldWrapper'

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 800,
        height: 'auto',
        maxHeight: '90%',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        overflow: 'auto',
    },
    title: {
        textAlign: 'center',
    },
    formField: {
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
    },
    dateDiv: {
        width: '100%',
        padding: 10,
    },
}))

const Step2 = () => {
    const classes = useStyles()
    const { values } = useFormikContext()
    return (
        <div>
            <div className={classes.formField}>
                <span className={classes.tableRowLabel}>Број зграда</span>
                <TextfieldWrapper name="brojZgrada" value={values.brojZgrada} />
            </div>
            <div className={classes.formField}>
                <span className={classes.tableRowLabel}>Укупно просторија</span>
                <TextfieldWrapper
                    name="ukupnoProstorija"
                    value={values.ukupnoProstorija}
                />
            </div>
            <div className={classes.formField}>
                <span className={classes.tableRowLabel}>Укупно учионица</span>
                <TextfieldWrapper
                    name="ukupnoUcionica"
                    value={values.ukupnoUcionica}
                />
            </div>
            <div className={classes.formField}>
                <span className={classes.tableRowLabel}>Број лабораторија</span>
                <TextfieldWrapper
                    name="brojLaboratorija"
                    value={values.brojLaboratorija}
                />
            </div>
            <div className={classes.formField}>
                <span className={classes.tableRowLabel}>Број кабинета</span>
                <TextfieldWrapper
                    name="brojKabineta"
                    value={values.brojKabineta}
                />
            </div>
            <div className={classes.formField}>
                <span className={classes.tableRowLabel}>Број библиотека</span>
                <TextfieldWrapper
                    name="brojBiblioteka"
                    value={values.brojBiblioteka}
                />
            </div>
            <div className={classes.formField}>
                <span className={classes.tableRowLabel}>Остале просторије</span>
                <TextfieldWrapper
                    name="ostaleProstorije"
                    value={values.ostaleProstorije}
                />
            </div>
        </div>
    )
}

export default Step2
