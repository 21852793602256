import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import PropTypes from 'prop-types'
import { Button, CircularProgress, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import WarningIcon from '@material-ui/icons/Warning'
import { axiosAuth as axios } from '../../util/axios-instance'

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    }
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        textAlign: 'center',
    },
    warning: {
        color: '#AE0F0F',
        fontSize: 50,
    },
}))

export default function DeleteLocation({
    id,
    modalOpen,
    setModal,
    setRefresh,
    setSeverityHandler,
    setSnackbarHandler,
}) {
    const classes = useStyles()
    const [modalStyle] = React.useState(getModalStyle)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
    })
    const [severity, setSeverity] = useState('info')

    const handleSnackBarOpen = () => {
        setSnackbar((previous) => ({
            ...previous,
            open: false,
        }))
    }

    const deleteLocation = async () => {
        try {
            setRefresh(true)
            setIsSubmitting(true)
            await axios.delete(`/api/location/${id}`)
            setModal(false)
            setSeverityHandler('success')
            setSnackbarHandler({
                open: true,
                message: 'Успешно сте обрисали локацију.',
            })
            setRefresh(false)
            setIsSubmitting(false)
        } catch (error) {
            setIsSubmitting(false)
            setSeverity('warning')
            setSnackbar({
                open: true,
                message: error?.response?.data?.message
                    ? error.response.data.message
                    : 'Брисање локације неуспешно.',
            })
        }
    }

    const body = (
        <>
            <Snackbar
                open={snackbar.open}
                onClose={handleSnackBarOpen}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
                key="key"
            >
                <Alert onClose={handleSnackBarOpen} severity={severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            <div style={modalStyle} className={classes.paper}>
                <WarningIcon className={classes.warning} />
                <h3>Да ли сте сигурни?</h3>
                <h3>Брисање локације је трајно</h3>
                <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    disabled={isSubmitting}
                    startIcon={
                        isSubmitting ? (
                            <CircularProgress size="0.9rem" />
                        ) : undefined
                    }
                    onClick={() => deleteLocation()}
                    style={{ margin: '10px 0' }}
                >
                    Обриши
                </Button>
                <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    onClick={() => setModal(false)}
                >
                    Одустани
                </Button>
            </div>
        </>
    )

    return (
        <div>
            <Modal
                open={modalOpen}
                onClose={() => setModal(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {id ? body : null}
            </Modal>
        </div>
    )
}

DeleteLocation.propTypes = {
    id: PropTypes.string.isRequired,
    modalOpen: PropTypes.bool,
    setModal: PropTypes.func,
    setRefresh: PropTypes.func,
    setSeverityHandler: PropTypes.func,
    setSnackbarHandler: PropTypes.func,
}

DeleteLocation.defaultProps = {
    modalOpen: false,
    setModal: () => {},
    setRefresh: () => {},
    setSeverityHandler: () => {},
    setSnackbarHandler: () => {},
}
