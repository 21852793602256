import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useFormikContext } from 'formik'
import TextfieldWrapper from '../../../components/FormsUI/Textfield/TextfieldWrapper'
import EditSelects from '../../../components/FormsUI/Select/EditSelects'

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 800,
        height: 'auto',
        maxHeight: '90%',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        overflow: 'auto',
    },
    title: {
        textAlign: 'center',
    },
    formField: {
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
    },
    dateDiv: {
        width: '100%',
        padding: 10,
    },
}))

const prim = [
    { naziv: 'Основна школа', _id: 'Основна школа' },
    { naziv: 'Специјалне школе', _id: 'Специјалне школе' },
    {
        naziv: 'Функционално образовање одраслих',
        _id: 'Функционално образовање одраслих',
    },
    {
        naziv: 'Средње музичке и балетске школе',
        _id: 'Средње музичке и балетске школе',
    },
    { naziv: 'Средње стручне школе', _id: 'Средње стручне школе' },
    { naziv: 'Гимназије', _id: 'Гимназије' },
]

const vrsta = [
    { naziv: 'Седиште', _id: 'Седиште' },
    { naziv: 'Издвојена локација', _id: 'Издвојена локација' },
]

const Step8 = () => {
    const { values } = useFormikContext()
    const classes = useStyles()

    return (
        <div>
            <div className={classes.formField}>
                <span className={classes.tableRowLabel}>
                    Примарна делатност
                </span>

                <EditSelects
                    name="primarnaDelatnost"
                    options={prim}
                    label="Изаберите примарну делатност"
                    currentValue={values.primarnaDelatnost}
                />
            </div>
            <div className={classes.formField}>
                <span className={classes.tableRowLabel}>Врста локације</span>

                <EditSelects
                    name="vrstaLokacije"
                    options={vrsta}
                    label="Изаберите врсту локације"
                    currentValue={values.vrstaLokacije}
                />
            </div>
            <div className={classes.formField}>
                <span className={classes.tableRowLabel}>Адреса</span>
                <TextfieldWrapper
                    name="adresa"
                    // inputRef={(input) => input && input.focus()}
                    value={values.adresa}
                />
            </div>
            <div className={classes.formField}>
                <span className={classes.tableRowLabel}>Поштански број</span>
                <TextfieldWrapper
                    name="postanskiBroj"
                    // inputRef={(input) => input && input.focus()}
                    value={values.postanskiBroj}
                />
            </div>
            <div className={classes.formField}>
                <span className={classes.tableRowLabel}>Насеље</span>
                <TextfieldWrapper
                    name="naselje"
                    // inputRef={(input) => input && input.focus()}
                    value={values.naselje}
                />
            </div>
            <div className={classes.formField}>
                <span className={classes.tableRowLabel}>Општина</span>
                <TextfieldWrapper
                    name="opstina"
                    // inputRef={(input) => input && input.focus()}
                    value={values.opstina}
                />
            </div>
        </div>
    )
}

export default Step8
