import * as Yup from 'yup'
import AddEditModal from './AddEditModal'
import DeleteModal from './DeleteModal'

export const initialStateHandler = (selected, action) => {
    let state
    if (Object.keys(selected).length === 0) {
        if (action === 'приоритет') {
            state = {
                naziv: selected && selected.naziv ? selected.naziv : '',
                broj: selected && selected.broj ? selected.broj : '',
            }
        } else {
            state = {
                naziv: selected && selected.naziv ? selected.naziv : '',
            }
        }
    } else if (Object.keys(selected).includes('cirilica')) {
        state = {
            naziv: selected && selected.naziv ? selected.naziv : '',
        }
    } else if (!Object.keys(selected).includes('cirilica')) {
        state = {
            naziv: selected && selected.naziv ? selected.naziv : '',
            broj: selected && selected.broj ? selected.broj : '',
        }
    }
    return state
}

export const inputValidationHandler = (selected, action) => {
    let validation
    if (Object.keys(selected).length === 0) {
        if (action === 'приоритет') {
            validation = Yup.object().shape({
                naziv: Yup.string().required('Морате уписати Назив.'),
                broj: Yup.string().required('Морате уписати Број.'),
            })
        } else {
            validation = Yup.object().shape({
                naziv: Yup.string().required('Морате уписати Назив.'),
            })
        }
    } else if (!Object.keys(selected).includes('broj')) {
        validation = Yup.object().shape({
            naziv: Yup.string().required('Морате уписати Назив.'),
        })
    } else if (!Object.keys(selected).includes('cirilica')) {
        validation = Yup.object().shape({
            naziv: Yup.string().required('Морате уписати Назив.'),
            broj: Yup.string().required('Морате уписати Број.'),
        })
    }
    return validation
}

export const contentHandler = (
    selected,
    action,
    closeModal,
    handleDelete,
    message,
    error
) => {
    let cont

    if (Object.keys(selected).length === 0) {
        if (action === 'приоритет') {
            cont = (
                <AddEditModal
                    type="prioritet"
                    title="Додај"
                    closeModal={closeModal}
                    message={message}
                    error={error}
                />
            )
        } else {
            cont = (
                <AddEditModal
                    type="podgrupa"
                    title="Додај"
                    closeModal={closeModal}
                    message={message}
                    error={error}
                />
            )
        }
    }

    if (!Object.keys(selected).includes('broj')) {
        if (action === 'izmeni') {
            cont = (
                <AddEditModal
                    type="podgrupa"
                    selected={selected}
                    title="Измена"
                    closeModal={closeModal}
                    message={message}
                    error={error}
                />
            )
        } else if (action === 'obrisi') {
            cont = (
                <DeleteModal
                    closeModal={closeModal}
                    deleteFunc={handleDelete}
                    message={message}
                    error={error}
                />
            )
        }
    } else if (!Object.keys(selected).includes('cirilica')) {
        if (action === 'izmeni') {
            cont = (
                <AddEditModal
                    type="prioritet"
                    selected={selected}
                    title="Измена"
                    closeModal={closeModal}
                    message={message}
                    error={error}
                />
            )
        } else if (action === 'obrisi') {
            cont = (
                <DeleteModal
                    closeModal={closeModal}
                    deleteFunc={handleDelete}
                    message={message}
                    error={error}
                />
            )
        }
    }
    return cont
}

export const linkHandler = (selected, action) => {
    let link
    if (Object.keys(selected).length === 0) {
        if (action === 'приоритет') link = '/api/priority/'
        if (action === 'мрежа') link = '/api/network'
        if (action === 'подгрупа') link = '/api/types/'
    } else {
        if (Object.keys(selected).includes('broj'))
            link = `/api/priority/${selected._id}`
        if (Object.keys(selected).includes('cirilica'))
            link = `/api/types/${selected._id}`
        if (Object.keys(selected).includes('connection'))
            link = `/api/network/${selected._id}`
    }
    return link
}
