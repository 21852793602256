import React, { useState } from 'react'
import { Tab, Tabs, Grid } from '@material-ui/core'
import TabPanel from '../../components/TabPanel/TabPanel'
import PageTitle from '../../components/FormsUI/PageTitle/PageTitle'
import InventoryContracts from './InventoryTabs/Contracts/InventoryContracts'
import InventoryReports from './InventoryTabs/InventoryReports/InventoryReports'
import SchoolNotifications from './InventoryTabs/SchoolNotifications/SchoolNotifications'
import InventoryTypes from './InventoryTabs/InventoryTypes/InventoryTypes'
import InventoryCheck from './InventoryTabs/InventoryCheck/InventoryCheck'

const Inventory = () => {
    const [value, setValue] = useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    return (
        <div>
            <PageTitle title="Инвентар" />
            <Grid
                container
                alignItems="baseline"
                style={{ padding: 12 }}
                direction="column"
            >
                <Grid item>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="user tabs"
                    >
                        <Tab label="Списак уговора" disableRipple />
                        <Tab label="Шифарник опреме" disableRipple />
                        <Tab label="Извештаји" disableRipple />
                        <Tab label="Списак обавештених школа" disableRipple />
                        <Tab label="Провера инвентара" disableRipple />
                    </Tabs>
                </Grid>
                <Grid item>
                    <TabPanel value={value} index={0}>
                        <InventoryContracts />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <InventoryTypes />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <InventoryReports />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <SchoolNotifications />
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        <InventoryCheck />
                    </TabPanel>
                </Grid>
            </Grid>
        </div>
    )
}

export default Inventory
