import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Grid, Typography } from '@material-ui/core'
import download from 'downloadjs'
import useSWR from 'swr'
import PropTypes from 'prop-types'
import moment from 'moment'
import uuid from 'react-uuid'
import { axiosAuth as axios } from '../../util/axios-instance'

const useStyles = makeStyles({
    tr: {
        border: '1px solid #dddddd',
    },
    td: {
        border: '1px solid #dddddd',
        textAlign: 'left',
        padding: '0 5px',
        width: 300,
    },
    th: {
        border: '1px solid #dddddd',
        textAlign: 'left',
        padding: '0 5px',
        backgroundColor: '#417AB4',
        color: 'white',
        width: 300,
    },
    btnPreuzmi: {
        marginLeft: 10,
        background: '#1E8637',
        color: '#FFFFFF',
    },
    listWrap: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    fileTxt: {
        textDecoration: 'underline',
        textTransform: 'none',
    },
})

const ActivityRow = ({ activity, fromControl, ustId, lokId }) => {
    const classes = useStyles()

    const fName = `${ustId}-${lokId}.zip`

    const url =
        activity.kratakNaziv === 'A1'
            ? `/api/files/get/all/files/tlocrt/${lokId}/${ustId}`
            : `/api/files/get/all/files/activity/${lokId}/${activity.kratakNaziv}`
    const { data: controledActivityFiles } = useSWR(url)
    // console.log('Fajlovi podredjene aktivnosti', controledActivityFiles)

    // eslint-disable-next-line
    const downloadFile = async (dok) => {
        try {
            const downloadUrl =
                activity.kratakNaziv === 'A1'
                    ? `/api/files/tlocrt/${lokId}/${ustId}/${fName}`
                    : `/api/files/activity/${lokId}/${activity.kratakNaziv}/${dok}`
            const response = await axios.get(downloadUrl, {
                responseType: 'blob',
            })

            return download(response.data, dok)
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            {/* <FileModal
                open={fileOpen}
                close={handleCloseFile}
                files={activity.fajlovi}
                id={activity._id}
                locationId={activity.lokacija._id}
                title="Доступни фајлови активности"
                type="activity"
            /> */}
            {/* <DataModal
                podaci={activity.podaci}
                close={handleCloseData}
                open={dataOpen}
            /> */}

            <tr>
                {/* <td className={classes.td}>{activity.kratakNaziv}</td> */}
                <td className={classes.td}>
                    {activity.kratakNaziv} - {activity.naziv}
                </td>
                <td className={classes.td}>
                    <Grid container>
                        {controledActivityFiles &&
                            controledActivityFiles.map((item) => (
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.listWrap}
                                    key={item}
                                >
                                    <Button
                                        onClick={() => {
                                            downloadFile(item)
                                        }}
                                        // endIcon={<GetAppIcon />}
                                    >
                                        <Typography
                                            variant="body2"
                                            className={classes.fileTxt}
                                        >
                                            {item}
                                        </Typography>
                                    </Button>
                                </Grid>
                            ))}
                        {(!controledActivityFiles ||
                            controledActivityFiles.length <= 0) && (
                            <Typography
                                variant="body2"
                                className={classes.fileTxt}
                            >
                                Нема докумената
                            </Typography>
                        )}
                    </Grid>
                    {/* <Button
                        disabled={activity.fajlovi.length === 0}
                        onClick={showFiles}
                        className={classes.btn}
                        variant="outlined"
                        color="primary"
                    >
                        Прикажи документа
                    </Button> */}
                </td>
                {!fromControl && (
                    <td className={classes.td}>
                        {activity.podaci.length > 0 &&
                        activity.podaci[0].vrednost !== ''
                            ? activity.podaci.map((item) => (
                                  <Grid
                                      item
                                      xs={12}
                                      className={classes.listWrap}
                                      key={uuid()}
                                  >
                                      {item.naziv}:{' '}
                                      {item.naziv.includes('Датум')
                                          ? moment(item.vrednost).format(
                                                'DD MM YYYY'
                                            )
                                          : item.vrednost}
                                  </Grid>
                              ))
                            : null}
                        {/* {activity.podaci ? activity.podaci[0] : ''} */}
                        {/* <Button
                            disabled={activity.podaci.length === 0}
                            onClick={showData}
                            className={classes.btn}
                            variant="outlined"
                            color="primary"
                        >
                            Прикажи податке
                        </Button> */}
                        {/* <Button
                    disabled={activity.podaci.length === 0}
                    onClick={showData}
                >
                    Прикажи
                </Button> */}
                    </td>
                )}
            </tr>
        </>
    )
}

ActivityRow.propTypes = {
    activity: PropTypes.objectOf(PropTypes.any).isRequired,
    fromControl: PropTypes.bool,
    ustId: PropTypes.string,
    lokId: PropTypes.number,
    // files: PropTypes.arrayOf(PropTypes.object),
}

ActivityRow.defaultProps = {
    fromControl: false,
    // files: [],
    ustId: '',
    lokId: '',
}

export default ActivityRow
