import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
    Grid,
    Typography,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core'
import moment from 'moment'
import { useDebounce } from 'use-debounce'
// eslint-disable-next-line
import fileDownload from 'js-file-download'
import { axiosAuth as axios } from '../../../util/axios-instance'
import UserTable from '../../../components/tables/UserTable'
import SimpleModal from '../../../components/Modal/Modal'
import latToCyr from '../../../util/latToCyr'
import ActivityList from '../../Activities/activitiesList'

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 300,
    },
    createbutton: {
        padding: 8,
        margin: '20px 0px',
    },
    root: {
        margin: 12,
        textAlign: 'left',
    },
    table: {
        margin: 8,
    },
    editButton: {
        backgroundColor: '#059D2A',
        color: 'white',
        '&:hover': {
            background: '#059D2A',
        },
    },
    deleteButton: {
        backgroundColor: '#D86565',
        color: 'white',
        '&:hover': {
            background: '#D86565',
        },
    },
    buttonW: {
        margin: '1rem 0',
    },
}))

const listaAktivnosti = [
    {
        id: '1',
        model: 'A1',
        name: 'Урађен предлог трасе',
        shortName: ' ПЗИ',
    },
    {
        id: '2',
        model: 'A9',
        name: 'Преглед ПЗИ',
        shortName: ' ПЗИ',
    },
    {
        id: '3',
        model: 'A12',
        name: 'Преглед ПИО',
        shortName: ' ПИО',
    },
]

const ReportPzi = () => {
    const classes = useStyles()
    const [load, setLoad] = useState(false)
    const [reports, setReports] = useState({ totalResult: 0, report: [] })
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [selectedActivity, setSelectedActivity] = useState('A1')
    // const [allActivity, setAllActivity] = useState([])
    const [search, setSearch] = useState({
        eng: '',
        srb: '',
    })
    const [searchDebounced] = useDebounce(search, 2000)

    const withSearchUrl =
        // prettier-ignore
        searchDebounced.eng === ''
            ? `/api/report/${selectedActivity}?page=${page + 1
            }&limit=${rowsPerPage}`
            : `/api/search/location?eng=${searchDebounced.eng}&srb=${searchDebounced.srb
            }&page=${page + 1
            }&limit=${rowsPerPage}&activity=${selectedActivity}`

    const filteredTitle = listaAktivnosti.filter(
        (aktivnost) => aktivnost.model === selectedActivity
    )

    const getPzi = async () => {
        try {
            setLoad(true)
            const { data } = await axios.get(withSearchUrl)

            // console.log('TEST', data, ' URL ', withSearchUrl)
            const svevidece = await axios.get('/api/group/sve-videci/true')
            const svevideceArr = []
            svevidece.data?.groups?.map((el) => svevideceArr.push(el))

            // console.log(svevideceArr)

            const tableData = data?.locations?.reduce((acc, currentEl) => {
                const formatDate = moment(currentEl?.datumZavrsetka).isValid()

                const isValidDate = formatDate
                    ? moment(currentEl?.datumZavrsetka).format('DD/MM/YYYY')
                    : 'Нема датум завршетка'

                // const groupArray = currentEl.odgovoran
                //     ?.map((el) => el.naziv)
                //     .join(', ')

                const groupArray = [...currentEl?.odgovoran, ...svevideceArr]
                    .filter((el) => el.tip === 'DOB')
                    .map((el) => el.naziv)
                    .join(', ')
                // const odgNazivi = [
                //     ...currentEl.lokacija.odgovoran,
                //     ...svevideceArr,
                // ]
                //     .filter((el) => groupArray.includes(el.cirilica))
                //     .map(
                //         (el) =>
                //             `${el.naziv} ${
                //                 el.nadredjenaGrupa
                //                     ? // prettier-ignore
                //                       `(${el.nadredjenaGrupa.naziv})`
                //                     : ''
                //             }`
                //     )
                //     .join(', ')

                // const formatDate = moment(
                //     currentEl?.completed?.datumZavrsetka
                // ).format('DD/MM/YYYY')
                // const checkFile = currentEl.fajlovi === "Нема фајлова"

                const addElement = {
                    adresa: currentEl?.adresa,
                    lokacija: currentEl?.lokacija,
                    status: latToCyr(currentEl?.status_aktivnosti),
                    prioritet: currentEl?.prioritet?.naziv,
                    fajlovi: currentEl?.fajlovi,
                    odgovoran: groupArray,
                    datumZavr: isValidDate,
                    object: {
                        adresa: currentEl?.adresa,
                        old_ID: currentEl?.old_ID,
                        ustanova_ID: currentEl?.ustanova_ID,
                        akt: currentEl?.akt,
                        lokacija: currentEl?.lokacija,
                        ugovor: currentEl?.ugovor,
                    },
                    ustanova_id: currentEl?.ustanova_ID,
                    old_ID: currentEl?.old_ID,
                    pzi:
                        currentEl?.fajlovi === 'Нема фајлова.' ? (
                            <span
                                style={{
                                    width: '100%',
                                    display: 'inline-block',
                                }}
                            >
                                Нема послатих докумената
                            </span>
                        ) : (
                            <span
                                data-name="pzi"
                                name="pzi"
                                style={{
                                    width: '100%',
                                    display: 'inline-block',
                                    textDecoration: 'underline',
                                }}
                            >
                                Преузмите документа
                            </span>
                        ),
                }
                // acc.push(addElement)
                return [...acc, addElement]
            }, [])

            setReports({
                totalResult: data.totalResults,
                report: tableData,
            })

            // const dataLength = parseInt(data.totalResults, 10)
            //  setTotalResults(dataLength)
            setLoad(false)
        } catch (err) {
            // setError(err)
            console.log(err.response)
            setLoad(true)
            setTimeout(() => {
                setLoad(false)
            }, 400)
        }
    }

    useEffect(() => {
        getPzi(setSelectedActivity)
    }, [page, rowsPerPage, selectedActivity, searchDebounced.eng])

    const downloadZipPzi = async (data) => {
        // prettier-ignore

        const apiUrl =
            data.akt === 'A1'
                ? `/api/files/tlocrt/${data.old_ID}/${data.ustanova_ID}/${data.ustanova_ID}-${data.old_ID}.zip`
                : `/api/files/zip?activityName=${selectedActivity}&directory=${data.adresa}&locationName=${data.lokacija}`

        const fileName =
            data.akt === 'A1'
                ? `${data.ustanova_ID}-${data.old_ID}.zip`
                : `${data.lokacija}-${data.akt}.zip`

        try {
            const response = await axios.get(apiUrl, {
                responseType: 'arraybuffer',
            })

            fileDownload(response.data, fileName)
        } catch (error) {
            console.log(error)
        }
    }

    const onRowClick = (rowData) => {
        // console.log(rowData)
        if (rowData.originalEvent.target.dataset.name === 'pzi') {
            downloadZipPzi(rowData.data.object)
        }
    }

    const handleChange = (event) => {
        setSelectedActivity(event.target.value)
    }

    // console.log(filteredTitle[0].name)

    return (
        <div className={classes.root}>
            <Grid container alignItems="center">
                <Grid item>
                    <Typography variant="body1">
                        Изаберите жељену активност
                    </Typography>
                </Grid>
                <Grid item>
                    <FormControl
                        variant="outlined"
                        className={classes.formControl}
                    >
                        <InputLabel id="reportSelect">активности</InputLabel>
                        <Select
                            labelId="reportSelect"
                            id="outlinedselekt"
                            value={selectedActivity}
                            onChange={handleChange}
                            label="извештаји"
                        >
                            {/* <MenuItem value="Izvestaj">
                                <em>избор активности</em>
                            </MenuItem> */}
                            {ActivityList.map((item) => (
                                <MenuItem
                                    value={item.kratakNaziv}
                                    key={item.id}
                                >
                                    {item.kratakNaziv}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <div className={classes.table}>
                {load ? (
                    <SimpleModal />
                ) : (
                    <>
                        <UserTable
                            model="report"
                            selectedActivity={selectedActivity}
                            title={
                                filteredTitle.length > 0
                                    ? filteredTitle[0].name
                                    : ''
                            }
                            data={reports.report ? reports.report : []}
                            totalResults={reports.totalResult}
                            page={page}
                            setPage={setPage}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                            onRowClick={onRowClick}
                            name="iktReportpzi"
                            withSearch
                            tableHeaders={[
                                {
                                    field: 'old_ID',
                                    header: 'ИД Локације',
                                },
                                {
                                    field: 'ustanova_id',
                                    header: 'ИД Установе',
                                },
                                {
                                    field: 'lokacija',
                                    header: 'Назив локације',
                                },
                                {
                                    field: 'odgovoran',
                                    header: 'Одговоран',
                                },
                                {
                                    field: 'pzi',
                                    header: 'Фајлови',
                                },
                                {
                                    field: 'status',
                                    header: 'Статус',
                                },
                                {
                                    field: 'prioritet',
                                    header: 'Приоритет',
                                },
                                {
                                    field: 'datumZavr',
                                    header: 'Датум завршетка',
                                },
                            ]}
                            setSearch={setSearch}
                            search={search}
                            exportFileName={selectedActivity}
                        />
                    </>
                )}
            </div>
        </div>
    )
}

export default ReportPzi

// ReportLocations.propTypes = {
//     // load: PropTypes.bool.isRequired,
//     // activity: objectOf(PropTypes.any).isRequired,
//     table: PropTypes.objectOf(PropTypes.any).isRequired,
//     load: PropTypes.bool.isRequired,
// }
