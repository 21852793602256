import React, { useState, useEffect } from 'react'
// import { useDebounce } from 'use-debounce'
import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
} from '@material-ui/core'
import { axiosAuth as axios } from '../../../../util/axios-instance'
import AdminTable from '../../../../components/tables/AdminTable'
import SimpleModal from '../../../../components/Modal/Modal'
import latToCyr from '../../../../util/latToCyr'
import SummaryFilters from '../../../../components/Cards/SummaryFilters'

const SummaryInvReports = () => {
    const [reports, setReports] = useState([])
    const [load, setLoad] = useState(false)
    // eslint-disable-next-line
    const [search, setSearch] = useState({ eng: '', key: '', srb: '' })
    // const [searchDebounced] = useDebounce(search, 2000)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [totalResults, setTotalResults] = useState(0)
    const [params, setParams] = useState('')
    const [sortParam, setSortParam] = useState('naziv')

    const getReports = async () => {
        try {
            setLoad(true)

            const url = `/api/inventory-reports/sumarno/${sortParam}?page=${
                page + 1
            }&limit=${rowsPerPage}${params}`
            // prettier-ignore
            // searchDebounced.eng === ''
            //     ? `/api/inventory-reports/sumarno/${sortParam}?page=${page}&limit=${rowsPerPage}${params}`
            //     : `/api/inventory-reports/sumarno/${sortParam}?eng=${searchDebounced.eng}&srb=${searchDebounced.srb
            //     }&page=${page}&limit=${rowsPerPage}${params}`

            const response = await axios.get(url)
            const tableData = response?.data?.sumPerTypeInventory?.reduce(
                (acc, currentEl) => {
                    const date = currentEl.datum?.split('T')[0].split('-')

                    const addElement = {
                        _id: currentEl._id,
                        kolicina: currentEl.Kolicina,
                        ukupnaCena: currentEl['Ukupna cena'],
                        ukupnaKolicina: currentEl.UkupnaKolicina,
                        cena: currentEl.cena,
                        naziv: currentEl.naziv,
                        redniBroj: currentEl.redniBroj,
                        tip: latToCyr(currentEl.tip),
                        trajanjeGarancije: currentEl.trajanjeGarancije,
                        datum: currentEl.datum
                            ? `${date[2]}/${date[1]}/${date[0]}`
                            : 'Више од једног',
                    }
                    acc.push(addElement)
                    return acc
                },
                []
            )
            setTotalResults(response.data.totalResults)
            setReports(tableData)
            setLoad(false)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getReports()
    }, [
        page,
        rowsPerPage,
        // refreshState,
        // searchDebounced.eng,
        // searchDebounced.srb,
        params,
        sortParam,
    ])

    const setQueryParams = (url) => {
        setParams(url)
    }

    return (
        <div>
            {load && <SimpleModal />}
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'start',
                    margin: '10px 0',
                }}
            >
                <FormControl component="fieldset">
                    <FormLabel component="legend">Сортирај по:</FormLabel>
                    <RadioGroup
                        aria-label="gender"
                        name="gender1"
                        value={sortParam}
                        onChange={(event) => setSortParam(event.target.value)}
                    >
                        <FormControlLabel
                            value="naziv"
                            control={<Radio />}
                            label="Назив"
                        />
                        <FormControlLabel
                            value="datum"
                            control={<Radio />}
                            label="Датум"
                        />
                    </RadioGroup>
                </FormControl>
            </div>
            <SummaryFilters setQueryParams={setQueryParams} />
            <AdminTable
                data={reports}
                name="summaryInventoryReports"
                tableHeaders={[
                    { field: 'naziv', header: 'Назив' },
                    { field: 'redniBroj', header: 'Редни број' },
                    { field: 'ukupnaKolicina', header: 'Укупна количина' },
                    // { field: 'cena', header: 'Цена' },
                    { field: 'ukupnaCena', header: 'Укупна цена' },
                    { field: 'tip', header: 'Тип' },
                    { field: 'trajanjeGarancije', header: 'Трајање гаранције' },
                    { field: 'datum', header: 'Датум' },
                ]}
                setSearch={() => {}}
                withSearch={false}
                search={search}
                totalResults={totalResults}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                onRowClick={() => {}}
                actionBodyTemplate={() => {}}
                model={`inventory-reports/sumarno/${sortParam}X${params}`}
                title="Сумарни подаци по ставкама"
                // searchColumns={[
                //     { datavalue: 'naziv', label: 'Назив инвентара' },
                //     { datavalue: 'tip', label: 'Тип' },
                //     { datavalue: 'sifra', label: 'Шифра' },
                // ]}
                exportFileName="Сумарни извештај"
            />
        </div>
    )
}

export default SummaryInvReports
