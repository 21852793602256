import React, { useState } from 'react'
import PropTypes, { objectOf } from 'prop-types'
import PermIdentityIcon from '@material-ui/icons/PermIdentity'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import PhoneIcon from '@material-ui/icons/Phone'
import DescriptionIcon from '@material-ui/icons/Description'

import { makeStyles, Grid } from '@material-ui/core'
import moment from 'moment'
import BasicTable from '../../../components/tables/BasicTable'
import latToCyr from '../../../util/latToCyr'

const useStyles = makeStyles(() => ({
    root: {
        margin: 12,
    },
    contactWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    contactDetails: {
        display: 'flex',
        alignItems: 'center',
        margin: '0 10px',
    },
    contactIcon: {
        marginRight: 10,
    },
    locationDetailTable: {
        marginTop: 20,
    },
    table: {
        borderCollapse: 'collapse',
        margin: 8,
    },
    htmlTable: {
        border: '1px solid black',
        borderCollapse: 'collapse',
        marginBottom: 20,
    },
    thConnected: {
        border: '1px solid #dddddd',
        textAlign: 'left',
        padding: 8,
        backgroundColor: '#417AB4',
        color: 'white',
        width: '100%',
    },
    th: {
        border: '1px solid #dddddd',
        textAlign: 'left',
        padding: 8,
        backgroundColor: '#417AB4',
        color: 'white',
        width: 300,
    },
    docDiv: {
        border: '1px solid #ddd',
        display: 'flex',
        justifyContent: 'space-between',
        width: '500px',
        '@media (max-width:750px)': {
            width: '380px',
        },
        '@media (max-width:450px)': {
            width: '250px',
            flexDirection: 'column',
        },
    },

    td: {
        border: '1px solid #dddddd',
        textAlign: 'left',
        padding: 8,
        width: 400,
    },
    contractTitle: {
        textAlign: 'left',
        margin: '20px 0px',
        fontSize: 25,
        color: '#417AB4',
        display: 'flex',
        alignItems: 'flex-end',
    },
    activityTitle: {
        textAlign: 'left',
        margin: '10px 8px',
        fontSize: 25,
        color: '#417AB4',
        display: 'flex',
        alignItems: 'flex-end',
    },
    icon: {
        fontSize: 40,
        color: '#417AB4',
        marginRight: 10,
    },
    tables: {
        display: 'flex',
        flexWrap: 'wrap',
    },

    comment: {
        flex: '40%',
        marginBottom: '5rem',
    },
    downloadButton: {
        backgroundColor: '#1E8637',
        color: 'white',
        '&:hover': {
            backgroundColor: '#4a9e5e',
            color: '#fff',
        },
    },
    fileDescription: {
        margin: '0 8px',
    },
    message: {
        width: '100%',
        fontSize: '1rem',
    },
    additionalData: {
        marginTop: '1rem',
    },
}))

// eslint-disable-next-line no-unused-vars
const ActivityDetails = ({ activity }) => {
    // eslint-disable-next-line no-unused-vars
    const [open, setOpen] = useState(true)
    const classes = useStyles()

    const locationDetails = (currentActivity) => ({
        uplink:
            currentActivity.lokacija?.uplink &&
            currentActivity.lokacija?.uplink !== 'NULL'
                ? currentActivity.lokacija?.uplink
                : 'Није унето',
        downlink:
            currentActivity.lokacija?.downlink &&
            currentActivity.lokacija?.downlink !== 'NULL'
                ? currentActivity.lokacija?.downlink
                : 'Није унето',
        amres: currentActivity.lokacija?.amres
            ? currentActivity.lokacija?.amres
            : 'Није унето',
        tipSkole:
            currentActivity.lokacija?.tipSkole &&
            currentActivity.lokacija?.tipSkole !== 'NULL'
                ? currentActivity.lokacija?.tipSkole
                : 'Није унето',
    })

    const contactDetails = (currentActivity) => ({
        admContact: (
            <div className={classes.contactWrapper}>
                <div className={classes.contactDetails}>
                    <PermIdentityIcon className={classes.contactIcon} />{' '}
                    {currentActivity?.lokacija?.admKontakt &&
                    currentActivity?.lokacija?.admKontakt !== 'NULL'
                        ? currentActivity?.lokacija?.admKontakt
                        : 'Није унето'}{' '}
                </div>
                <div className={classes.contactDetails}>
                    <MailOutlineIcon className={classes.contactIcon} />
                    {currentActivity?.lokacija?.admKontaktEmail &&
                    currentActivity?.lokacija?.admKontaktEmail !== 'NULL'
                        ? currentActivity?.lokacija?.admKontaktEmail
                        : 'Није унето'}
                </div>
                <div className={classes.contactDetails}>
                    <PhoneIcon className={classes.contactIcon} />
                    {currentActivity?.lokacija?.admKontaktTelefon &&
                    currentActivity?.lokacija?.admKontaktTelefon !== 'NULL'
                        ? currentActivity?.lokacija?.admKontaktTelefon
                        : 'Није унето'}
                </div>
            </div>
        ),
        techContact: (
            <div className={classes.contactWrapper}>
                <div className={classes.contactDetails}>
                    <PermIdentityIcon className={classes.contactIcon} />{' '}
                    {currentActivity.lokacija?.tehLice &&
                    currentActivity.lokacija?.tehLice !== 'NULL'
                        ? currentActivity.lokacija?.tehLice
                        : 'Није унето'}{' '}
                </div>
                <div className={classes.contactDetails}>
                    <MailOutlineIcon className={classes.contactIcon} />
                    {currentActivity.lokacija?.tehLiceEmail &&
                    currentActivity.lokacija?.tehLiceEmail !== 'NULL'
                        ? currentActivity.lokacija?.tehLiceEmail
                        : 'Није унето'}
                </div>
                <div className={classes.contactDetails}>
                    <PhoneIcon className={classes.contactIcon} />
                    {currentActivity.lokacija?.tehLiceTelefon &&
                    currentActivity.lokacija?.tehLiceTelefon !== 'NULL'
                        ? currentActivity.lokacija?.tehLiceTelefon
                        : 'Није унето'}
                </div>
            </div>
        ),
    })
    const filteredActivityFields = (currentActivity) => {
        const responsible = currentActivity.odgovoran.join(', ')
        const startDate = currentActivity?.datumPocetka
            ? moment(currentActivity.datumPocetka).format('DD/MM/YYYY')
            : 'Активност није започета'
        const endDateFlex = currentActivity.rok === null ? 'клизно' : false
        const endDate = currentActivity?.datumZavrsetka
            ? moment(currentActivity.datumZavrsetka).format('DD/MM/YYYY')
            : 'Активност није завршена'
        const rok = currentActivity?.datumPocetka
            ? moment(currentActivity?.datumPocetka)
                  .add(currentActivity.rok, 'd')
                  .format('DD/MM/YYYY')
            : 'Активност није започета'

        return {
            lokacija: currentActivity.lokacija.naziv,
            naziv: currentActivity.naziv,
            ugovor: currentActivity?.lokacija?.ugovor?.naziv
                ? currentActivity?.lokacija?.ugovor?.naziv
                : 'Нема уговор',
            odgovoran: responsible,
            datumPocetka: startDate,
            datumZavrsetka: endDateFlex || endDate,
            rok: endDateFlex || rok,
            status: latToCyr(currentActivity.status),
        }
    }
    const activityHeaderLabel = () => {
        if (activity.kratakNaziv === 'A1') return 'Тип школе'
        if (activity.kratakNaziv === 'A4') return 'Датум'
        if (activity.kratakNaziv === 'A5.1') return ''
        if (activity.kratakNaziv === 'A5.4') return 'Вредност (у динарима)'
        if (activity.kratakNaziv === 'A9') return 'Датум'
        return 'Вредност'
    }

    const additionalDataHeaders = [
        { field: 'naziv', header: 'Назив' },
        { field: 'vrednost', header: activityHeaderLabel() },
    ]

    return (
        <Grid item xs={12} className={classes.contractDiv}>
            <div className={classes.contractTitle}>
                <DescriptionIcon className={classes.icon} />
                <span>Детаљи активности</span>
            </div>

            <BasicTable
                data={[filteredActivityFields(activity)]}
                name="IktDetailsTable"
                commentColumWidth="max-content"
                tableHeaders={[
                    { field: 'naziv', header: 'Назив' },
                    {
                        field: 'lokacija',
                        header: 'Локација',
                    },
                    {
                        field: 'ugovor',
                        header: 'Уговор',
                    },
                    { field: 'status', header: 'Статус' },
                    {
                        field: 'odgovoran',
                        header: 'Одговоран',
                    },
                    {
                        field: 'datumPocetka',
                        header: 'Датум почетка',
                    },
                    {
                        field: 'datumZavrsetka',
                        header: 'Датум завршетка',
                    },
                    {
                        field: 'rok',
                        header: 'Рок',
                    },
                ]}
            />
            {activity.podaci.length > 0 && (
                <Grid container className={classes.additionalData}>
                    <Grid item xs={6}>
                        <BasicTable
                            data={activity.podaci}
                            name="IktAdditionalDataTable"
                            tableHeaders={additionalDataHeaders}
                        />
                    </Grid>
                </Grid>
            )}

            {(activity.kratakNaziv === 'A1' ||
                activity.kratakNaziv === 'A5.3') && (
                <Grid container className={classes.additionalData}>
                    {activity.kratakNaziv === 'A1' && (
                        <Grid item xs={12}>
                            <BasicTable
                                data={[contactDetails(activity)]}
                                name="IktContactDetails"
                                tableHeaders={[
                                    {
                                        field: 'admContact',
                                        header: 'Административни контакт',
                                    },
                                    {
                                        field: 'techContact',
                                        header: 'Техничко лице',
                                    },
                                ]}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} className={classes.locationDetailTable}>
                        <BasicTable
                            data={[locationDetails(activity)]}
                            name="IktLocationDetails"
                            tableHeaders={[
                                {
                                    field: 'uplink',
                                    header: 'Uplink',
                                },
                                {
                                    field: 'downlink',
                                    header: 'Downlink',
                                },
                                {
                                    field: 'amres',
                                    header: 'Амрес',
                                },
                                {
                                    field: 'tipSkole',
                                    header: 'Тип школе',
                                },
                            ]}
                        />
                    </Grid>
                </Grid>
            )}
        </Grid>
    )
}

export default ActivityDetails

ActivityDetails.propTypes = {
    activity: objectOf(PropTypes.any).isRequired,
}
