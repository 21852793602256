import React, { useState } from 'react'
import { Message } from 'primereact/message'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Container, Grid, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useHistory } from 'react-router-dom'
import useSWR from 'swr'
import AddButton from '../../components/FormsUI/Button/AddButton'
import UserTable from '../../components/tables/UserTable'
import CreateSMTP from './CreateSMTP'

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    root: {
        margin: '20px',
        width: 'unset',
    },
    cellButtons: {
        maxWidth: 'min-content',
        cursor: 'default',
    },

    divButtons: {
        '& > *': {
            width: '33%',
        },
    },
    createButton: {
        backgroundColor: '#d9534f',
        color: 'white',
        '&:hover': {
            backgroundColor: '#d9534f',
        },
        padding: 8,
        margin: 20,
        boxShadow: 'none',
    },
    roleBtn: {
        '&:hover': {
            backgroundColor: 'unset',
            boxShadow: 'none',
        },
    },
    divRoot: {
        textAlign: 'left',
    },
    editButton: {
        backgroundColor: '#059D2A',
        color: 'white',
        '&:hover': {
            background: '#059D2A',
        },
    },
    deleteButton: {
        backgroundColor: '#D86565',
        color: 'white',
        '&:hover': {
            background: '#D86565',
        },
    },
    locationsButton: {
        backgroundColor: 'white',
        color: '#D86565',
        '&:hover': {
            background: 'white',
        },
    },
    icon: {
        marginLeft: 5,
    },
    buttonW: {
        margin: '1rem 0',
    },
    message: {
        margin: '2rem 0 ',
    },
})

const EmailConfiguration = () => {
    const history = useHistory()
    const classes = useStyles()
    const [openCreateSMTP, setOpenCreateSMTP] = useState(false)
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
    })

    const [severity, setSeverity] = useState('info')

    const handleSnackBarOpen = () => {
        setSnackbar((previous) => ({
            ...previous,
            open: false,
        }))
    }
    const { data, isValidating } = useSWR(`/api/smtp`)

    const reduceData = (dataArr) =>
        dataArr.reduce((acc, currentEl) => {
            const addElement = {
                _id: currentEl._id,

                email: currentEl.email,
                inUse: currentEl.inUse === false ? 'Не' : 'Да',
                host: currentEl.host,
                port: currentEl.port,
            }
            acc.push(addElement)
            return acc
        }, [])

    const openSmtpModal = () => {
        setOpenCreateSMTP(true)
    }
    const closeSmtpModal = () => {
        setOpenCreateSMTP(false)
    }

    const onRowClick = (rowData) => {
        history.push(`/app/emailconfig/${rowData.data._id}`)
    }
    const haveAcтive = data ? data.smtps.some((el) => el.inUse === true) : false
    return (
        <Container>
            <div className={classes.divRoot}>
                <Snackbar
                    open={snackbar.open}
                    onClose={handleSnackBarOpen}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    autoHideDuration={2000}
                    key="key"
                >
                    <Alert onClose={handleSnackBarOpen} severity={severity}>
                        {snackbar.message}
                    </Alert>
                </Snackbar>

                <CreateSMTP
                    open={openCreateSMTP}
                    close={closeSmtpModal}
                    setSeverity={setSeverity}
                    setSnackbar={setSnackbar}
                />

                <div className={classes.root}>
                    <Box className={classes.buttonW}>
                        <AddButton label="Додај СМТП" click={openSmtpModal} />
                    </Box>{' '}
                    <Grid container justifyContent="center">
                        {!haveAcтive && !isValidating && (
                            <Grid item>
                                <Message
                                    severity="warn"
                                    text="Ни један СМТП није изабран! Уколико не изаберете СМТП сервер е-пошта неће функционисати. ."
                                    className={classes.message}
                                />{' '}
                            </Grid>
                        )}
                    </Grid>
                    {data && (
                        <UserTable
                            model="smtp"
                            data={reduceData(data.smtps)}
                            title="Протоколи електронске поште"
                            totalResults={10}
                            page={1}
                            setPage={() => {}}
                            rowsPerPage={10}
                            setRowsPerPage={() => {}}
                            name="iktSmtps"
                            onRowClick={onRowClick}
                            tableHeaders={[
                                { field: 'email', header: 'Е-пошта' },
                                { field: 'host', header: 'Хост' },
                                { field: 'port', header: 'Порт' },
                                { field: 'inUse', header: 'Користи се' },
                            ]}
                            setSearch={() => {}}
                            search={{}}
                            sortField=""
                            withExport={false}
                            withImport={false}
                            withPagination={false}
                        />
                    )}
                </div>
            </div>
        </Container>
    )
}

export default EmailConfiguration
