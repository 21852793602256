import React, { useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import download from 'downloadjs'
import { Alert } from '@material-ui/lab'
// import moment from 'moment'
import { Grid, Snackbar } from '@material-ui/core'
// import SearchBar from '../../../components/SearchBar/SearchBar'
// import SelectWrapper from '../../../components/FormsUI/Select/SelectWrapper'
import { axiosAuth as axios } from '../../../util/axios-instance'
import AdminTable from '../../../components/tables/AdminTable'
import SimpleModal from '../../../components/Modal/Modal'
import PageTitle from '../../../components/FormsUI/PageTitle/PageTitle'
import InfoPanel from '../../../components/FormsUI/Info/InfoPanel'
import latToCyr from '../../../util/latToCyr'

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    createbutton: {
        backgroundColor: '#d9534f',
        color: 'white',
        '&:hover': {
            backgroundColor: '#d9534f',
        },
        padding: 8,
        margin: 8,
        boxShadow: 'none',
    },
    root: {
        margin: 12,
        textAlign: 'left',
    },
    table: {
        margin: 8,
    },
    editButton: {
        backgroundColor: '#059D2A',
        color: 'white',
        '&:hover': {
            background: '#059D2A',
        },
    },
    deleteButton: {
        backgroundColor: '#D86565',
        color: 'white',
        '&:hover': {
            background: '#D86565',
        },
    },
}))

const AllLocations = () => {
    const history = useHistory()
    const classes = useStyles()
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [locations, setLocations] = useState([])
    const [totalResults, setTotalResults] = useState(0)
    const [load, setLoad] = useState(false)
    // eslint-disable-next-line
    const [error, setError] = useState('')
    const [search, setSearch] = useState({ eng: '', key: '', srb: '' })
    const [searchDebounced] = useDebounce(search, 2000)
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
    })
    const [severity, setSeverity] = useState('info')

    const handleSnackBarOpen = () => {
        setSnackbar((previous) => ({
            ...previous,
            open: false,
        }))
    }

    const getAllLocations = async () => {
        try {
            setLoad(true)
            const url =
                searchDebounced.eng === ''
                    ? `/api/location?page=${page + 1}&limit=${rowsPerPage}`
                    : `/api/search/location?eng=${search.eng}&srb=${
                          search.srb
                      }&page=${page + 1}&limit=${rowsPerPage}`
            const { data } = await axios.get(url)
            const svevidece = await axios.get('/api/group/sve-videci/true')
            const svevideceArr = []
            svevidece.data.groups.map((el) => svevideceArr.push(el))

            // console.log(data)

            const tableData =
                data &&
                data.locations.reduce((acc, currentEl) => {
                    const groupArray = [
                        ...(currentEl?.odgovoran ? currentEl.odgovoran : []),
                        ...svevideceArr,
                    ]
                        .filter((el) => el.tip === 'DOB')
                        .map((el) => el.naziv)
                        .join(', ')

                    const addElement = {
                        id: currentEl._id,
                        ustanova_ID: currentEl?.ustanova_ID
                            ? latToCyr(currentEl.ustanova_ID.toUpperCase())
                            : '',
                        lokacija_ID: currentEl?.lokacija_ID
                            ? latToCyr(currentEl.lokacija_ID.toUpperCase())
                            : '',
                        ustId: parseInt(
                            currentEl.ustanova_ID.replace('u', ''),
                            10
                        ),
                        lokId: currentEl.old_ID,
                        naziv: currentEl?.naziv,
                        adresa: currentEl?.adresa ? currentEl.adresa : '',
                        postanskiBroj: currentEl?.postanskiBroj
                            ? currentEl.postanskiBroj
                            : '',
                        naselje: currentEl?.naselje ? currentEl.naselje : '',
                        opstina: currentEl?.opstina ? currentEl.opstina : '',
                        ugovor: currentEl?.ugovor?.naziv
                            ? currentEl.ugovor.naziv
                            : '',
                        prioritet: currentEl?.prioritet?.naziv
                            ? currentEl.prioritet.naziv
                            : '',
                        izvodjacRadova: groupArray,
                        status: currentEl.status,
                        tlocrt: (
                            <span
                                data-name="download"
                                name="download"
                                style={{
                                    width: '100%',
                                    display: 'inline-block',
                                }}
                            >
                                Преузмите
                            </span>
                        ),
                    }
                    acc.push(addElement)
                    return acc
                }, [])

            setLocations(tableData)

            const dataLength = parseInt(data.totalResults, 10)
            setTotalResults(dataLength)
            setLoad(false)
        } catch (er) {
            setError(er.response)
            setLoad(false)
        }
    }

    useEffect(() => {
        getAllLocations()
    }, [page, rowsPerPage, searchDebounced.eng])

    const rowClass = (rowData) => {
        if (rowData.status === 'Aktivnosti u toku') {
            return { blueRow: true }
        }
        if (rowData.status === 'Aktivnosti završene') {
            return { greenRow: true }
        }
        return { white: true }
    }

    // eslint-disable-next-line
    const downloadFile = async (lokId, ustId) => {
        try {
            const fName = `${ustId}-${lokId}.zip`
            const response = await axios.get(
                `/api/files/tlocrt/${lokId}/${ustId}/${fName}`,
                {
                    responseType: 'blob',
                }
            )
            return download(response.data, fName)
        } catch (err) {
            if (err.response.status === 500) {
                setSeverity('warning')
                setSnackbar({
                    open: true,
                    message: 'На локацији нема тлоцрта',
                })
            }
        }
    }

    const onRowClick = (rowData) => {
        if (
            rowData.originalEvent.target.nodeName === 'TD' ||
            rowData.originalEvent.target.nodeName === 'DIV'
        ) {
            history.push(`/app/lokacije-user/${rowData.data.id}`)
        }

        if (rowData.originalEvent.target.dataset.name === 'download') {
            downloadFile(rowData.data.lokId, rowData.data.ustId)
        }
        // const start = new Date(rowData?.data?.ugovor?.datumPocetka)
        // const zavrsetak = start
        // const rok = rowData?.data?.ugovor?.rokTrajanja
        // zavrsetak.setDate(new Date(start).getDate() + rok)
        // const danas = new Date()
        // const brojDanaDoRoka = moment
        //     .duration(moment(zavrsetak).diff(moment(danas)))
        //     .asDays()
        // const x = brojDanaDoRoka / rok
        // const statusUgovora = rowData?.data?.ugovor?.status
        // const datumZavrsetka = rowData?.data?.ugovor?.datumZavrsetka
        //     ? rowData.data.ugovor.datumZavrsetka
        //     : null

        // console.log('zavrsetak', zavrsetak)
        // console.log('rok', rok)
        // console.log('broj dana do roka', brojDanaDoRoka)
        // console.log('10%', x)
        // console.log('datum zavrsetka', datumZavrsetka)
    }

    if (load) return <SimpleModal />
    // if (error) return <span>{error.data.message}</span>
    return (
        <div className={classes.root}>
            <Snackbar
                open={snackbar.open}
                onClose={handleSnackBarOpen}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
                key="key"
            >
                <Alert onClose={handleSnackBarOpen} severity={severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            <div className={classes.table}>
                <Grid container justifyContent="center">
                    <PageTitle>Локације</PageTitle>
                </Grid>
                {locations.length >= 0 ? (
                    <AdminTable
                        model="location"
                        data={locations}
                        load={load}
                        title="Локације"
                        totalResults={totalResults}
                        page={page}
                        setPage={setPage}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        name="iktUserLocationTable"
                        onRowClick={onRowClick}
                        rowClass={rowClass}
                        tableHeaders={[
                            {
                                field: 'ustanova_ID',
                                header: 'ИД установе',
                            },
                            { field: 'lokacija_ID', header: 'ИД локације' },
                            { field: 'naziv', header: 'Назив установе' },
                            { field: 'adresa', header: 'Адреса локације' },
                            {
                                field: 'postanskiBroj',
                                header: 'Поштански број',
                            },
                            { field: 'opstina', header: 'Општина' },
                            { field: 'naselje', header: 'Насеље' },
                            { field: 'ugovor', header: 'Уговор' },
                            { field: 'prioritet', header: 'Приоритет' },
                            {
                                field: 'izvodjacRadova',
                                header: 'Извођач радова',
                            },
                            { field: 'tlocrt', header: 'Тлоцрт' },
                        ]}
                        actionBodyTemplate=""
                        setSearch={setSearch}
                        search={search}
                        sortField=""
                        exportFileName="Локације"
                    />
                ) : null}
            </div>
            <InfoPanel />
        </div>
    )
}

export default AllLocations
