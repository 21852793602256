import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import {
    userListReducer,
    guestsListReducer,
    myInfoReducer,
    myRoleReducer,
} from './Redux/reducers/userReducers'
import { groupListReducer, singleGroup } from './Redux/reducers/groupReducers'
import { importIdReducer } from './Redux/reducers/importReducers'

const reducer = combineReducers({
    allUsers: userListReducer,
    allGuests: guestsListReducer,
    allGroups: groupListReducer,
    singleGroup: singleGroup,
    myinfo: myInfoReducer,
    myrole: myRoleReducer,
    importInfo: importIdReducer,
})

const initialState = {}
const middleware = [thunk]

const store = createStore(
    reducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
)

export default store
