import { Column } from 'primereact/column'
import { makeStyles } from '@material-ui/core/styles'
import { DataTable } from 'primereact/datatable'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button, Grid, Snackbar, Tooltip } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import moment from 'moment'
import { axiosAuth as axios } from '../../../../util/axios-instance'
import AddButton from '../../../../components/FormsUI/Button/AddButton'
import AddLocationInventory from './AddLocationInventory'
import EditLocationInventory from './EditLocationInventory'
import DeleteLocationInventory from './DeleteLocationInventory'
import latToCyr from '../../../../util/latToCyr'
import BackToButton from '../../../../components/FormsUI/Button/BackToButton'
import PageTitle from '../../../../components/FormsUI/PageTitle/PageTitle'
import ButtonImportInventory from '../../../../components/FormsUI/Button/Buttons-ImportExports/ButtonImportInventory'
import SimpleModal from '../../../../components/Modal/Modal'
import importExcel from '../../../../util/import'

const useStyles = makeStyles(() => ({
    editButton: {
        backgroundColor: '#059D2A',
        color: 'white',
        '&:hover': {
            background: '#059D2A',
        },
    },
    deleteButton: {
        backgroundColor: '#D86565',
        color: 'white',
        '&:hover': {
            background: '#D86565',
        },
    },
    tableCell: {
        width: '100%',

        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        display: '-webkit-box',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
    },
    closeButton: {
        backgroundColor: 'rgb(201,83,83)',
        color: 'white',
        '&:hover': {
            backgroundColor: 'rgb(201,83,83,0.9)',
        },
        padding: 8,
        margin: '8px 8px 8px 0',
        boxShadow: 'none',
        letterSpacing: 0.5,
    },
}))

const LocationInventory = ({ data, closeModal, refresh }) => {
    const classes = useStyles()
    const history = useHistory()
    const { id } = useParams()
    const [inventory, setInventory] = useState([])
    const [location, setLocation] = useState(null)
    const [addOpen, setAddOpen] = useState(false)
    const [load, setLoad] = useState(false)
    const [editOpen, setEditOpen] = useState(false)
    const [deleteOpen, setDeleteOpen] = useState(false)
    const [invData, setInvData] = useState(null)
    const [refreshState, setRefreshState] = useState(false)
    const [types, setTypes] = useState([])
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: 'default',
    })
    const [severity, setSeverity] = useState('info')
    const handleSnackBarOpen = () => {
        setSnackbar((previous) => ({
            ...previous,
            open: false,
        }))
    }

    const newId =
        history?.location?.state?.from?.page === 'contracts' ? id : data?._id

    const contractId =
        history?.location?.state?.from?.page === 'contracts'
            ? history?.location?.state?.from?.contractId
            : data?.ugovor?._id

    const getLocationInventory = async () => {
        try {
            setLoad(true)

            const response = await axios.get(
                `/api/inventory?lokacija=${newId}&ugovor=${contractId}`
            )
            const tableData = response.data.inventory.reduce(
                (acc, currentEl) => {
                    const addElement = {
                        _id: currentEl._id,
                        datumPrimopredaje: currentEl.datumPrimopredaje
                            ? moment(currentEl.datumPrimopredaje).format(
                                  'DD/MM/YYYY'
                              )
                            : '',
                        invType: currentEl.invType,
                        tip: latToCyr(currentEl.invType.tip),
                        kolicina: currentEl.kolicina,
                        lokacija: currentEl.lokacija,
                        cenaRsd: currentEl.cenaRsd,
                    }
                    acc.push(addElement)
                    return acc
                },
                []
            )
            setInventory(tableData)
            setLoad(false)
        } catch (error) {
            console.log(error)
        }
    }

    const getInvTypes = async () => {
        try {
            // setLoad(true)
            const response = await axios.get('/api/inv-type')
            setTypes(response.data)
            // setLoad(false)
        } catch (error) {
            console.log(error)
        }
    }

    const getLocation = async () => {
        try {
            // setLoad(true)
            const response = await axios.get(`/api/location/${newId}`)
            setLocation(response.data.location)
            // setLoad(false)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getInvTypes()
        getLocation()
    }, [])

    useEffect(() => {
        if (!refresh || !refreshState) getLocationInventory()
    }, [refresh, refreshState])

    const actionBodyTemplate = () => (
        <Grid key="edit" container justifyContent="space-between">
            <Grid item xs={5} className={classes.buttonWrapper}>
                <Button name="izmeni" fullWidth className={classes.editButton}>
                    Измени
                </Button>
            </Grid>
            <Grid item xs={5} className={classes.buttonWrapper}>
                <Button
                    name="obrisi"
                    color="secondary"
                    fullWidth
                    className={classes.deleteButton}
                >
                    Обриши
                </Button>
            </Grid>
        </Grid>
    )
    const onRowClick = (rowData) => {
        if (
            rowData.originalEvent.target.nodeName === 'SPAN' &&
            rowData.originalEvent.target.parentNode.name === 'izmeni'
        ) {
            setEditOpen(true)
            setInvData(rowData.data)
        }
        if (
            rowData.originalEvent.target.nodeName === 'SPAN' &&
            rowData.originalEvent.target.parentNode.name === 'obrisi'
        ) {
            setDeleteOpen(true)
            setInvData(rowData.data)
        }
    }

    const addInventory = () => {
        setAddOpen(true)
    }

    const cellData = (rowData, cellTooltip) => (
        <Tooltip
            title={
                rowData.invType[cellTooltip] ? rowData.invType[cellTooltip] : ''
            }
        >
            <div className={classes.tableCell}>
                {' '}
                {rowData.invType[cellTooltip]}
            </div>
        </Tooltip>
    )

    const importInv = (event, importId) => {
        importExcel(event, importId, setLoad)
    }

    return (
        <div style={{ margin: 30 }}>
            <Snackbar
                open={snackbar.open}
                onClose={handleSnackBarOpen}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                autoHideDuration={3500}
                key="key"
            >
                <Alert onClose={handleSnackBarOpen} severity={severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            {addOpen && (
                <AddLocationInventory
                    modalOpen={addOpen}
                    setModal={setAddOpen}
                    setRefresh={setRefreshState}
                    setSeverityHandler={setSeverity}
                    setSnackbarHandler={setSnackbar}
                    locId={newId}
                    types={types}
                    contractId={location?.ugovor}
                />
            )}
            {deleteOpen && invData && (
                <DeleteLocationInventory
                    id={invData._id}
                    modalOpen={deleteOpen}
                    setModal={setDeleteOpen}
                    setRefresh={setRefreshState}
                    setSeverityHandler={setSeverity}
                    setSnackbarHandler={setSnackbar}
                />
            )}
            {editOpen && invData && (
                <EditLocationInventory
                    data={invData}
                    modalOpen={editOpen}
                    setModal={setEditOpen}
                    setRefresh={setRefreshState}
                    setSeverityHandler={setSeverity}
                    setSnackbarHandler={setSnackbar}
                    locId={id}
                    types={types}
                />
            )}
            {location && history?.location?.state?.from === 'contracts' && (
                <PageTitle title={`Инвентар на локацији ${location.naziv}`} />
            )}

            <Grid container spacing={2}>
                <Grid item xs={12} style={{ textAlign: 'left' }}>
                    <Grid container alignItems="baseline">
                        {history?.location?.state?.from === 'contracts' ? (
                            <Grid item>
                                <BackToButton label="Назад на списак локација" />
                            </Grid>
                        ) : (
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() =>
                                        history?.location?.state?.from?.page ===
                                        'contracts'
                                            ? history.goBack()
                                            : closeModal()
                                    }
                                    className={classes.closeButton}
                                >
                                    {history?.location?.state?.from?.page ===
                                    'contracts'
                                        ? 'Назад'
                                        : 'Затвори'}
                                </Button>
                            </Grid>
                        )}
                        <Grid item>
                            <AddButton
                                label="Додај инвентар"
                                click={addInventory}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            {location && (
                                <Grid item xs={2}>
                                    <ButtonImportInventory
                                        id={newId}
                                        setRefresh={setRefreshState}
                                        setSeverityHandler={setSeverity}
                                        setSnackbarHandler={setSnackbar}
                                        setLoad={setLoad}
                                        closeModal={closeModal}
                                        importInv={importInv}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <DataTable
                        value={inventory}
                        onRowClick={onRowClick}
                        className="blackTable"
                        showGridlines
                        emptyMessage="Локација је без инвентара"
                    >
                        <Column
                            field="invType.naziv"
                            header="Назив"
                            body={(rowData) => cellData(rowData, 'naziv')}
                        />
                        <Column
                            field="tip"
                            header="Тип"
                            style={{
                                width: '250px',
                            }}
                        />
                        <Column
                            field="kolicina"
                            header="Количина"
                            style={{
                                width: '150px',
                            }}
                        />
                        <Column
                            field="datumPrimopredaje"
                            header="Датум примопредаје"
                            style={{
                                width: '200px',
                            }}
                        />
                        <Column
                            field={actionBodyTemplate}
                            style={{
                                width: '200px',
                            }}
                        />
                    </DataTable>
                </Grid>
            </Grid>
            {load && <SimpleModal />}
        </div>
    )
}

export default LocationInventory

LocationInventory.propTypes = {
    data: PropTypes.objectOf(PropTypes.any),
    closeModal: PropTypes.func,
    refresh: PropTypes.bool,
}

LocationInventory.defaultProps = {
    data: {},
    closeModal: () => {},
    refresh: false,
}
