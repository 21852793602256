import React from 'react'
import PropTypes from 'prop-types'
import {
    Grid,
    makeStyles,
    Button,
    Tooltip,
    Typography,
} from '@material-ui/core'
import { Form, Formik } from 'formik'
import download from 'downloadjs'
import * as Yup from 'yup'

import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import MultipleFileUploadField from '../upload/MultipleFileUploadField'
import StepChangeStatus from './StepChangeStatus'
import StepChangeControlStatus from '../ControllActivityStepper/StepChangeControlStatus'
import { axiosAuth } from '../../../util/axios-instance'

const useStyles = makeStyles(() => ({
    // cardMargin: {
    //     margin: 'auto',
    // },
    // paper: {
    //     padding: 20,
    // },
    siteSurveyWrapper: {
        marginBottom: 20,
        display: 'flex',
        justifyContent: 'flex-start',
    },
    iconFile: {
        color: 'white',
        marginRight: 5,
    },
    importSchema: {
        background: '#1E8637',
        color: 'white',
        '&:hover': {
            background: '#059D2A',
        },
        marginLeft: 3,
    },
    fileNumber: {
        marginTop: 25,
    },
    formWidth: {
        width: 300,
    },
}))

const StepUploadFiles = ({
    activity,
    connected,
    setSeverity,
    setSnackbar,
    allFiles,
    setError,

    withChanges,
}) => {
    const classess = useStyles()

    const INITIAL_FILE_STATE = {
        files: [],
    }

    const FILE_VALIDATION = Yup.object().shape({
        files: Yup.array().of(
            Yup.object().shape({
                errors: Yup.array().max(0, 'Имате грешку при увозу података'),
            })
        ),
        // .test({
        //     message: `Потребан број фајлова за активност: ${activity.brojFajlova}`,
        //     test: (arr) => arr.length >= activity.brojFajlova,
        // }),
    })

    /* eslint-disable */

    const checkFiles = (files) => {
        // console.log('Fajlovi iz checkfile', files)
        const demandedFilesNumber = files.filter((file) =>
            file.includes(`revizija=${activity.returned}`)
        ).length

        return demandedFilesNumber
    }
    const findSubActivity = (activityData) => {
        if (activityData.kontrolna) {
            const subActivity = connected.find((el) => {
                if (withChanges === 'true') return el.kratakNaziv === 'A3.1'
                if (withChanges === 'false') {
                    const isA3 = el.kratakNaziv === 'A3.3'

                    return isA3
                        ? el.kratakNaziv === 'A3.3'
                        : el.kratakNaziv === 'A7.1'
                }
            })
            return subActivity._id
        }
        return activityData._id
    }

    const downloadFile = async (fName) => {
        try {
            const response = await axiosAuth.get(`/api/files/get/checklist`, {
                responseType: 'blob',
            })

            return download(response.data, fName)
        } catch (error) {
            console.log(error.response)
        }
    }

    const filesToUpload = () => {
        if (activity.returned === 0) {
            const filesDifference = activity.brojFajlova * 1 - allFiles.length

            return filesDifference
        }

        return activity.brojFajlova * 1 - checkFiles(allFiles)
    }

    return (
        <Grid container className={classess.cardMargin}>
            <Grid item xs={12}>
                <Formik
                    initialValues={{ ...INITIAL_FILE_STATE }}
                    validationSchema={FILE_VALIDATION}
                    // onSubmit={fileUpload}
                >
                    {({ isValid, isSubmitting, errors }) => (
                        <Form>
                            <Grid container>
                                {activity.kratakNaziv === 'A5.2' && (
                                    <Grid
                                        item
                                        xs={12}
                                        className={classess.siteSurveyWrapper}
                                    >
                                        <Tooltip
                                            title="Овде можете скинути документ са инструкцијом односно чеклистом за снимање стања у школи."
                                            placement="top"
                                        >
                                            <Button
                                                className={
                                                    classess.importSchema
                                                }
                                                onClick={() =>
                                                    downloadFile('Чеклиста')
                                                }
                                            >
                                                <DescriptionOutlinedIcon
                                                    className={
                                                        classess.iconFile
                                                    }
                                                />
                                                Шаблон чеклисте
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                )}

                                {activity.kratakNaziv !== 'A2' && (
                                    <Grid item xs={12}>
                                        <MultipleFileUploadField
                                            name="files"
                                            activity={activity}
                                            setSeverity={setSeverity}
                                            setSnackbar={setSnackbar}
                                            setError={setError}
                                        />

                                        <>
                                            {filesToUpload() > 0 && (
                                                <Typography
                                                    color="error"
                                                    className={
                                                        classess.fileNumber
                                                    }
                                                >
                                                    {`Број фајлова неопходан за увоз: ${filesToUpload()}`}
                                                </Typography>
                                            )}
                                            {filesToUpload() <= 0 && (
                                                <Typography
                                                    color="primary"
                                                    className={
                                                        classess.fileNumber
                                                    }
                                                >
                                                    {`Увезли сте довољан број фајлова.`}
                                                </Typography>
                                            )}
                                        </>
                                    </Grid>
                                )}
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Grid>
            {activity.kontrolna ? (
                <StepChangeControlStatus
                    activity={activity}
                    setSeverity={setSeverity}
                    setSnackbar={setSnackbar}
                    setError={setError}
                    allFiles={allFiles}
                />
            ) : (
                <StepChangeStatus
                    activity={activity}
                    setSeverity={setSeverity}
                    setSnackbar={setSnackbar}
                    setError={setError}
                    allFiles={allFiles}
                />
            )}
        </Grid>
    )
}

export default StepUploadFiles

StepUploadFiles.propTypes = {
    activity: PropTypes.objectOf(PropTypes.any).isRequired,
    connected: PropTypes.arrayOf(PropTypes.any),
    setSeverity: PropTypes.func.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    handleNext: PropTypes.func.isRequired,
    setError: PropTypes.func.isRequired,
    allFiles: PropTypes.arrayOf(PropTypes.any),
    // setShowStepLabel: PropTypes.func.isRequired,
    withChanges: PropTypes.string,
}

StepUploadFiles.defaultProps = {
    withChanges: 'false',
    connected: [],
    allFiles: [],
}
