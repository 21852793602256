import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import PropTypes from 'prop-types'
import LocationInventory from '../Contracts/LocationInventory'

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    }
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: '90%',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        height: '90%',
        overflow: 'auto',
    },
    title: {
        textAlign: 'center',
    },
    formField: {
        padding: 10,
    },
    dateDiv: {
        width: '100%',
        padding: 10,
    },
    removeIcon: {
        cursor: 'pointer',
        color: 'red',
        fontSize: 20,
        margin: '0 0 0 10px',
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 10,
    },
}))

export default function EditInventoryModal({
    data,
    modalOpen,
    setModal,
    refresh,
}) {
    const classes = useStyles()
    const [modalStyle] = React.useState(getModalStyle)

    const body = (
        <>
            <div style={modalStyle} className={classes.paper}>
                <h2 className={classes.title}>
                    Измена инвентара на локацији {data?.naziv}
                </h2>
                <LocationInventory
                    data={data}
                    refresh={refresh}
                    closeModal={() => setModal(false)}
                />
            </div>
        </>
    )

    return (
        <div>
            <Modal
                open={modalOpen}
                onClose={() => setModal(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </div>
    )
}

EditInventoryModal.propTypes = {
    data: PropTypes.objectOf(PropTypes.any),
    modalOpen: PropTypes.bool,
    setModal: PropTypes.func,
    refresh: PropTypes.bool,
}

EditInventoryModal.defaultProps = {
    data: {},
    modalOpen: false,
    setModal: () => {},
    refresh: false,
}
