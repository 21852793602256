import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'
import Logo from '../assets/logo.png'

const useStyles = makeStyles((theme) => ({
    message: {
        maxWidth: '90%',
        color: '#FFFFFF',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        },
    },
    formContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    formWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8),
    },
    // alignAll: {
    //     display: 'flex',
    //     justifyContent: 'flex-end',
    //     alignItems: 'center',
    // },
    Leftside: {
        // height: '100vh',
        background: '#4E5054',
        textAlign: 'center',
    },
    Rightside: {
        background: '#F79256',
        display: 'flex',
        justifyContent: 'center',
    },
    logocenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '50%',
    },
    logoSize: {
        width: 50,
        marginRight: '1rem',
    },
    logodlpmsize: {
        width: 300,
    },
    titleLogo: {
        maxWidth: '90%',
        color: '#FFFFFF',
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        },
    },
    marginB: {
        marginBottom: 35,
    },
    logoContainer: {
        height: '100vh',
        [theme.breakpoints.down('sm')]: {
            height: '100%',
            padding: '1rem 0',
        },
    },
    dlpmlogo: {
        position: 'absolute',
        bottom: 10,
    },
}))

const Login = () => {
    const classes = useStyles()

    // ctx.handleAuthHeader(true)
    return (
        <Grid container className={classes.mainCointainer}>
            <Grid item xs={12} md={6} className={classes.Leftside}>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    className={classes.logoContainer}
                >
                    <Grid item xs={12} className={classes.logocenter}>
                        <img
                            src={Logo}
                            alt="logo"
                            className={classes.logoSize}
                        />
                        <div>
                            <Typography
                                variant="h2"
                                className={classes.titleLogo}
                            >
                                Министарство трговине, туризма и
                                телекомуникација
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6} className={classes.Rightside}>
                <Grid container justifyContent="center" alignContent="center">
                    <Grid item className={classes.formContainer}>
                        <Typography variant="h2" className={classes.message}>
                            Успешно сте потврдили пријем пописне листе инвентара
                            у Вашој школи.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Login
