import React from 'react'
import { useFormikContext } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import TextfieldWrapper from '../../../components/FormsUI/Textfield/TextfieldWrapper'
import CheckboxWrapper from '../../../components/FormsUI/Checkbox/CheckboxWrapper'

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 800,
        height: 'auto',
        maxHeight: '90%',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        overflow: 'auto',
    },
    title: {
        textAlign: 'center',
    },
    formField: {
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
    },
    dateDiv: {
        width: '100%',
        padding: 10,
    },
}))

const Step7 = () => {
    const { values } = useFormikContext()
    const classes = useStyles()

    return (
        <div>
            <div className={classes.formField}>
                <span className={classes.tableRowLabel}>PIB</span>
                <TextfieldWrapper name="pib" value={values.pib} />
            </div>
            <div className={classes.formField}>
                <span className={classes.tableRowLabel}>Матични број</span>
                <TextfieldWrapper
                    name="maticniBroj"
                    value={values.maticniBroj}
                />
            </div>
            <div className={classes.formField}>
                <span className={classes.tableRowLabel}>
                    Број ученика на локацији
                </span>
                <TextfieldWrapper
                    name="brojUcenika"
                    value={values.brojUcenika}
                />
            </div>
            <div className={classes.formField}>
                <span className={classes.tableRowLabel}>Координате</span>
                <TextfieldWrapper
                    name="geokoordinate"
                    value={values.geokoоrdinate}
                    placeholder="дужина, ширина"
                />
            </div>
            <div className={classes.formField}>
                <CheckboxWrapper
                    name="gpon"
                    label="GPON"
                    currentValue={values.gpon}
                />
            </div>
        </div>
    )
}

export default Step7
