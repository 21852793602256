import React from 'react'
import { useFormikContext } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import TextfieldWrapper from '../../../components/FormsUI/Textfield/TextfieldWrapper'

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 800,
        height: 'auto',
        maxHeight: '90%',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        overflow: 'auto',
    },
    title: {
        textAlign: 'center',
    },
    formField: {
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
    },
    dateDiv: {
        width: '100%',
        padding: 10,
    },
}))

const Step3 = ({ networks }) => {
    const classes = useStyles()
    const { values, setFieldValue } = useFormikContext()

    return (
        <div>
            <div className={classes.formField}>
                <span className={classes.tableRowLabel}>Амрес</span>
                {/* <EditSelects
                    name="amres"
                    options={networks}
                    label="Амрес"
                    currentValue={values.amres}
                /> */}
                <FormControl fullWidth className={classes.formControl}>
                    {/* <InputLabel id="demo-simple-select-label">
                        Амрес
                    </InputLabel> */}
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values.amres}
                        label="Амрес"
                        onChange={(event) => {
                            setFieldValue('amres', event.target.value)
                        }}
                    >
                        {networks.map((network) => (
                            <MenuItem value={network.naziv} key={network._id}>
                                {network.naziv}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div className={classes.formField}>
                <span className={classes.tableRowLabel}>ИД линије</span>
                <TextfieldWrapper name="id_linije" value={values.id_linije} />
            </div>
            <div className={classes.formField}>
                <span className={classes.tableRowLabel}>Uplink</span>
                <TextfieldWrapper name="uplink" value={values.uplink} />
            </div>
            <div className={classes.formField}>
                <span className={classes.tableRowLabel}>Downlink</span>
                <TextfieldWrapper name="downlink" value={values.downlink} />
            </div>
        </div>
    )
}
Step3.propTypes = {
    networks: PropTypes.arrayOf(PropTypes.any),
}

Step3.defaultProps = {
    networks: [],
}

export default Step3
