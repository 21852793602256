import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import { Alert } from '@material-ui/lab'
import { Button, CircularProgress, Snackbar } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import ButtonWrapper from '../../../components/FormsUI/Button/ButtonWrapper'
import { axiosAuth as axios } from '../../../util/axios-instance'
import SimpleModal from '../../../components/Modal/Modal'
import Step1 from '../CreateLocation/Step1'
import Step8 from '../CreateLocation/Step8'
import Step2 from '../CreateLocation/Step2'
import Step3 from '../CreateLocation/Step3'
import Step4 from '../CreateLocation/Step4'
import Step5 from '../CreateLocation/Step5'
import Step6 from '../CreateLocation/Step6'
import Step7 from '../CreateLocation/Step7'

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    }
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 800,
        height: 'auto',
        maxHeight: '90%',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        overflow: 'auto',
    },
    title: {
        textAlign: 'center',
    },
    formField: {
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
    },
    dateDiv: {
        width: '100%',
        padding: 10,
    },
}))

export default function EditLocation({
    id,
    modalOpen,
    setModal,
    setRefresh,
    setSeverityHandler,
    setSnackbarHandler,
}) {
    const classes = useStyles()
    const [modalStyle] = React.useState(getModalStyle)
    const [contracts, setContracts] = useState([])
    const [initValues, setInitValues] = useState(false)
    const [priorities, setPriorities] = useState([])
    const [groups, setGroups] = useState([])
    const [networks, setNetworks] = useState([])
    const [responsible, setResponsible] = useState(false)
    // const [locationStatus, setLocationStatus] = useState('')
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
    })
    const [severity, setSeverity] = useState('info')

    const handleSnackBarOpen = () => {
        setSnackbar((previous) => ({
            ...previous,
            open: false,
        }))
    }
    const FORM_VALIDATION = Yup.object().shape({
        // step1
        naziv: Yup.string().required('Поље је обавезно'),
        ustanova_ID: Yup.string().required('Поље је обавезно'),
        old_ID: Yup.number()
            .test(
                'len',
                'Морате унети највише 5 цифри',
                (val) => val?.toString().length <= 5
            )
            .required('Поље је обавезно')
            .typeError('Морате унети број'),
        primarnaDelatnost: Yup.string(),
        ugovor: Yup.string(),
        odgovoran: Yup.array().of(Yup.string()),
        vrstaLokacije: Yup.string(),
        adresa: Yup.string(),
        postanskiBroj: Yup.string().typeError('Морате унети број'),
        naselje: Yup.string(),
        opstina: Yup.string(),
        skolskaUprava: Yup.string(),
        // step2
        nazivSkole: Yup.string().required('Поље је обавезно'),
        brojZgrada: Yup.string().typeError('Морате унети број'),
        ukupnoProstorija: Yup.string().typeError('Морате унети број'),
        ukupnoUcionica: Yup.string().typeError('Морате унети број'),
        brojLaboratorija: Yup.string().typeError('Морате унети број'),
        brojKabineta: Yup.string().typeError('Морате унети број'),
        brojBiblioteka: Yup.string().typeError('Морате унети број'),
        ostaleProstorije: Yup.string().typeError('Морате унети број'),
        // step3
        amres: Yup.string(),
        id_linije: Yup.string(),
        uplink: Yup.string(),
        downlink: Yup.string(),
        // step4
        tipSkole: Yup.string(),
        brojSmena: Yup.string().typeError('Морате унети број'),
        deliVezu: Yup.string(),
        domacin: Yup.string(),
        gost: Yup.string(),
        ukupnoUcenika: Yup.string(),
        // step 5
        prioritet: Yup.string(),
        // odgovoran: Yup.string(),
        telefonSkole: Yup.string(),
        emailSkole: Yup.string(),
        direktor: Yup.string(),
        direktorTelefon: Yup.string(),
        direktorEmail: Yup.string(),
        // step6
        admKontakt: Yup.string(),
        admKontaktTelefon: Yup.string(),
        admKontaktEmail: Yup.string(),
        tehLice: Yup.string(),
        tehLiceTelefon: Yup.string(),
        tehLiceEmail: Yup.string(),
        // step7
        pib: Yup.string().typeError('Морате унети број'),
        maticniBroj: Yup.string().typeError('Морате унети број'),
        brojUcenika: Yup.string().typeError('Морате унети број'),
        geokoordinate: Yup.string(),
        gpon: Yup.bool(),
    })

    const getLocation = async () => {
        try {
            const response = await axios.get(`/api/location/${id}`)

            setInitValues({
                // step1
                naziv: response?.data?.location?.naziv
                    ? response.data.location.naziv
                    : '',
                old_ID: response?.data?.location?.old_ID
                    ? response.data.location.old_ID
                    : '',
                ustanova_ID: response?.data?.location?.ustanova
                    ? response.data.location.ustanova
                    : response.data.location.ustanova_ID.slice(1),
                ugovor: response?.data?.location?.ugovor
                    ? response.data.location.ugovor._id
                    : '',
                primarnaDelatnost: response?.data?.location?.primarnaDelatnost
                    ? response.data.location.primarnaDelatnost
                    : '',
                vrstaLokacije: response?.data?.location?.vrstaLokacije
                    ? response.data.location.vrstaLokacije
                    : '',
                adresa: response?.data?.location?.adresa
                    ? response.data.location.adresa
                    : '',
                postanskiBroj: response?.data?.location?.postanskiBroj
                    ? response.data.location.postanskiBroj
                    : '',
                naselje: response?.data?.location?.naselje
                    ? response.data.location.naselje
                    : '',
                opstina: response?.data?.location?.opstina
                    ? response.data.location.opstina
                    : '',
                skolskaUprava: response?.data?.location?.skolskaUprava
                    ? response.data.location.skolskaUprava
                    : '',
                // step2
                nazivSkole: response?.data?.location?.nazivSkole
                    ? response.data.location.nazivSkole
                    : '',
                brojZgrada: response?.data?.location?.brojZgrada
                    ? response.data.location.brojZgrada
                    : '',
                ukupnoProstorija: response?.data?.location?.ukupnoProstorija
                    ? response.data.location.ukupnoProstorija
                    : '',
                ukupnoUcionica: response?.data?.location?.ukupnoUcionica
                    ? response.data.location.ukupnoUcionica
                    : '',
                brojLaboratorija: response?.data?.location?.brojLaboratorija
                    ? response.data.location.brojLaboratorija
                    : '',
                brojKabineta: response?.data?.location?.brojKabineta
                    ? response.data.location.brojKabineta
                    : '',
                brojBiblioteka: response?.data?.location?.brojBiblioteka
                    ? response.data.location.brojBiblioteka
                    : '',
                ostaleProstorije: response?.data?.location?.ostaleProstorije
                    ? response.data.location.ostaleProstorije
                    : '',
                // step3
                amres: response?.data?.location?.amres
                    ? response.data.location.amres
                    : '',
                id_linije: response?.data?.location?.id_linije
                    ? response.data.location.id_linije
                    : '',
                uplink: response?.data?.location?.uplink
                    ? response.data.location.uplink
                    : '',
                downlink: response?.data?.location?.downlink
                    ? response.data.location.downlink
                    : '',
                // step4
                tipSkole: response?.data?.location?.tipSkole
                    ? response.data.location.tipSkole
                    : '',
                brojSmena: response?.data?.location?.brojSmena
                    ? response.data.location.brojSmena
                    : '',
                deliVezu: response?.data?.location?.deliVezu ? 'true' : 'false',
                domacin: response?.data?.location?.domacin
                    ? response.data.location.domacin
                    : '',
                gost: response?.data?.location?.gost
                    ? response.data.location.gost
                    : '',
                ukupnoUcenika: response?.data?.location?.ukupnoUcenika
                    ? response.data.location.ukupnoUcenika
                    : '',
                // step5
                prioritet: response?.data?.location?.prioritet
                    ? response.data.location.prioritet
                    : '',
                odgovoran: response?.data?.location?.odgovoran
                    ? response.data.location.odgovoran.map((odg) => odg._id)
                    : '',
                telefonSkole: response?.data?.location?.telefonSkole
                    ? response.data.location.telefonSkole
                    : '',
                emailSkole: response?.data?.location?.emailSkole
                    ? response.data.location.emailSkole
                    : '',
                direktor: response?.data?.location?.direktor
                    ? response.data.location.direktor
                    : '',
                direktorTelefon: response?.data?.location?.direktorTelefon
                    ? response.data.location.direktorTelefon
                    : '',
                direktorEmail: response?.data?.location?.direktorEmail
                    ? response.data.location.direktorEmail
                    : '',
                // step6
                admKontakt: response?.data?.location?.admKontakt
                    ? response.data.location.admKontakt
                    : '',
                admKontaktTelefon: response?.data?.location?.admKontaktTelefon
                    ? response.data.location.admKontaktTelefon
                    : '',
                admKontaktEmail: response?.data?.location?.admKontaktEmail
                    ? response.data.location.admKontaktEmail
                    : '',
                tehLice: response?.data?.location?.tehLice
                    ? response.data.location.tehLice
                    : '',
                tehLiceTelefon: response?.data?.location?.tehLiceTelefon
                    ? response.data.location.tehLiceTelefon
                    : '',
                tehLiceEmail: response?.data?.location?.tehLiceEmail
                    ? response.data.location.tehLiceEmail
                    : '',
                // step7
                pib: response?.data?.location?.pib
                    ? response.data.location.pib
                    : '',
                maticniBroj: response?.data?.location?.maticniBroj
                    ? response.data.location.maticniBroj
                    : '',
                brojUcenika: response?.data?.location?.brojUcenika
                    ? response.data.location.brojUcenika
                    : '',
                geokoordinate: response?.data?.location?.geokoordinate
                    ? response.data.location.geokoordinate
                    : '',
                gpon: response?.data?.location?.gpon,
            })
            setResponsible(response.data.location.odgovoran)
            console.log(response.data.location)
        } catch (error) {
            console.log(error)
        }
    }

    const getPriorities = async () => {
        try {
            const response = await axios.get('/api/priority')
            setPriorities(response.data.priorities)
        } catch (error) {
            console.log(error)
        }
    }

    const getAllGroups = async () => {
        try {
            // const response = await axios.get('/api/group?limit=5000')
            // setGroups(response.data.data)
            const svevidece = await axios.get('/api/group/sve-videci/false')
            setGroups(
                svevidece.data.groups.filter(
                    (group) =>
                        group.naziv !== 'Админ' && group.naziv !== 'Admin'
                )
            )
        } catch (error) {
            console.log(error)
        }
    }

    const getAllContracts = async () => {
        try {
            const response = await axios.get('/api/contract')
            setContracts(response.data.contracts)
        } catch (error) {
            console.log(error)
        }
    }

    const getNetworks = async () => {
        try {
            const response = await axios.get('/api/network')
            setNetworks(response.data.networks)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getLocation()
        getAllGroups()
        getPriorities()
        getAllContracts()
        getNetworks()
    }, [])

    const editLocation = async (values) => {
        // const keys = Object.keys(values)
        // const newState = Object.values(values)
        // const initialState = Object.values(initValues)
        // const x = {}
        // for (let i = 0; i < newState.length; i += 1) {
        //     if (newState[i] !== initialState[i]) {
        //         x[keys[i]] = newState[i]
        //     }
        // }
        // console.log(x)
        try {
            let uId = `u${values.ustanova_ID}`
            if (values.ustanova_ID.length === 4) {
                uId = `u0${values.ustanova_ID}`
            } else if (values.ustanova_ID.length === 3) {
                uId = `u00${values.ustanova_ID}`
            } else if (values.ustanova_ID.length === 2) {
                uId = `u000${values.ustanova_ID}`
            } else if (values.ustanova_ID.length === 1) {
                uId = `u0000${values.ustanova_ID}`
            }

            let lId = `l${values.old_ID}`
            if (values.old_ID.length === 4) {
                lId = `l0${values.old_ID}`
            } else if (values.old_ID.length === 3) {
                lId = `l00${values.old_ID}`
            } else if (values.old_ID.length === 2) {
                lId = `l000${values.old_ID}`
            } else if (values.old_ID.length === 1) {
                lId = `l0000${values.old_ID}`
            }
            setRefresh(true)
            await axios.patch(`/api/location/${id}`, {
                ...values,
                // odgovoran: values.odgovoran !== '' ? [values.odgovoran] : [],
                deliVezu: values.deliVezu === 'true',
                ustanova_ID: uId, // slovo
                ustanova: values.ustanova_ID, // broj
                lokacija_ID: lId, // slovo
                old_ID: values.old_ID, // broj
            })
            setModal(false)
            setSeverityHandler('success')
            setSnackbarHandler({
                open: true,
                message: 'Успешно сте изменили локацију.',
            })
            setRefresh(false)
        } catch (error) {
            setSeverity('warning')
            setSnackbar({
                open: true,
                message: error?.response?.data?.message
                    ? error.response.data.message
                    : 'Измена локације неуспешна.',
            })
        }
        // console.log({
        //     ...values,
        //     odgovoran: values.odgovoran !== '' ? [values.odgovoran] : [],
        //     deliVezu: values.deliVezu === 'true',
        // })
    }

    const setResponsibleHandler = (state) => {
        setResponsible(state)
    }

    const body = (
        <>
            <Snackbar
                open={snackbar.open}
                onClose={handleSnackBarOpen}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
                key="key"
            >
                <Alert onClose={handleSnackBarOpen} severity={severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            <div style={modalStyle} className={classes.paper}>
                <h2 className={classes.title}>Измена локације</h2>
                {initValues && responsible ? (
                    <FormikStepper
                        validateOnChange={false}
                        initialValues={{
                            ...initValues,
                        }}
                        validationSchema={FORM_VALIDATION}
                        onSubmit={(values) => editLocation(values)}
                        setModal={setModal}
                    >
                        <Step1
                            groups={groups}
                            priorities={priorities}
                            contracts={contracts}
                            disabledId="true"
                            responsible={responsible}
                            setResponsible={setResponsibleHandler}
                        />
                        <Step8 />
                        <Step2 />
                        <Step3 networks={networks} />
                        <Step4 />
                        <Step5 />
                        <Step6 />
                        <Step7 />
                    </FormikStepper>
                ) : (
                    <SimpleModal />
                )}
            </div>
        </>
    )

    return (
        <div>
            <Modal
                open={modalOpen}
                onClose={() => setModal(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {id ? body : <SimpleModal />}
            </Modal>
        </div>
    )
}

EditLocation.propTypes = {
    id: PropTypes.string,
    modalOpen: PropTypes.bool,
    setModal: PropTypes.func,
    setRefresh: PropTypes.func,
    setSeverityHandler: PropTypes.func,
    setSnackbarHandler: PropTypes.func,
}

EditLocation.defaultProps = {
    id: '',
    modalOpen: false,
    setModal: () => {},
    setRefresh: () => {},
    setSeverityHandler: () => {},
    setSnackbarHandler: () => {},
}

/* eslint-disable */
export function FormikStepper({ children, ...props }) {
    const childrenArray = React.Children.toArray(children)
    const [step, setStep] = useState(0)
    const currentChild = childrenArray[step]
    // const { errors } = useFormikContext()

    return (
        <Formik {...props}>
            {({ values, setErrors, touched, setTouched, isSubmitting }) => (
                <Form autoComplete="off">
                    {currentChild}
                    {step > 0 && (
                        <Button
                            style={{ margin: 10 }}
                            startIcon={<ArrowBackIcon />}
                            color="primary"
                            variant="contained"
                            onClick={() => setStep(step - 1)}
                        >
                            Назад
                        </Button>
                    )}
                    {step < childrenArray.length - 1 && (
                        <Button
                            style={{ margin: 10 }}
                            endIcon={<ArrowForwardIcon />}
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                if (
                                    values.naziv.length === 0 ||
                                    values.ustanova_ID.length === 0 ||
                                    values.nazivSkole.length === 0 ||
                                    values.old_ID.length === 0
                                ) {
                                    setTouched({
                                        naziv: true,
                                        ustanova_ID: true,
                                        nazivSkole: true,
                                        old_ID: true,
                                    })
                                } else {
                                    setStep(step + 1)
                                }
                            }}
                        >
                            Даље
                        </Button>
                    )}
                    {/* {step === childrenArray.length - 1 && ( */}
                    <ButtonWrapper
                        type="submit"
                        fullWidth
                        variant="contained"
                        disabled={isSubmitting}
                        startIcon={
                            isSubmitting ? (
                                <CircularProgress size="0.9rem" />
                            ) : undefined
                        }
                        style={{
                            margin: '10px',
                        }}
                    >
                        Измени локацију
                    </ButtonWrapper>
                    {/* )} */}

                    <Button
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        onClick={() => props.setModal(false)}
                        style={{
                            margin: '10px',
                        }}
                    >
                        Одустани
                    </Button>
                </Form>
            )}
        </Formik>
    )
}
