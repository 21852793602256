/* eslint-disable */
import axios from 'axios'

export const axiosInstance = axios.create({
    // baseURL: 'http://localhost:5001/',
    baseURL: 'https://ikt.mit.gov.rs/',
    // baseURL: 'http://68.183.209.20:5000/',
})

export const axiosAuth = axios.create({
    // baseURL: 'http://localhost:5001/',
    baseURL: 'https://ikt.mit.gov.rs/',
    // baseURL: 'http://68.183.209.20:5000/',
})

axiosAuth.interceptors.request.use((config) => {
    const token = localStorage.getItem('token')
    config.headers.Authorization = token ? `Bearer ${token}` : ''
    return config
})
