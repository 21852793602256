import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
// import uuid from 'react-uuid'
import ActivityRow from './ActivityRow'
import ContractFiles from './ContractFiles'
import latToCyr from '../../util/latToCyr'
import { axiosAuth as axios } from '../../util/axios-instance'

const useStyles = makeStyles((theme) => ({
    title: {
        background: '#417AB4',
        color: '#FFF',
        padding: '0.8rem 1rem',
    },
    nested: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    BoardWrap: {
        border: '1px solid #E0E0E0',
        marginTop: '0.5rem',
    },
    titlebody: {
        marginRight: 20,
    },
    contentBody: {
        textAlign: 'right',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    table: {
        border: '1px solid #dddddd',
        borderCollapse: 'collapse',
        margin: 20,
    },
    tr: {
        border: '1px solid #dddddd',
    },
    td: {
        border: '1px solid #dddddd',
        textAlign: 'left',
        padding: 8,
        width: 300,
    },
    th: {
        border: '1px solid #dddddd',
        textAlign: 'left',
        padding: 8,
        backgroundColor: '#417AB4',
        color: 'white',
        width: 300,
    },
    editbutton: {
        backgroundColor: '#d9534f',
        color: 'white',
        '&:hover': {
            backgroundColor: '#d9534f',
        },
        padding: 8,
        margin: 8,
        boxShadow: 'none',
    },
    tables: {
        display: 'flex',
        flexDirection: 'column',
    },
    contractTitle: {
        textAlign: 'left',
        margin: '20px',
        fontSize: 25,
        color: '#417AB4',
        display: 'flex',
        alignItems: 'flex-end',
    },
    icon: {
        fontSize: 40,
        color: '#417AB4',
    },
    htmlTable: {
        border: '1px solid black',
        borderCollapse: 'collapse',
        marginBottom: 20,
    },
    btn: {
        margin: 5,
    },
}))

const LocationInfo = ({ location }) => {
    const classes = useStyles()
    const [files, setFiles] = useState([])
    const [filesState, setFilesState] = useState(false)

    const getFiles = async () => {
        try {
            const arr = []
            const response = await axios.get(
                `/api/files/get-files-per-activity/${location._id}`
            )

            location.aktivnosti.forEach((aktivnost) => {
                response.data.data.forEach((fajl) => {
                    if (aktivnost.kratakNaziv === fajl.aktivnost)
                        arr.push(aktivnost)
                })
            })
            setFiles(arr)
            setFilesState(true)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getFiles()
    }, [])

    return (
        <Grid container>
            <Grid item xs={12}>
                <div className={classes.tables}>
                    <Grid container justifyContent="flex-start">
                        <Box py={1}>
                            <Typography variant="body1">
                                ИНФОРМАЦИЈЕ О ЛОКАЦИЈИ
                            </Typography>
                        </Box>
                    </Grid>
                    <table className={classes.htmlTable}>
                        <tbody>
                            <tr>
                                <th className={classes.th}>ИД локације</th>
                                <th className={classes.th}>Назив локације</th>
                                <th className={classes.th}>ИД школе</th>
                                <th className={classes.th}>Назив школе</th>
                                <th className={classes.th}>Адреса школе</th>
                            </tr>
                            <tr>
                                <td className={classes.td}>
                                    {/* {location?.lokacija_ID
                                        ? latToCyr(
                                              location.lokacija_ID.toUpperCase()
                                          )
                                        : 'Нема информација'} */}
                                    {location?.old_ID && location.old_ID}
                                </td>
                                <td className={classes.td}>
                                    {location?.naziv
                                        ? location.naziv
                                        : 'Нема информација'}
                                </td>
                                <td className={classes.td}>
                                    {location?.ustanova_ID
                                        ? parseInt(
                                              location.ustanova_ID.replace(
                                                  'u',
                                                  ''
                                              ),
                                              10
                                          )
                                        : 'Нема информација'}
                                    {/* Нема информација */}
                                </td>
                                <td className={classes.td}>
                                    {location?.nazivSkole
                                        ? location.nazivSkole
                                        : 'Нема информација'}
                                </td>
                                <td className={classes.td}>
                                    {location?.adresa &&
                                    location.adresa !== 'NULL' &&
                                    location.adresa !== 'null'
                                        ? location.adresa
                                        : 'Нема информација'}
                                </td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <th className={classes.th}>Директор школе</th>
                                <th className={classes.th}>Контакт телефон</th>
                                <th className={classes.th}>Мејл адреса</th>
                                <th className={classes.th}> </th>
                                <th className={classes.th}> </th>
                            </tr>
                            <tr>
                                <td className={classes.td}>
                                    {location?.direktor &&
                                    location.direktor !== 'NULL' &&
                                    location.direktor !== 'null'
                                        ? location.direktor
                                        : 'Нема информација'}
                                </td>
                                <td className={classes.td}>
                                    {location?.direktorTelefon &&
                                    location.direktorTelefon !== 'NULL' &&
                                    location.direktorTelefon !== 'null'
                                        ? location.direktorTelefon
                                        : 'Нема информација'}
                                </td>
                                <td className={classes.td}>
                                    {location?.direktorEmail &&
                                    location.direktorEmail !== 'NULL' &&
                                    location.direktorEmail !== 'null'
                                        ? location.direktorEmail
                                        : 'Нема информација'}
                                </td>
                                <td className={classes.td}> </td>
                                <td className={classes.td}> </td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <th className={classes.th}>
                                    Административни контакт
                                </th>
                                <th className={classes.th}>Контакт телефон</th>
                                <th className={classes.th}>Мејл адреса</th>
                                <th className={classes.th}> </th>
                                <th className={classes.th}> </th>
                            </tr>
                            <tr>
                                <td className={classes.td}>
                                    {location?.admKontakt &&
                                    location.admKontakt !== 'NULL' &&
                                    location.admKontakt !== 'null'
                                        ? location.admKontakt
                                        : 'Нема информација'}
                                </td>
                                <td className={classes.td}>
                                    {location?.admKontaktTelefon &&
                                    location.admKontaktTelefon !== 'NULL' &&
                                    location.admKontaktTelefon !== 'null'
                                        ? location.admKontaktTelefon
                                        : 'Нема информација'}
                                </td>
                                <td className={classes.td}>
                                    {location?.admKontaktEmail &&
                                    location.admKontaktEmail !== 'NULL' &&
                                    location.admKontaktEmail !== 'null'
                                        ? location.admKontaktEmail
                                        : 'Нема информација'}
                                </td>
                                <td className={classes.td}> </td>
                                <td className={classes.td}> </td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <th className={classes.th}>Техничко лице</th>
                                <th className={classes.th}>Контакт телефон</th>
                                <th className={classes.th}>Мејл адреса</th>
                                <th className={classes.th}> </th>
                                <th className={classes.th}> </th>
                            </tr>
                            <tr>
                                <td className={classes.td}>
                                    {location?.tehLice &&
                                    location.tehLice !== 'NULL' &&
                                    location.tehLice !== 'null'
                                        ? location.tehLice
                                        : 'Нема информација'}
                                </td>
                                <td className={classes.td}>
                                    {location?.tehLiceTelefon &&
                                    location.tehLiceTelefon !== 'NULL' &&
                                    location.tehLiceTelefon !== 'null'
                                        ? location.tehLiceTelefon
                                        : 'Нема информација'}
                                </td>
                                <td className={classes.td}>
                                    {location?.tehLiceEmail &&
                                    location.tehLiceEmail !== 'NULL' &&
                                    location.tehLiceEmail !== 'null'
                                        ? location.tehLiceEmail
                                        : 'Нема информација'}
                                </td>
                                <td className={classes.td}> </td>
                                <td className={classes.td}> </td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <th className={classes.th}>Број зграда</th>
                                <th className={classes.th}>
                                    Укупан број просторија
                                </th>
                                <th className={classes.th}>Број учионица</th>
                                <th className={classes.th}>
                                    Број лабораторија
                                </th>
                                <th className={classes.th}>
                                    Број информатичких кабинета
                                </th>
                            </tr>
                            <tr>
                                <td className={classes.td}>
                                    {location?.brojZgrada &&
                                    location.brojZgrada !== 'NULL' &&
                                    location.brojZgrada !== 'null'
                                        ? location.brojZgrada
                                        : 'Нема информација'}
                                </td>
                                <td className={classes.td}>
                                    {location?.ukupnoProstorija &&
                                    location.ukupnoProstorija !== 'NULL' &&
                                    location.ukupnoProstorija !== 'null'
                                        ? location.ukupnoProstorija
                                        : 'Нема информација'}
                                </td>
                                <td className={classes.td}>
                                    {location?.ukupnoUcionica &&
                                    location.ukupnoUcionica !== 'NULL' &&
                                    location.ukupnoUcionica !== 'null'
                                        ? location.ukupnoUcionica
                                        : 'Нема информација'}
                                </td>
                                <td className={classes.td}>
                                    {location?.brojLaboratorija &&
                                    location.brojLaboratorija !== 'NULL' &&
                                    location.brojLaboratorija !== 'null'
                                        ? location.brojLaboratorija
                                        : 'Нема информација'}
                                </td>
                                <td className={classes.td}>
                                    {location?.brojKabineta &&
                                    location.brojKabineta !== 'NULL' &&
                                    location.brojKabineta !== 'null'
                                        ? location.brojKabineta
                                        : 'Нема информација'}
                                </td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <th className={classes.th}>Број библиотека</th>
                                <th className={classes.th}>
                                    Број осталих просторија (намена)
                                </th>
                                <th className={classes.th}>Тип амрес мреже</th>
                                <th className={classes.th}>Општина</th>
                                <th className={classes.th}>Насеље</th>
                            </tr>
                            <tr>
                                <td className={classes.td}>
                                    {location?.brojBiblioteka &&
                                    location.brojBiblioteka !== 'NULL' &&
                                    location.brojBiblioteka !== 'null'
                                        ? location.brojBiblioteka
                                        : 'Нема информација'}
                                </td>
                                <td className={classes.td}>
                                    {location?.ostaleProstorije &&
                                    location.ostaleProstorije !== 'NULL' &&
                                    location.ostaleProstorije !== 'null'
                                        ? location.ostaleProstorije
                                        : 'Нема информација'}
                                </td>
                                <td className={classes.td}>
                                    {location?.amres &&
                                    location.amres !== 'NULL' &&
                                    location.amres !== 'null'
                                        ? location.amres
                                        : 'Нема информација'}
                                </td>
                                <td className={classes.td}>
                                    {location?.opstina &&
                                    location.opstina !== 'NULL' &&
                                    location.opstina !== 'null'
                                        ? location.opstina
                                        : 'Нема информација'}
                                </td>
                                <td className={classes.td}>
                                    {location?.naselje &&
                                    location.naselje !== 'NULL' &&
                                    location.naselje !== 'null'
                                        ? location.naselje
                                        : 'Нема информација'}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <Grid container justifyContent="flex-start">
                        <Box py={1}>
                            <Typography variant="body1">
                                ИНФОРМАЦИЈЕ О УГОВОРУ
                            </Typography>
                        </Box>
                    </Grid>
                    <table className={classes.htmlTable}>
                        <tbody>
                            <tr>
                                <th className={classes.th}>Назив уговора</th>
                                <th className={classes.th}>Број уговора</th>
                                <th className={classes.th}>Статус</th>
                                <th className={classes.th}>Документи</th>
                            </tr>
                            <tr>
                                <td className={classes.td}>
                                    {location?.ugovor?.naziv
                                        ? location.ugovor.naziv
                                        : 'Није повезан уговор'}
                                </td>
                                <td className={classes.td}>
                                    {location?.ugovor?.broj
                                        ? location.ugovor.broj
                                        : 'Није повезан уговор'}
                                </td>
                                <td className={classes.td}>
                                    {location?.ugovor?.status
                                        ? latToCyr(location.ugovor.status)
                                        : 'Није повезан уговор'}
                                </td>
                                <td className={classes.td}>
                                    <ContractFiles location={location} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <Grid container justifyContent="flex-start">
                        <Box py={1}>
                            <Typography variant="body1">
                                ИНФОРМАЦИЈЕ О АКТИВНОСТИМА
                            </Typography>
                        </Box>
                    </Grid>
                    {filesState && (
                        <table className={classes.htmlTable}>
                            <tbody>
                                <tr>
                                    <th className={classes.th}>Назив</th>
                                    <th className={classes.th}>Документи</th>
                                    <th className={classes.th}>Подаци</th>
                                </tr>
                                {location.aktivnosti
                                    .filter(
                                        (aktivnost) =>
                                            ((aktivnost?.podaci?.length > 0 &&
                                                aktivnost.podaci[0].vrednost !==
                                                    '') ||
                                                files.includes(aktivnost)) &&
                                            (aktivnost.status === 'U toku' ||
                                                aktivnost.status === 'Završena')
                                    )
                                    .map((el) => (
                                        <ActivityRow
                                            key={el._id}
                                            activity={el}
                                            ustId={`${location.ustanova}`}
                                            files={files}
                                            lokId={`${location.old_ID}`}
                                        />
                                    ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </Grid>
        </Grid>
    )
}

LocationInfo.propTypes = {
    location: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default LocationInfo
