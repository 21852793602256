import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import PropTypes from 'prop-types'

const PriorityTable = ({
    priorities,
    onRowClick,
    actionBodyTemplate,
    style,
}) => (
    <div className={style}>
        <DataTable
            value={priorities}
            onRowClick={onRowClick}
            showGridlines
            className="blackTable"
            emptyMessage="Нема пронађених података."
        >
            <Column field="naziv" header="Назив" />
            <Column field="broj" header="Број" />
            <Column
                field={actionBodyTemplate}
                header="Управљање"
                style={{
                    width: '500px',
                }}
            />
        </DataTable>
    </div>
)

export default PriorityTable

PriorityTable.propTypes = {
    priorities: PropTypes.arrayOf(PropTypes.any).isRequired,
    actionBodyTemplate: PropTypes.func.isRequired,
    onRowClick: PropTypes.func.isRequired,
    style: PropTypes.string.isRequired,
}
