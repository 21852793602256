import React, { useEffect, useState } from 'react'
// import { useDebounce } from 'use-debounce'
import { useHistory, useParams } from 'react-router-dom'
import { Grid, Container } from '@material-ui/core'
import moment from 'moment'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import FolderIcon from '@material-ui/icons/Folder'
import Tooltip from '@material-ui/core/Tooltip'

import { axiosAuth as axios } from '../../../util/axios-instance'
import AdminTable from '../../../components/tables/AdminTable'
// import LocationInfo from '../../../components/Cards/LocationInfo'
import LocationInfoFix from '../../../components/Cards/LocationInfoFix'
import LocationInfoBtn from '../../../components/FormsUI/Button/LocationInfoBtn'
import BackToButton from '../../../components/FormsUI/Button/BackToButton'
import SimpleModal from '../../../components/Modal/Modal'
import latToCyr from '../../../util/latToCyr'
import CommentsModal from '../../Locations/SingleLocation/SingleLocationModals/CommentsModal'
import FileModal from '../../Locations/SingleLocation/SingleLocationModals/FileModal'
import DataModal from '../../Locations/SingleLocation/SingleLocationModals/DataModal'

const SingleLocationStaff = () => {
    const { id } = useParams()
    const history = useHistory()
    const [location, setLocation] = useState([])
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(50)
    const [load, setLoad] = useState(false)
    const [showLocationInfo, setShowLocationInfo] = useState(false)
    const [act, setAct] = useState([])

    const [search, setSearch] = useState({ eng: '', key: '', srb: '' })
    // const [searchDebounced] = useDebounce(search, 1000)

    const [commentModal, setCommentsModal] = useState(false)
    const [comments, setComments] = useState()
    const [documentsModal, setDocumentsModal] = useState(false)
    const [documents, setDocuments] = useState([])
    const [dataModal, setDataModal] = useState(false)
    const [data, setData] = useState([])
    const [aktId, setAktId] = useState('')
    const [files, setFiles] = useState([])

    const getFiles = async () => {
        try {
            const response = await axios.get(
                `/api/files/get-files-per-activity/${id}`
            )
            setFiles(response.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    const getLocation = async () => {
        try {
            setLoad(true)
            const response = await axios.get(
                `/api/location/${id}?page=${page + 1}&limit=${rowsPerPage}`
            )
            setLocation(response.data.location)

            const svevidece = await axios.get('/api/group/sve-videci/true')
            const svevideceArr = []
            svevidece.data.groups.map((el) => svevideceArr.push(el))

            const tableData =
                response.data &&
                response.data.location &&
                response.data?.location?.aktivnosti?.reduce(
                    (acc, currentEl) => {
                        const groupArray = currentEl.odgovoran
                            .map((el) => el)
                            .join(', ')

                        // const odgNazivi = currentEl.lokacija.odgovoran
                        //     .filter((el) => groupArray.includes(el.cirilica))
                        //     .map((el) => el.naziv)
                        //     .join(', ')

                        const odgNazivi = [
                            ...(response.data.location?.odgovoran?.length > 0
                                ? response.data.location.odgovoran
                                : []),
                            ...svevideceArr,
                        ]
                            .filter((el) => groupArray === el.tip)
                            .map(
                                (el) =>
                                    `${el.naziv} ${
                                        el.nadredjenaGrupa
                                            ? // prettier-ignore
                                              `(${el.nadredjenaGrupa.naziv})`
                                            : ''
                                    }`
                            )
                            .join(', ')

                        const addElement = {
                            id: currentEl._id,
                            naziv: `${currentEl?.kratakNaziv} - ${currentEl.naziv}`,
                            status: latToCyr(currentEl.status),
                            statusLat: currentEl.status,
                            tip: latToCyr(groupArray),
                            nazivOdgGrupe: odgNazivi,
                            rokBrDana: currentEl.rok,
                            pocetak: currentEl.datumPocetka
                                ? moment(currentEl.datumPocetka).format(
                                      'DD/MM/YYYY'
                                  )
                                : '',
                            pocetakMD: currentEl.datumPocetka
                                ? moment(currentEl.datumPocetka).format(
                                      'MM/DD/YYYY'
                                  )
                                : '',
                            zavrsetak: currentEl.datumZavrsetka
                                ? moment(currentEl.datumZavrsetka).format(
                                      'DD/MM/YYYY'
                                  )
                                : '',
                            zavrsetakMD: currentEl.datumZavrsetka
                                ? moment(currentEl.datumZavrsetka).format(
                                      'MM/DD/YYYY'
                                  )
                                : '',
                            rok: currentEl.rokZavrsetka,
                            rokZaZavrsetak: currentEl.rokZavrsetka
                                ? moment(currentEl.rokZavrsetka).format(
                                      'DD/MM/YYYY'
                                  )
                                : 'клизно',
                            rokZaZavrsetakMD: currentEl.rokZavrsetka
                                ? moment(currentEl.rokZavrsetka).format(
                                      'MM/DD/YYYY'
                                  )
                                : 'клизно',
                            poslednjiKomentar:
                                currentEl.komentari.length > 0 ? (
                                    <span
                                        data-name="comments"
                                        name="comments"
                                        style={{
                                            width: '100%',
                                            display: 'inline-block',
                                        }}
                                    >
                                        {
                                            currentEl.komentari[
                                                currentEl.komentari.length - 1
                                            ].komentar
                                        }
                                    </span>
                                ) : (
                                    ''
                                ),
                            podaci: currentEl.podaci,
                            fajlovi: currentEl.fajlovi,
                            sviKomentari: currentEl.komentari,
                            brojFajlova: currentEl.brojFajlova,
                            kratakNaziv: currentEl.kratakNaziv,
                        }
                        acc.push(addElement)
                        return acc
                    },
                    []
                )

            setAct(tableData)

            setLoad(false)
        } catch (error) {
            console.log(error)
            setLoad(true)
        }
    }

    const actionBodyTemplate = (rowData) => (
        <Grid key="edit" container spacing={2} justifyContent="center">
            <Grid item xs={4}>
                {files?.length > 0 &&
                    files
                        ?.map((f) => f.aktivnost)
                        .includes(rowData.kratakNaziv) && (
                        <Tooltip title="Документа">
                            {/* <Button endIcon={<FolderIcon />} name="dokumenta" /> */}
                            <FolderIcon data-name="dokumenta" />
                        </Tooltip>
                    )}
            </Grid>
            <Grid item xs={4}>
                {rowData?.podaci?.length > 0 &&
                    rowData.podaci[0].vrednost !== '' && (
                        <Tooltip title="Подаци">
                            {/* <Button endIcon={<InsertDriveFileIcon />} name="podaci" /> */}
                            <InsertDriveFileIcon data-name="podaci" />
                        </Tooltip>
                    )}
            </Grid>
        </Grid>
    )

    const handleOpenCommentsModal = () => {
        setCommentsModal(true)
    }

    useEffect(() => {
        getLocation()
        // getSingleLoc()
        getFiles()
    }, [id])

    const showLocationHandler = () => {
        setShowLocationInfo(!showLocationInfo)
    }

    const handleCloseModal = () => {
        setCommentsModal(false)
    }

    const rowClass = (rowData) => {
        const danas = new Date()
        // const pocetakD = new Date(rowData.pocetakMD)
        const zavrseno = new Date(rowData.zavrsetakMD)
        const planiraniRokZavrsetka = new Date(rowData.rokZaZavrsetakMD)
        const brojDanaDoRoka = moment
            .duration(moment(planiraniRokZavrsetka).diff(moment(danas)))
            .asDays()
        const rokBrojDana = rowData.rokBrDana
        const tenPercentToDeadLine = brojDanaDoRoka / rokBrojDana

        if (
            rowData.statusLat === 'Završena' &&
            planiraniRokZavrsetka > zavrseno
        ) {
            return { greenRow: true }
        }
        if (
            rowData.statusLat === 'Završena' &&
            planiraniRokZavrsetka < zavrseno
        ) {
            return { greenRedRow: true }
        }
        if (
            brojDanaDoRoka > 0 &&
            tenPercentToDeadLine > 0.1 &&
            rowData.statusLat === 'U toku'
        ) {
            return { blueRow: true }
        }
        if (
            brojDanaDoRoka > 0 &&
            tenPercentToDeadLine < 0.1 &&
            rowData.statusLat === 'U toku'
        ) {
            return { orangeRow: true }
        }
        if (brojDanaDoRoka < 0 && rowData.statusLat === 'U toku') {
            return { redRow: true }
        }
        if (
            rowData.rokZaZavrsetak === 'клизно' &&
            rowData.statusLat === 'U toku'
        ) {
            return { blueRow: true }
        }
        if (
            rowData.statusLat === 'Završena' &&
            rowData.rokZaZavrsetak === 'клизно'
        ) {
            return { greenRow: true }
        }

        return { white: true }
    }

    const onRowClick = (rowData) => {
        if (
            rowData.originalEvent.target.nodeName === 'TD' ||
            rowData.originalEvent.target.nodeName === 'DIV'
        ) {
            history.push(`/app/aktivnost/${rowData.data.id}`)
        }
        if (
            rowData.originalEvent.target.nodeName === 'path' &&
            rowData.originalEvent.target.parentNode.dataset.name === 'dokumenta'
        ) {
            if (
                files.length > 0 &&
                files.map((f) => f.aktivnost).includes(rowData.data.kratakNaziv)
            ) {
                files.forEach((file) => {
                    if (file.aktivnost === rowData.data.kratakNaziv) {
                        setDocumentsModal(true)
                        setDocuments(file)
                        setAktId(rowData.data.id)
                    }
                })
            }
        }
        if (
            rowData.originalEvent.target.nodeName === 'path' &&
            rowData.originalEvent.target.parentNode.dataset.name === 'podaci'
        ) {
            if (
                rowData.data.podaci.length > 0 &&
                rowData.data.podaci[0].vrednost !== ''
            ) {
                setData(rowData.data.podaci)
                setDataModal(true)
            }
        }
        if (rowData.originalEvent.target.dataset.name === 'comments') {
            setComments(rowData.data)
            handleOpenCommentsModal()
        }
    }

    return (
        <>
            <Container>
                <BackToButton label="НАЗАД НА ЛИСТУ СВИХ ЛОКАЦИЈА" />
                <Grid container alignContent="flex-start">
                    <LocationInfoBtn
                        label="Лична карта локације"
                        show={showLocationInfo}
                        click={showLocationHandler}
                    />
                </Grid>
                {load ? <SimpleModal /> : null}
                {showLocationInfo ? (
                    <LocationInfoFix location={location} />
                ) : null}
                {commentModal ? (
                    <CommentsModal
                        open={commentModal}
                        close={handleCloseModal}
                        podaci={comments}
                    />
                ) : null}
                {dataModal ? (
                    <DataModal
                        open={dataModal}
                        close={() => setDataModal(false)}
                        podaci={data}
                    />
                ) : null}
                {documentsModal ? (
                    <FileModal
                        open={documentsModal}
                        close={() => setDocumentsModal(false)}
                        files={documents}
                        id={aktId}
                        locationId={id}
                        title="Доступни документи"
                        type="activity"
                    />
                ) : null}
                <Grid container style={{ marginBottom: '20px' }}>
                    {location &&
                        location.aktivnosti &&
                        location.aktivnosti.length !== 0 && (
                            <AdminTable
                                model="location"
                                title={`Локација ${location.old_ID} - ${
                                    location && location.naziv
                                        ? location.naziv
                                        : ' '
                                }`}
                                data={act}
                                totalResults={parseInt(
                                    location.aktivnosti.length,
                                    10
                                )}
                                page={page}
                                setPage={setPage}
                                rowClass={rowClass}
                                rowsPerPage={rowsPerPage}
                                setRowsPerPage={setRowsPerPage}
                                name="iktActivitiesTable22"
                                onRowClick={onRowClick}
                                tableHeaders={[
                                    { field: 'naziv', header: 'Назив' },
                                    { field: 'status', header: 'Статус' },
                                    { field: 'pocetak', header: 'Почетак' },
                                    {
                                        field: 'zavrsetak',
                                        header: 'Завршетак',
                                    },
                                    {
                                        field: 'rokZaZavrsetak',
                                        header: 'Рок за завршетак',
                                    },
                                    {
                                        field: 'tip',
                                        header: 'Тип групе',
                                    },
                                    {
                                        field: 'nazivOdgGrupe',
                                        header: 'Назив одговорне групе',
                                    },
                                    {
                                        field: 'poslednjiKomentar',
                                        header: 'Последњи коментар',
                                    },
                                    {
                                        field: 'edit',
                                        header: '',
                                    },
                                ]}
                                searchColumns={[
                                    { dataLabel: 'naziv', label: 'Назив' },
                                    {
                                        dataLabel: 'status',
                                        label: 'Статус',
                                    },
                                    {
                                        field: 'tip',
                                        header: 'Тип групе',
                                    },
                                    {
                                        field: 'nazivOdgGrupe',
                                        header: 'Назив одговорне групе',
                                    },
                                ]}
                                setSearch={setSearch}
                                search={search}
                                withPagination={false}
                                withExport={false}
                                withSearch={false}
                                editColumnwidth={150}
                                actionBodyTemplate={actionBodyTemplate}
                            />
                        )}
                </Grid>
            </Container>
        </>
    )
}

export default SingleLocationStaff
