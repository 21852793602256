import React from 'react'
import { useFormikContext } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import TextfieldWrapper from '../../../components/FormsUI/Textfield/TextfieldWrapper'

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 800,
        height: 'auto',
        maxHeight: '90%',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        overflow: 'auto',
    },
    title: {
        textAlign: 'center',
    },
    formField: {
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
    },
    dateDiv: {
        width: '100%',
        padding: 10,
    },
}))

const Step6 = () => {
    const { values } = useFormikContext()
    const classes = useStyles()

    return (
        <div>
            <div className={classes.formField}>
                <span className={classes.tableRowLabel}>
                    Административни контакт име и презиме
                </span>
                <TextfieldWrapper name="admKontakt" value={values.admKontakt} />
            </div>
            <div className={classes.formField}>
                <span className={classes.tableRowLabel}>
                    Административни контакт број телефона
                </span>
                <TextfieldWrapper
                    name="admKontaktTelefon"
                    value={values.admKontaktTelefon}
                />
            </div>
            <div className={classes.formField}>
                <span className={classes.tableRowLabel}>
                    Административни контакт мејл адреса
                </span>
                <TextfieldWrapper
                    name="admKontaktEmail"
                    value={values.admKontaktEmail}
                />
            </div>
            <div className={classes.formField}>
                <span className={classes.tableRowLabel}>
                    Техничко лице име и презиме
                </span>
                <TextfieldWrapper name="tehLice" value={values.tehLice} />
            </div>
            <div className={classes.formField}>
                <span className={classes.tableRowLabel}>
                    Техничко лице број телефона
                </span>
                <TextfieldWrapper
                    name="tehLiceTelefon"
                    value={values.tehLiceTelefon}
                />
            </div>
            <div className={classes.formField}>
                <span className={classes.tableRowLabel}>
                    Техничко лице мејл адреса
                </span>
                <TextfieldWrapper
                    name="tehLiceEmail"
                    value={values.tehLiceEmail}
                />
            </div>
        </div>
    )
}

export default Step6
