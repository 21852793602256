import React from 'react'
import { Formik, Form } from 'formik'

import * as Yup from 'yup'
import { Button, Grid, makeStyles, CircularProgress } from '@material-ui/core'

import PropTypes from 'prop-types'

// import { useParams } from 'react-router-dom'
import useSWR, { trigger } from 'swr'
import { axiosAuth } from '../../../util/axios-instance'

import EditSelects from '../../../components/FormsUI/Select/EditSelects'

const useStyles = makeStyles(() => ({
    addAll: {
        marginTop: 30,
    },
    formWidth: {
        width: 300,
    },
}))

const A2FirstStep = ({
    setSeverity,
    setSnackbar,
    setError,
    handleNext,
    activity,
}) => {
    const classes = useStyles()
    // const { id } = useParams()

    const INITIAL_FORM_STATE = {
        ...activity.lokacija,
        ugovor: '',
    }

    const FORM_VALIDATION = Yup.object().shape({
        ugovor: Yup.string().required('Изаберите једну од опција'),
    })
    const { data: allContracts } = useSWR('/api/contract')

    const submitAdditionalData = async (values) => {
        try {
            await axiosAuth.patch(
                `/api/location/${activity.lokacija._id}`,
                values
            )

            // console.log(data)
            setSeverity('success')
            setSnackbar({
                open: true,
                message: 'Успешно додат уговор',
            })
            trigger(`/api/activity/${activity._id}`)
            handleNext()
        } catch (error) {
            setSeverity('warning')
            setSnackbar({
                open: true,
                message: 'Неуспешно додат уговор',
            })
            setError(error)
        }
    }
    return (
        <Grid container justifyContent="center">
            {/* <Paper className={classes.add} elevation={0}> */}
            <Formik
                initialValues={INITIAL_FORM_STATE}
                validationSchema={FORM_VALIDATION}
                onSubmit={submitAdditionalData}
            >
                {({ isSubmitting }) => (
                    <Form autoComplete="off" className={classes.formWidth}>
                        <Grid container justifyContent="flex-start">
                            <Grid item xs={12}>
                                <EditSelects
                                    name="ugovor"
                                    options={
                                        allContracts?.contracts
                                            ? allContracts.contracts
                                            : []
                                    }
                                    label="Изаберите уговор"
                                    currentValue={
                                        activity?.lokacija?.ugovor &&
                                        allContracts?.contracts &&
                                        activity?.lokacija?.ugovor !== 'NULL'
                                            ? activity?.lokacija?.ugovor._id
                                            : ''
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    className={classes.addAll}
                                    disabled={isSubmitting}
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    startIcon={
                                        isSubmitting ? (
                                            <CircularProgress size="0.9rem" />
                                        ) : undefined
                                    }
                                >
                                    {isSubmitting ? 'Слање...' : 'Сачувај'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
            {/* </Paper> */}
        </Grid>
    )
}

export default A2FirstStep

A2FirstStep.propTypes = {
    setSeverity: PropTypes.func.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    setError: PropTypes.func.isRequired,
    handleNext: PropTypes.func.isRequired,
    activity: PropTypes.objectOf(PropTypes.any).isRequired,
}
