import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { Autocomplete } from '@material-ui/lab'
import { TextField } from '@material-ui/core'

import { useFormikContext } from 'formik'
import PropTypes from 'prop-types'
import TextfieldWrapper from '../../../components/FormsUI/Textfield/TextfieldWrapper'
import latToCyr from '../../../util/latToCyr'

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 800,
        height: 'auto',
        maxHeight: '90%',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        overflow: 'auto',
    },
    title: {
        textAlign: 'center',
    },
    formField: {
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
    },
    dateDiv: {
        width: '100%',
        padding: 10,
    },
}))

const Step1 = ({
    groups,
    priorities,
    contracts,
    disabledId,
    responsible,
    setResponsible,
}) => {
    const { values, setFieldValue } = useFormikContext()
    const classes = useStyles()

    return (
        <div>
            <div className={classes.formField}>
                <span className={classes.tableRowLabel}>Назив локације</span>
                <TextfieldWrapper
                    name="naziv"
                    // inputRef={(input) => input && input.focus()}
                    value={values.naziv}
                />
            </div>
            <div className={classes.formField}>
                <span className={classes.tableRowLabel}>ИД локације</span>
                <TextfieldWrapper
                    name="old_ID"
                    value={values.old_ID}
                    disabled={disabledId === 'true'}
                />
            </div>
            <div className={classes.formField}>
                <span className={classes.tableRowLabel}>Назив школе</span>
                <TextfieldWrapper name="nazivSkole" value={values.nazivSkole} />
            </div>
            <div className={classes.formField}>
                <span className={classes.tableRowLabel}>ИД школе</span>
                <TextfieldWrapper
                    name="ustanova_ID"
                    value={values.ustanova_ID}
                    disabled={disabledId === 'true'}
                />
            </div>
            <div className={classes.formField}>
                <span className={classes.tableRowLabel}>Уговор</span>
                <FormControl className={classes.formControl}>
                    <Select
                        name="ugovor"
                        value={values.ugovor}
                        native
                        onChange={(event) => {
                            setFieldValue('ugovor', event.target.value)
                        }}
                    >
                        <option aria-label="None" value="">
                            Изаберите уговор
                        </option>
                        {contracts
                            .filter((con) => con.status !== 'Završen')
                            .map((contract) => (
                                <option key={contract._id} value={contract._id}>
                                    {contract.naziv}
                                </option>
                            ))}
                    </Select>
                </FormControl>
            </div>

            <div className={classes.formField}>
                {/* <span className={classes.tableRowLabel}>Приоритет</span>
                <EditSelects
                    name="prioritet"
                    options={priorities}
                    label="Изаберите приоритет"
                    currentValue={values.prioritet}
                /> */}
                <span className={classes.tableRowLabel}>Приоритет</span>
                <FormControl className={classes.formControl}>
                    <Select
                        name="prioritet"
                        value={values.prioritet}
                        native
                        onChange={(event) => {
                            setFieldValue('prioritet', event.target.value)
                        }}
                    >
                        <option aria-label="None" value="">
                            Изаберите приоритет
                        </option>
                        {priorities.map((priority) => (
                            <option key={priority._id} value={priority._id}>
                                {priority.naziv}
                            </option>
                        ))}
                    </Select>
                </FormControl>
            </div>
            {/* <div className={classes.formField}>
                <span className={classes.tableRowLabel}>Одговорна група</span>
                <FormControl className={classes.formControl}>
                    <Select
                        name="odgovoran"
                        value={values.odgovoran}
                        native
                        onChange={(event) => {
                            setFieldValue('odgovoran', event.target.value)
                        }}
                    >
                        <option aria-label="None" value="">
                            Изаберите одговорну групу
                        </option>
                        {groups.map((group) => (
                            <option key={group._id} value={group._id}>
                                {group.naziv}
                            </option>
                        ))}
                    </Select>
                </FormControl>
            </div> */}
            <div className={classes.formField}>
                <span className={classes.tableRowLabel}>Одговорне групе</span>
                <Autocomplete
                    id="combo-box-demo"
                    value={responsible}
                    multiple
                    disableClearable
                    options={groups}
                    getOptionLabel={(option) =>
                        `${option.naziv}  (${latToCyr(option.tip)})`
                    }
                    getOptionSelected={(option, value) =>
                        option._id === value._id
                    }
                    onChange={(event, gr) => {
                        setResponsible(gr)
                        setFieldValue(
                            'odgovoran',
                            gr.map((g) => g._id)
                        )
                    }}
                    renderInput={(params) => (
                        <TextField {...params} variant="outlined" />
                    )}
                />
            </div>
        </div>
    )
}
Step1.propTypes = {
    groups: PropTypes.arrayOf(PropTypes.any),
    priorities: PropTypes.arrayOf(PropTypes.any),
    contracts: PropTypes.arrayOf(PropTypes.any),
    disabledId: PropTypes.string,
    responsible: PropTypes.arrayOf(PropTypes.object),
    setResponsible: PropTypes.func,
}

Step1.defaultProps = {
    groups: [],
    priorities: [],
    contracts: [],
    disabledId: '',
    responsible: [],
    setResponsible: () => {},
}

export default Step1
