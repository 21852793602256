// import Select from '@material-ui/core/Select'
import React, { useEffect } from 'react'
import { useField, useFormikContext } from 'formik'
import { MenuItem, TextField } from '@material-ui/core'
import PropTypes from 'prop-types'
import latToCyr from '../../../util/latToCyr'

const TypeSelectWrapper = ({ name, options, currentValue, ...otherProps }) => {
    const { setFieldValue } = useFormikContext()
    const [field, meta] = useField(name)

    const configSelect = {
        ...field,
        ...otherProps,
        select: true,
        // variant: 'outlined',
        fullWidth: true,
        // onChange: handleChange,
    }

    if (meta && meta.touched && meta.error) {
        configSelect.error = true
        configSelect.helperText = meta.error
    }
    useEffect(() => {
        if (currentValue !== undefined) setFieldValue(name, currentValue)
    }, [name, currentValue, setFieldValue])

    return (
        <TextField {...configSelect}>
            {options
                .filter((t) => t.naziv !== 'Admin' && t.naziv !== 'Админ')
                .map((t) => (
                    <MenuItem value={t.naziv} key={t._id}>
                        {latToCyr(t.naziv)}
                    </MenuItem>
                ))}
        </TextField>
    )
}

TypeSelectWrapper.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.instanceOf(Array).isRequired,
    currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}
TypeSelectWrapper.defaultProps = {
    currentValue: '',
}
export default TypeSelectWrapper
