import {
    // Button,
    // CircularProgress,
    Container,
    Grid,
    makeStyles,
    Snackbar,
    Tab,
    Tabs,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useDebounce } from 'use-debounce'
import React, { useEffect, useState } from 'react'
import pick from 'lodash/pick'
import { useHistory } from 'react-router-dom'
import useSWR from 'swr'
import { useSelector } from 'react-redux'
import AddUserModal from './userModals/AddUserModal'
import EditUserModal from './userModals/EditUserModal'
import TabPanel from '../../components/TabPanel/TabPanel'
// import { axiosAuth } from '../../util/axios-instance'
import AdminTable from '../../components/tables/AdminTable'
import EditBtn from '../../components/FormsUI/Button/EditBtn'
import DeleteBtn from '../../components/FormsUI/Button/DeleteBtn'
// import PageTitle from '../../components/FormsUI/PageTitle/PageTitle'
import SimpleModal from '../../components/Modal/Modal'
import DeleteUserModal from './userModals/DeleteUserModal'
import AddButton from '../../components/FormsUI/Button/AddButton'
import latToCyr from '../../util/latToCyr'

const useStyles = makeStyles({
    icon: {
        marginLeft: 5,
    },
    flex: {
        display: 'flex',
        justifyContent: 'flex-start',
    },

    createButton: {
        backgroundColor: '#ba000d',
        color: 'white',
        '&:hover': {
            backgroundColor: '#d9534f',
        },
        width: '180px',
        fontWeight: 700,
        marginBottom: '40px',
    },
    pageContainer: {
        margin: '10px 0px ',
    },
    relative: {
        position: 'relative',
    },
    loader: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        // transform: '-webkit-translate(-50%, -50%)',
        // transform: '-moz-translate(-50%, -50%)',
        // transform: '-ms-translate(-50%, -50%)',
    },
})

const Users = () => {
    const [users, setUsers] = useState([])
    const [guestsAll, setGuestsAll] = useState([])
    // const [confirmedUsers, setConfirmedUsers] = useState('')
    // const [guests, setGuests] = useState({ guests: [] })
    const [selectedUser, setSelectedUser] = useState({})
    const [page, setPage] = useState(0)
    // eslint-disable-next-line
    // const [searchPage, setSearchPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [guestsPage, setGuestsPage] = useState(0)
    const [guestsRowsPerPage, setGuestsRowsPerPage] = useState(10)
    const [openAddUserModal, setAddUserModal] = useState(false)
    const [openEditUserModal, setEditUserModal] = useState(false)
    const [openDeleteUserModal, setDeleteUserModal] = useState(false)
    const [load, setLoad] = useState(false)
    const [value, setValue] = useState(0)
    const [error, setError] = useState('')
    const [managerGroupsOptions, setManagerGroupsOptions] = useState('')

    const history = useHistory()

    const [snackbar, setSnackbar] = useState({
        open: false,
        message: 'default',
    })
    const [severity, setSeverity] = useState('info')
    const handleSnackBarOpen = () => {
        setSnackbar((previous) => ({
            ...previous,
            open: false,
        }))
    }

    const [searchGuests, setSearchGuests] = useState({
        eng: '',
        srb: '',
    })
    const [searchUsers, setSearchUsers] = useState({
        eng: '',
        srb: '',
    })
    const [searchDebouncedUsers] = useDebounce(searchUsers, 2000)

    const classes = useStyles()
    const myProfile = useSelector((state) => state.myrole)

    const handleChange = (event, newValue) => {
        setValue(newValue)
        setPage(0)
    }

    const reduceData = (dataArr) =>
        dataArr.reduce((acc, currentEl) => {
            const addElement = {
                _id: currentEl._id,
                ime: currentEl.ime,
                prezime: currentEl.prezime,
                email: currentEl.email,
                emailPotvrdjen:
                    currentEl.emailPotvrdjen === false ? 'Не' : 'Да',
                emailPotvrdjenBool: currentEl.emailPotvrdjen,
                rola: latToCyr(currentEl.rola),
                status: latToCyr(currentEl.status),
                grupa: currentEl.grupa ? currentEl.grupa?.naziv : 'Нема групу',
                tip: currentEl.grupa
                    ? latToCyr(currentEl.grupa?.tip)
                    : 'Нема групу',
                nadredjenaGrupa:
                    currentEl.grupa && currentEl.grupa?.nadredjenaGrupa
                        ? currentEl.grupa?.nadredjenaGrupa?.naziv
                        : '/',
                grupaId: currentEl.grupa ? currentEl.grupa?._id : '',
                grupaObj: currentEl.grupa ? currentEl.grupa : '',
            }
            acc.push(addElement)
            return acc
        }, [])

    const withSearchUrl =
        searchDebouncedUsers.eng === ''
            ? `/api/user?page=${page + 1}&limit=${rowsPerPage}`
            : `/api/search/user?eng=${searchDebouncedUsers.eng}&srb=${
                  searchDebouncedUsers.srb
              }&page=${page + 1}&limit=${rowsPerPage}`
    // console.log('URL za get USERS', withSearchUrl)

    const {
        data: allUsers,
        error: userError,
        isValidating: userValidation,
    } = useSWR(withSearchUrl)
    // console.log('SVI USERI', allUsers)
    const {
        data: allGuests,
        error: guestError,
        isValidating: guestsValidation,
    } = useSWR(
        `/api/user/guests?page=${guestsPage + 1}&limit=${guestsRowsPerPage}`
    )

    const { data: groups, isValidating: groupsValidation } = useSWR(
        myProfile.user.rola === 'Admin' ? `/api/group?limit=50000` : null
    )
    // console.log('Grupe iz user modula', groups)
    const { data: myGroup, isValidating: myGroupValidation } = useSWR(
        myProfile.user?.grupa ? `/api/group/${myProfile.user.grupa._id}` : null
    )

    useEffect(() => {
        if (myProfile.user.rola === 'Rukovodilac' && myGroup?.group) {
            const currentGroup = pick(myGroup.group, [
                '_id',
                'kratakNaziv',
                'naziv',
                'tip',
                'cirilica',
            ])
            const managerGroups = [
                ...myGroup.group.podredjeneGrupe,
                currentGroup,
            ]
            setManagerGroupsOptions(managerGroups)
            // console.log('grupe managera', managerGroups)
        }
    }, [myGroup])

    useEffect(() => {
        // if ((!allUsers && !userError) || (!allGuests && !guestError))
        if (
            userValidation ||
            users ||
            guestsValidation ||
            myGroupValidation ||
            groupsValidation
        )
            setLoad(true)
        if (allUsers && allGuests) setLoad(false)
        if (guestError || userError) setError(true)
        if (allUsers) {
            const reduceUsers = reduceData(allUsers.users)
            setUsers(reduceUsers)
        }
        if (allGuests) {
            const reduceGuests = reduceData(allGuests.guests)
            setGuestsAll(reduceGuests)
        }
    }, [allUsers, allGuests])

    // console.log(
    //     'svi Useri:',
    //     allUsers?.users,
    //     'svi Gosti: ',
    //     allGuests?.guests,
    //     'sve Grupe: ',
    //     groups,
    //     'moja grupa: ',
    //     myGroup,
    //     'moj profil: ',
    //     myProfile,
    //     'groups Error',
    //     groupsError,
    //     'guest Error',
    //     guestError,
    //     'loader user',
    //     userValidation,
    //     'loader gosti',
    //     guestsValidation,
    //     'loader grupe',
    //     groupsValidation
    //     // 'reducedUsers',
    //     // reducedUsers,
    //     // 'reduced Guests',
    //     // reducedGuests
    // )

    const handleOpenEditUserModal = () => {
        setEditUserModal(true)
    }

    const handleOpenAddUserModal = () => {
        setAddUserModal(true)
    }
    const handleOpenDeleteUserModal = () => {
        setDeleteUserModal(true)
    }

    const handleClose = () => {
        setEditUserModal(false)
        setAddUserModal(false)
        setDeleteUserModal(false)
    }

    const onRowClick = (rowData) => {
        if (
            rowData.originalEvent.target.nodeName === 'TD' ||
            rowData.originalEvent.target.nodeName === 'DIV'
        ) {
            history.push(`/app/korisnici/${rowData.data._id}`, {
                from: 'KORISNICI',
            })
        }

        if (rowData.originalEvent.target.parentNode.name === 'izmeni') {
            setSelectedUser(rowData.data)
            handleOpenEditUserModal()
        }
        if (rowData.originalEvent.target.parentNode.name === 'obrisi') {
            setSelectedUser(rowData.data)
            handleOpenDeleteUserModal()
        }
    }
    const actionBodyTemplate = () => (
        <Grid key="edit" container>
            <Grid item xs={6} className={classes.buttonWrapper}>
                <EditBtn name="izmeni">измени</EditBtn>
            </Grid>
            <Grid item xs={6} className={classes.buttonWrapper}>
                <DeleteBtn name="obrisi">обриши</DeleteBtn>
            </Grid>
        </Grid>
    )

    const content = () => {
        if (load) return <SimpleModal />
        if (error) return <span>Грешка при преузимању података</span>
        return (
            <>
                {/* {confirmedUsers.users.length > 0 && (groups || oneGroup) && ( */}
                <Grid container className={classes.pageContainer}>
                    <Snackbar
                        open={snackbar.open}
                        onClose={handleSnackBarOpen}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        autoHideDuration={3500}
                        key="key"
                    >
                        <Alert onClose={handleSnackBarOpen} severity={severity}>
                            {snackbar.message}
                        </Alert>
                    </Snackbar>
                    {/* <Grid container justifyContent="center">
                        <PageTitle>Корисници</PageTitle>
                    </Grid> */}
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item>
                            <AddButton
                                label="додај корисника"
                                click={handleOpenAddUserModal}
                            />
                        </Grid>
                        <Grid item>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label="user tabs"
                            >
                                <Tab label="Сви корисници" disableRipple />
                                <Tab label="Гости" disableRipple />
                            </Tabs>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TabPanel value={value} index={0}>
                            {users && allUsers && users && (
                                <Grid item xs={12}>
                                    <AdminTable
                                        model="user"
                                        title="корисници"
                                        data={users}
                                        totalResults={allUsers.totalResults}
                                        page={page}
                                        setPage={setPage}
                                        rowsPerPage={rowsPerPage}
                                        setRowsPerPage={setRowsPerPage}
                                        name="iktUsersTable"
                                        onRowClick={onRowClick}
                                        tableHeaders={[
                                            { field: 'ime', header: 'Име' },
                                            {
                                                field: 'prezime',
                                                header: 'Презиме',
                                            },
                                            {
                                                field: 'email',
                                                header: 'Е-пошта',
                                            },
                                            {
                                                field: 'emailPotvrdjen',
                                                header: 'Потврђена е-пошта',
                                            },
                                            {
                                                field: 'rola',
                                                header: 'Рола',
                                            },
                                            {
                                                field: 'status',
                                                header: 'Статус',
                                            },
                                            {
                                                field: 'grupa',
                                                header: 'Група',
                                            },
                                            {
                                                field: 'tip',
                                                header: 'Тип',
                                            },
                                            {
                                                field: 'nadredjenaGrupa',
                                                header: 'Надређена група',
                                            },
                                            { field: 'edit', header: '' },
                                        ]}
                                        actionBodyTemplate={actionBodyTemplate}
                                        setSearch={setSearchUsers}
                                        search={searchUsers}
                                        withImport
                                        exportFileName="Корисници"
                                    />
                                </Grid>
                            )}
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            {value === 1 && allGuests && guestsAll ? (
                                <AdminTable
                                    model="user"
                                    data={guestsAll}
                                    load={load}
                                    title="Гости"
                                    totalResults={allGuests.totalResults}
                                    page={guestsPage}
                                    setPage={setGuestsPage}
                                    rowsPerPage={guestsRowsPerPage}
                                    setRowsPerPage={setGuestsRowsPerPage}
                                    name="iktGuestsTable"
                                    onRowClick={onRowClick}
                                    tableHeaders={[
                                        { field: 'ime', header: 'Име' },
                                        {
                                            field: 'prezime',
                                            header: 'Презиме',
                                        },
                                        {
                                            field: 'email',
                                            header: 'Е-пошта',
                                        },
                                        {
                                            field: 'emailPotvrdjen',
                                            header: 'Потврђена е-пошта',
                                        },
                                        {
                                            field: 'rola',
                                            header: 'Рола',
                                        },
                                        {
                                            field: 'status',
                                            header: 'Статус',
                                        },
                                        {
                                            field: 'grupa',
                                            header: 'Група',
                                        },
                                        {
                                            field: 'nadredjenaGrupa',
                                            header: 'Надређена група',
                                        },
                                        { field: 'edit', header: '' },
                                    ]}
                                    actionBodyTemplate={actionBodyTemplate}
                                    setSearch={setSearchGuests}
                                    search={searchGuests}
                                    withSearch={false}
                                    withExport={false}
                                />
                            ) : (
                                <></>
                            )}
                        </TabPanel>
                    </Grid>

                    <Grid item xs={12} className={classes.relative}>
                        {((groups && groups?.groups) ||
                            managerGroupsOptions) && (
                            <AddUserModal
                                open={openAddUserModal}
                                close={handleClose}
                                groups={
                                    myProfile.user.rola === 'Admin'
                                        ? groups.groups
                                        : managerGroupsOptions
                                }
                                page={page}
                                rowsPerPage={rowsPerPage}
                                guestsPage={guestsPage}
                                guestsRowsPerPage={guestsRowsPerPage}
                                setSeverity={setSeverity}
                                setSnackbar={setSnackbar}
                            />
                        )}
                        {(groups?.groups || managerGroupsOptions) &&
                            users.length > 0 &&
                            myProfile.user && (
                                <EditUserModal
                                    open={openEditUserModal}
                                    close={handleClose}
                                    user={selectedUser}
                                    setSeverity={setSeverity}
                                    setSnackbar={setSnackbar}
                                    groups={
                                        myProfile.user.rola === 'Admin'
                                            ? groups.groups
                                            : managerGroupsOptions
                                    }
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                    guestsPage={guestsPage}
                                    guestsRowsPerPage={guestsRowsPerPage}
                                    searchDebouncedUsers={searchDebouncedUsers}
                                />
                            )}
                        <DeleteUserModal
                            open={openDeleteUserModal}
                            close={handleClose}
                            user={selectedUser}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            guestsPage={guestsPage}
                            guestsRowsPerPage={guestsRowsPerPage}
                            setSeverity={setSeverity}
                            setSnackbar={setSnackbar}
                            searchDebouncedUsers={searchDebouncedUsers}
                        />
                    </Grid>
                </Grid>
            </>
        )
    }

    return <Container className={classes.container}>{content()}</Container>
}

export default Users
