import React from 'react'
import { useFormikContext } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
// import PropTypes from 'prop-types'
import TextfieldWrapper from '../../../components/FormsUI/Textfield/TextfieldWrapper'
// import EditSelects from '../../../components/FormsUI/Select/EditSelects'

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 800,
        height: 'auto',
        maxHeight: '90%',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        overflow: 'auto',
    },
    title: {
        textAlign: 'center',
    },
    formField: {
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
    },
    dateDiv: {
        width: '100%',
        padding: 10,
    },
}))

const Step5 = () => {
    const { values } = useFormikContext()
    const classes = useStyles()

    return (
        <div>
            <div className={classes.formField}>
                <span className={classes.tableRowLabel}>Школска управа</span>
                <TextfieldWrapper
                    name="skolskaUprava"
                    // inputRef={(input) => input && input.focus()}
                    value={values.skolskaUprava}
                />
            </div>
            <div className={classes.formField}>
                <span className={classes.tableRowLabel}>
                    Број телефона школе
                </span>
                <TextfieldWrapper
                    name="telefonSkole"
                    value={values.telefonSkole}
                />
            </div>
            <div className={classes.formField}>
                <span className={classes.tableRowLabel}>Мејл адреса школе</span>
                <TextfieldWrapper name="emailSkole" value={values.emailSkole} />
            </div>
            <div className={classes.formField}>
                <span className={classes.tableRowLabel}>
                    Име и презиме директора
                </span>
                <TextfieldWrapper name="direktor" value={values.direktor} />
            </div>
            <div className={classes.formField}>
                <span className={classes.tableRowLabel}>
                    Број телефона директора
                </span>
                <TextfieldWrapper
                    name="direktorTelefon"
                    value={values.direktorTelefon}
                />
            </div>
            <div className={classes.formField}>
                <span className={classes.tableRowLabel}>
                    Мејл адреса директора
                </span>
                <TextfieldWrapper
                    name="direktorEmail"
                    value={values.direktorEmail}
                />
            </div>
        </div>
    )
}

// Step5.propTypes = {
//     priorities: PropTypes.arrayOf(PropTypes.any),
//     groups: PropTypes.arrayOf(PropTypes.any),
// }

// Step5.defaultProps = {
//     priorities: [],
//     groups: [],
// }

export default Step5
