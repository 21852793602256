import React, { useEffect, useState } from 'react'
import { TextField } from '@material-ui/core'
import { useField, useFormikContext } from 'formik'
import { Autocomplete } from '@material-ui/lab'
import PropTypes from 'prop-types'

const AutoCompleteWrapper = ({
    name,
    label,
    data,
    currentValue,
    isDisabled,
    selectedRole,
    ...otherProps
}) => {
    const { setFieldValue } = useFormikContext()
    const [field, meta] = useField(name)
    const [defaultData, setDefaultData] = useState(
        currentValue ? data.find((el) => el._id === currentValue) : null
    )

    const handleChange = (evt, value) => {
        if (value) setFieldValue(name, value._id)

        setDefaultData(data.find((el) => el._id === value._id))
    }

    const configSelect = {
        ...field,
        ...otherProps,
        variant: 'outlined',
        fullWidth: true,
    }

    useEffect(() => {
        if (currentValue !== undefined || currentValue !== '')
            setFieldValue(name, currentValue)
    }, [name, currentValue, setFieldValue])

    if (meta && meta.touched && meta.error) {
        configSelect.error = true
        configSelect.helperText = meta.error
    }

    const filteredOptions = data.filter(
        (el) => el?.naziv !== 'Админ' && el?.naziv !== 'Admin'
    )

    return (
        <Autocomplete
            id="users"
            options={filteredOptions}
            value={
                selectedRole === 'Gost' || selectedRole === 'Admin'
                    ? ''
                    : defaultData
            }
            disabled={isDisabled}
            renderInput={(params) => (
                <TextField {...params} {...configSelect} label={label} />
            )}
            getOptionLabel={(option) => {
                if (selectedRole === 'Gost') return 'Нема групу'
                if (selectedRole === 'Admin') return 'Админ'
                if (
                    option.naziv === 'Админ' &&
                    (selectedRole === 'Zaposleni' ||
                        selectedRole === 'Rukovodilac')
                )
                    return 'Изаберите групу'
                return option.naziv && `${option.naziv} (${option.tip}) `
            }}
            onChange={handleChange}
            getOptionSelected={(option, value) => option._id === value._id}
        />
    )
}

export default AutoCompleteWrapper

AutoCompleteWrapper.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.any).isRequired,
    currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    isDisabled: PropTypes.bool,
    selectedRole: PropTypes.string,
}

AutoCompleteWrapper.defaultProps = {
    currentValue: '',
    isDisabled: false,
    selectedRole: '',
}
