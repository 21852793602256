import React, { useState, useEffect } from 'react'
import { useDebounce } from 'use-debounce'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Button, Grid, Box, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import { axiosAuth as axios } from '../../util/axios-instance'
import AdminTable from '../../components/tables/AdminTable'
import SimpleModal from '../../components/Modal/Modal'
import EditGroup from './EditGroup'
import DeleteGroup from './DeleteGroup'
import CreateGroup from './CreateGroup'
import AddButton from '../../components/FormsUI/Button/AddButton'
import latToCyr from '../../util/latToCyr'

const useStyles = makeStyles({
    root: {
        margin: '20px 20px 50px 20px',
        width: 'unset',
    },
    cellButtons: {
        maxWidth: 'min-content',
        cursor: 'default',
    },
    divButtons: {
        '& > *': {
            width: '33%',
        },
    },
    createButton: {
        margin: '1rem 0',
    },
    roleBtn: {
        '&:hover': {
            backgroundColor: 'unset',
            boxShadow: 'none',
        },
    },
    grWr: {
        flexWrap: 'unset',
        alignItems: 'center',
        justifyContent: 'end',
    },
    editButton: {
        backgroundColor: '#059D2A',
        color: 'white',
        '&:hover': {
            background: '#059D2A',
        },
    },
    deleteButton: {
        backgroundColor: '#D86565',
        color: 'white',
        '&:hover': {
            background: '#D86565',
        },
    },
})

const AllGroups = () => {
    const [groups, setGroups] = useState([])
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [totalResults, setTotalResults] = useState(0)
    const [load, setLoad] = useState(false)
    const classes = useStyles()
    const history = useHistory()
    const [editOpen, setEditOpen] = useState(false)
    const [createOpen, setCreateOpen] = useState(false)
    const [editId, setEditId] = useState('')
    const [deleteId, setDeleteId] = useState('')
    const [deleteOpen, setDeleteOpen] = useState(false)
    const [refreshState, setRefreshState] = useState(false)
    const myInfo = useSelector((state) => state.myrole)

    const [search, setSearch] = useState({ eng: '', srb: '' })
    const [searchDebounced] = useDebounce(search, 2000)

    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
    })
    const [severity, setSeverity] = useState('info')
    const handleSnackBarOpen = () => {
        setSnackbar((previous) => ({
            ...previous,
            open: false,
        }))
    }

    const getGroups = async () => {
        try {
            setLoad(true)
            const url =
                searchDebounced.eng === ''
                    ? `/api/group?page=${page + 1}&limit=${rowsPerPage}`
                    : `/api/search/group?eng=${searchDebounced.eng}&srb=${
                          searchDebounced.srb
                      }&page=${page + 1}&limit=${rowsPerPage}`

            const { data } = await axios.get(url)

            const tableData = data.groups.reduce((acc, currentEl) => {
                const addElement = {
                    naziv: currentEl.naziv,
                    kratakNaziv: currentEl.kratakNaziv,
                    tip: latToCyr(currentEl.tip),
                    id: currentEl._id,
                    nadredjenaGrupa: currentEl.nadredjenaGrupa?.naziv,
                    sveLokacije: currentEl.sveVideci ? 'Да' : 'Не',
                }
                acc.push(addElement)
                return acc
            }, [])

            setGroups(tableData)

            const dataLength = parseInt(data.totalResults, 10)
            setTotalResults(dataLength)
            setLoad(false)
        } catch (error) {
            console.log(error)
            setLoad(true)
            setTimeout(() => {
                setLoad(false)
            }, 400)
        }
    }

    const setRefresh = (state) => {
        setRefreshState(state)
    }

    useEffect(() => {
        // if (!refreshState && myInfo.user.rola === 'Admin') {
        if (!refreshState) getGroups()
        // }
        // if (!refreshState && myInfo.user.rola === 'Rukovodilac') {
        //     getOneGroup()
        // }
    }, [page, rowsPerPage, refreshState, searchDebounced.eng])

    const actionBodyTemplate = (rowData) => (
        <Grid key="edit" container className={classes.grWr} spacing={2}>
            {myInfo.user.rola === 'Admin' &&
                rowData.naziv !== 'Админ' &&
                rowData.naziv !== 'Admin' && (
                    <Grid item xs={3} className={classes.buttonWrapper}>
                        <Button
                            name="izmeni"
                            fullWidth
                            className={classes.editButton}
                        >
                            {' '}
                            Измени
                        </Button>
                    </Grid>
                )}
            {myInfo.user.rola === 'Admin' &&
                rowData.naziv !== 'Админ' &&
                rowData.naziv !== 'Admin' && (
                    <Grid item xs={3} className={classes.buttonWrapper}>
                        <Button
                            name="obrisi"
                            color="secondary"
                            fullWidth
                            className={classes.deleteButton}
                        >
                            {' '}
                            Обриши
                        </Button>
                    </Grid>
                )}
            {/* {rowData.naziv !== 'Админ' && ( */}
            <Grid
                item
                xs={myInfo.user.rola === 'Admin' ? 6 : 12}
                className={classes.buttonWrapper}
            >
                <Button
                    name="upravljanjeVezama"
                    color="secondary"
                    variant="outlined"
                >
                    Управљање корисницима
                </Button>
            </Grid>
            {/* )} */}
        </Grid>
    )

    const onRowClick = (rowData) => {
        if (
            rowData.originalEvent.target.nodeName === 'TD' ||
            rowData.originalEvent.target.nodeName === 'DIV'
        ) {
            history.push(`/app/grupa/${rowData.data.id}`, { from: 'GRUPE' })
        }
        if (
            rowData.originalEvent.target.nodeName === 'SPAN' &&
            rowData.originalEvent.target.parentNode.name === 'izmeni'
        ) {
            setEditOpen(true)
            setEditId(rowData.data.id)
        }
        if (
            rowData.originalEvent.target.nodeName === 'SPAN' &&
            rowData.originalEvent.target.parentNode.name === 'obrisi'
        ) {
            setDeleteOpen(true)
            setDeleteId(rowData.data.id)
        }
        if (
            rowData.originalEvent.target.nodeName === 'SPAN' &&
            rowData.originalEvent.target.parentNode.name === 'upravljanjeVezama'
        ) {
            history.push(
                `/app/grupe/upravljanje-korisnicima/${rowData.data.naziv}/${rowData.data.id}`,
                { from: 'UPRAVLJANJE KORISNICIMA' }
            )
            // console.log('upravljanje vezama', rowData.data)
        }
    }

    const setEditModal = (state) => {
        setEditOpen(state)
    }
    const setCreateModal = (state) => {
        setCreateOpen(state)
    }
    const setDeleteModal = (state) => {
        setDeleteOpen(state)
    }

    const handleAddGroup = () => {
        setCreateOpen(true)
    }

    const setSeverityHandler = (state) => {
        setSeverity(state)
    }

    const setSnackbarHandler = (state) => {
        setSnackbar(state)
    }

    return (
        <div style={{ textAlign: 'left' }}>
            <Snackbar
                open={snackbar.open}
                onClose={handleSnackBarOpen}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
                key="key"
            >
                <Alert onClose={handleSnackBarOpen} severity={severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            {editOpen && editId.length > 0 ? (
                <EditGroup
                    id={editId}
                    modalOpen={editOpen}
                    setModal={setEditModal}
                    setRefresh={setRefresh}
                    setSeverityHandler={setSeverityHandler}
                    setSnackbarHandler={setSnackbarHandler}
                />
            ) : null}
            {createOpen ? (
                <CreateGroup
                    modalOpen={createOpen}
                    setModal={setCreateModal}
                    setRefresh={setRefresh}
                    setSeverityHandler={setSeverityHandler}
                    setSnackbarHandler={setSnackbarHandler}
                />
            ) : null}
            {deleteOpen && deleteId.length > 0 ? (
                <DeleteGroup
                    id={deleteId}
                    modalOpen={deleteOpen}
                    setModal={setDeleteModal}
                    setRefresh={setRefresh}
                    setSeverityHandler={setSeverityHandler}
                    setSnackbarHandler={setSnackbarHandler}
                />
            ) : null}
            {/* {load ? (
                <SimpleModal />
            ) : ( */}
            <div className={classes.root}>
                {myInfo.user.rola === 'Admin' && (
                    <Box className={classes.createButton}>
                        <AddButton
                            label="НАПРАВИ НОВУ ГРУПУ"
                            click={handleAddGroup}
                        />
                    </Box>
                )}
                {/* <Button
                    className={classes.createButton}
                    variant="contained"
                    color="primary"
                    onClick={() => setCreateOpen(true)}
                    endIcon={<AddIcon />}
                >
                    НАПРАВИ НОВУ ГРУПУ
                </Button> */}
                {load ? (
                    <SimpleModal />
                ) : (
                    <AdminTable
                        model="group"
                        data={groups}
                        load={load}
                        title="Групе"
                        totalResults={totalResults}
                        page={page}
                        setPage={setPage}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        name={
                            myInfo.user.rola === 'Admin'
                                ? 'iktGroupsTable'
                                : 'iktRukGroupsTable'
                        }
                        onRowClick={onRowClick}
                        tableHeaders={[
                            {
                                field: 'naziv',
                                header: 'Назив',
                            },
                            {
                                field: 'kratakNaziv',
                                header: 'Кратак назив',
                            },
                            { field: 'tip', header: 'Тип' },
                            {
                                field: 'nadredjenaGrupa',
                                header: 'Надређена група',
                            },
                            {
                                field: 'sveLokacije',
                                header: 'Види све локације',
                            },
                            { field: 'edit', header: '' },
                        ]}
                        actionBodyTemplate={actionBodyTemplate}
                        editColumnwidth={
                            myInfo.user.rola === 'Admin' ? 500 : 250
                        }
                        searchColumns={[
                            { dataValue: 'naziv', label: 'Назив' },
                            {
                                dataValue: 'kratakNaziv',
                                label: 'Кратак назив',
                            },
                            { dataValue: 'tip', label: 'Тип' },
                        ]}
                        setSearch={setSearch}
                        search={search}
                        exportFileName="Групе"
                    />
                )}
            </div>
            {/* )} */}
        </div>
    )
}

export default AllGroups
