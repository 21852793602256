import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
    Button,
    Step,
    StepContent,
    StepLabel,
    Stepper,
} from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'

import StepUploadFiles from './StepUploadFiles'
// import AdditionalField from '../AdditionalFieldsForm/AdditionalField'
import AdditionalFields from '../AdditionalFieldsForm/AdditionalFields'
import A1FirstStep from '../AdditionalFieldsForm/A1FirstStep'
import A2FirstStep from '../AdditionalFieldsForm/A2FirstStep'
import A4FirstStep from '../AdditionalFieldsForm/A4FirstStep'
import A51FirstStep from '../AdditionalFieldsForm/A51FirstStep'
import A54FirstStep from '../AdditionalFieldsForm/A54FirstStep'
import A9FirstStep from '../AdditionalFieldsForm/A9FirstStep'
import A13FirstStep from '../AdditionalFieldsForm/A13FirstStep'
import A161FirstStep from '../AdditionalFieldsForm/A161FirstStep'

const useStyles = makeStyles(() => ({
    cardMargin: {
        margin: '2rem auto',
    },
    formWidth: {
        width: '100%',
    },
    containerFlex: {
        display: 'flex',
    },
    showLabel: {
        display: 'flex',
        width: 550,
    },
    hideLabel: {
        display: 'none',
    },
}))

const ActivityStepper = ({
    activity,
    allFiles,
    setSeverity,
    setSnackbar,
    setError,
}) => {
    const classes = useStyles()
    const [activeStep, setActiveStep] = useState(0)
    // eslint-disable-next-line
    const [showStepLabel, setShowStepLabel] = useState({
        uploaded: false,
        statusChanged: false,
    })
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    const getSteps = () => ['Статус активности промењен']
    const getStepsWithExtraFields = () => [
        'Податак додат',
        'Статус активности промењен',
    ]
    const steps =
        activity.kratakNaziv === 'A1' ||
        activity.kratakNaziv === 'A2' ||
        activity.kratakNaziv === 'A4' ||
        activity.kratakNaziv === 'A5.1' ||
        activity.kratakNaziv === 'A5.4' ||
        activity.kratakNaziv === 'A9' ||
        activity.kratakNaziv === 'A13' ||
        activity.kratakNaziv === 'A16.1'
            ? getStepsWithExtraFields()
            : getSteps()

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <StepUploadFiles
                        activity={activity}
                        setSeverity={setSeverity}
                        setSnackbar={setSnackbar}
                        handleNext={handleNext}
                        setError={setError}
                        setShowStepLabel={setShowStepLabel}
                        allFiles={allFiles}
                    />
                )

            default:
                return 'Непознат корак'
        }
    }

    const getStepContentWithExtraFields = (step) => {
        switch (step) {
            case 0:
                if (activity.kratakNaziv === 'A1') {
                    return (
                        <A1FirstStep
                            activity={activity}
                            setSeverity={setSeverity}
                            setSnackbar={setSnackbar}
                            setError={setError}
                            handleNext={handleNext}
                        />
                    )
                }
                if (activity.kratakNaziv === 'A2') {
                    return (
                        <A2FirstStep
                            activity={activity}
                            setSeverity={setSeverity}
                            setSnackbar={setSnackbar}
                            setError={setError}
                            handleNext={handleNext}
                        />
                    )
                }
                if (activity.kratakNaziv === 'A4') {
                    return (
                        <A4FirstStep
                            activity={activity}
                            setSeverity={setSeverity}
                            setSnackbar={setSnackbar}
                            setError={setError}
                            handleNext={handleNext}
                        />
                    )
                }
                if (activity.kratakNaziv === 'A5.1') {
                    return (
                        <A51FirstStep
                            activity={activity}
                            setSeverity={setSeverity}
                            setSnackbar={setSnackbar}
                            setError={setError}
                            handleNext={handleNext}
                        />
                    )
                }
                if (activity.kratakNaziv === 'A5.4') {
                    return (
                        <A54FirstStep
                            activity={activity}
                            setSeverity={setSeverity}
                            setSnackbar={setSnackbar}
                            setError={setError}
                            handleNext={handleNext}
                        />
                    )
                }
                if (activity.kratakNaziv === 'A9') {
                    return (
                        <A9FirstStep
                            activity={activity}
                            setSeverity={setSeverity}
                            setSnackbar={setSnackbar}
                            setError={setError}
                            handleNext={handleNext}
                        />
                    )
                }
                if (activity.kratakNaziv === 'A13') {
                    return (
                        <A13FirstStep
                            activity={activity}
                            setSeverity={setSeverity}
                            setSnackbar={setSnackbar}
                            setError={setError}
                            handleNext={handleNext}
                        />
                    )
                }
                if (activity.kratakNaziv === 'A16.1') {
                    return (
                        <A161FirstStep
                            activity={activity}
                            setSeverity={setSeverity}
                            setSnackbar={setSnackbar}
                            setError={setError}
                            handleNext={handleNext}
                        />
                    )
                }

                return (
                    <AdditionalFields
                        activity={activity}
                        setSeverity={setSeverity}
                        setSnackbar={setSnackbar}
                        setError={setError}
                        handleNext={handleNext}
                    />
                )

            case 1:
                return (
                    <StepUploadFiles
                        activity={activity}
                        setSeverity={setSeverity}
                        setSnackbar={setSnackbar}
                        handleNext={handleNext}
                        setError={setError}
                        setShowStepLabel={setShowStepLabel}
                        allFiles={allFiles}
                    />
                )

            default:
                return 'Непознат корак'
        }
    }
    return (
        <>
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => (
                    <Step key={label}>
                        {activity.status !== 'Završena' && (
                            <StepLabel> </StepLabel>
                        )}
                        <StepContent className={classes.containerFlex}>
                            {activity.status !== 'Završena' &&
                            (activity.kratakNaziv === 'A1' ||
                                activity.kratakNaziv === 'A2' ||
                                activity.kratakNaziv === 'A4' ||
                                activity.kratakNaziv === 'A5.1' ||
                                activity.kratakNaziv === 'A5.4' ||
                                activity.kratakNaziv === 'A9' ||
                                activity.kratakNaziv === 'A13' ||
                                activity.kratakNaziv === 'A16.1')
                                ? getStepContentWithExtraFields(index)
                                : getStepContent(index)}
                            <div className={classes.actionsContainer}>
                                {activeStep !== 0 && (
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                    >
                                        Назад
                                    </Button>
                                )}
                            </div>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
            {/* {activeStep === steps.length && (
                <Paper square elevation={0}>
                    <Typography>
                        Успешно сте променили статус активности
                    </Typography>
                </Paper>
            )} */}
        </>
    )
}

export default ActivityStepper

ActivityStepper.propTypes = {
    activity: PropTypes.objectOf(PropTypes.any).isRequired,
    allFiles: PropTypes.arrayOf(PropTypes.any),
    setSeverity: PropTypes.func.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    setError: PropTypes.func.isRequired,
}

ActivityStepper.defaultProps = {
    allFiles: [],
}
