import React, { useEffect, useState } from 'react'
import moment from 'moment'

// eslint-disable-next-line no-unused-vars
import { Container, makeStyles, Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useDebounce } from 'use-debounce'

import { axiosAuth } from '../../util/axios-instance'
import UserTable from '../../components/tables/UserTable'
import SimpleModal from '../../components/Modal/Modal'
import latToCyr from '../../util/latToCyr'
import InfoPanelNotification from '../../components/FormsUI/Info/InfoPanelNotification'
import PageTitle from '../../components/FormsUI/PageTitle/PageTitle'
import daysToDeadline from '../../util/daysToDeadline'

const useStyles = makeStyles({
    notificationPage: {
        margin: '50px 0px',
    },
})

const NotificationPage = () => {
    const classes = useStyles()
    const [actions, setActions] = useState('')
    // eslint-disable-next-line
    const [load, setLoad] = useState(false)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const [search, setSearch] = useState({ eng: '', key: '', srb: '' })
    const [searchNotification] = useDebounce(search, 2000)
    const myRole = useSelector((state) => state.myrole)

    const isGuest = myRole && myRole.user && myRole.user.rola === 'Gost'

    const history = useHistory()

    const getNotification = async () => {
        try {
            setLoad(true)
            const url =
                searchNotification.eng === ''
                    ? `/api/activity/notifications?page=${
                          page + 1
                      }&limit=${rowsPerPage}`
                    : `/api/search/notification?eng=${
                          searchNotification.eng
                      }&srb=${searchNotification.srb}&page=${
                          page + 1
                      }&limit=${rowsPerPage}`

            const { data } = await axiosAuth.get(url)

            const svevidece = await axiosAuth.get('/api/group/sve-videci/true')
            // console.log('NOTIFIKACIJE', data, svevidece)
            const svevideceArr = []
            svevidece.data.groups.map((el) => svevideceArr.push(el))

            // const dateFormat = 'DD-MM-YYYY'

            const tableData =
                data.activities &&
                data.activities.reduce((acc, currentEl) => {
                    const startDate = currentEl?.datumPocetka
                        ? moment(currentEl?.datumPocetka).format('YYYY/MM/DD')
                        : 'Непознат датум'

                    const groupReliable = [
                        ...(currentEl?.lokacija?.odgovoran
                            ? currentEl?.lokacija?.odgovoran
                            : []),
                        ...svevideceArr,
                    ]
                        .filter((el) => el.tip === 'DOB')
                        .map((el) => el.naziv)
                        .join(', ')

                    const rokZavr = currentEl.rok
                        ? moment(currentEl?.datumPocetka)
                              .add(currentEl.rok, 'd')
                              .format('YYYY/MM/DD')
                        : 'клизно'

                    const rokZavrsetkaNeformatiran = currentEl.rok
                        ? moment(currentEl?.datumPocetka).add(
                              currentEl.rok,
                              'd'
                          )
                        : 'клизно'

                    /* eslint-disable */
                    const addElement = {
                        oldId: `Л${currentEl.lokacija.old_ID}`,
                        lokId: latToCyr(
                            currentEl.lokacija.lokacija_ID.toUpperCase()
                        ),
                        ustId: latToCyr(
                            currentEl.lokacija.ustanova_ID.toUpperCase()
                        ),
                        id: currentEl._id,
                        lokacija: currentEl?.lokacija.naziv,
                        aktivnost: currentEl.kratakNaziv,
                        opstina: currentEl.lokacija.opstina,
                        naselje: currentEl.lokacija.naselje,
                        odgovoran: groupReliable,
                        datumPocetka:
                            currentEl.status === 'Nije započeta'
                                ? 'Незапочета активност'
                                : startDate,
                        datumZavrsetka:
                            currentEl.status === 'Nije započeta'
                                ? 'Незапочета активност'
                                : rokZavr,
                        status: latToCyr(currentEl.status),
                        brojDanaDoRoka: currentEl?.brojDanaDoRoka,
                        rok: currentEl.rok,
                        datumZavrsetkaNeformatiran: rokZavrsetkaNeformatiran,
                    }

                    acc.push(addElement)
                    return acc
                }, [])

            setActions({ totalResults: data.totalResults, actions: tableData })

            setLoad(false)
        } catch (error) {
            console.log(error.response)
            setLoad(false)
        }
    }
    useEffect(() => {
        if (!isGuest) getNotification()
    }, [page, rowsPerPage, searchNotification.eng])

    const onRowClick = (rowData) => {
        history.push(`/app/aktivnost/${rowData.data.id}`, {
            from: 'NOTIFIKACIJE',
        })
    }

    const rowClass = (rowData) => {
        // console.log(rowData)

        const daysLeft = daysToDeadline(
            new Date(),
            rowData.datumZavrsetkaNeformatiran
        )

        const tenPercentToDeadLine = (daysLeft * 1) / rowData.rok

        if (
            (daysLeft * 1 > 0 && tenPercentToDeadLine > 0.1) ||
            rowData.datumZavrsetka === 'клизно'
        )
            return { blueRow: true }
        if (daysLeft * 1 >= 0 && tenPercentToDeadLine < 0.1) {
            return { orangeRow: true }
        }
        if (daysLeft * 1 < 0) {
            return { redRow: true }
        }

        return { white: true }
    }

    const notificationsTableContent = (
        <>
            {' '}
            {load && <SimpleModal />}
            {actions ? (
                <Container className={classes.notificationPage}>
                    <UserTable
                        model="activity/notifications"
                        data={actions.actions}
                        noData="Немате обавештења"
                        title="обавештења"
                        totalResults={actions.totalResults}
                        page={page}
                        setPage={setPage}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        name="iktNotificationTable"
                        onRowClick={onRowClick}
                        rowClass={rowClass}
                        exportFileName="нотификације"
                        tableHeaders={[
                            {
                                field: 'ustId',
                                header: 'ИД Установе',
                            },
                            {
                                field: 'lokId',
                                header: 'ИД Локације',
                            },
                            { field: 'lokacija', header: 'Локација' },
                            { field: 'opstina', header: 'Општина' },
                            { field: 'naselje', header: 'Насеље' },
                            {
                                field: 'aktivnost',
                                header: 'Активност',
                            },
                            {
                                field: 'odgovoran',
                                header: 'Извођач радова',
                            },
                            {
                                field: 'datumPocetka',
                                header: 'Датум почетка',
                            },
                            {
                                field: 'datumZavrsetka',
                                header: 'Рок за завршетак',
                            },
                            {
                                field: 'status',
                                header: 'Статус',
                            },
                        ]}
                        setSearch={setSearch}
                        search={search}
                        withSearch
                    />
                    <InfoPanelNotification />
                </Container>
            ) : null}
        </>
    )

    return (
        <>
            {isGuest ? (
                <Container className={classes.notificationPage}>
                    <PageTitle title="Молимо сачекајте да вам се додели рола" />
                </Container>
            ) : (
                notificationsTableContent
            )}
        </>
    )
}

export default NotificationPage
