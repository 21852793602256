import React from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import ButtonWrapper from '../../components/FormsUI/Button/ButtonWrapper'
import TextfieldWrapper from '../../components/FormsUI/Textfield/TextfieldWrapper'
import { axiosInstance } from '../../util/axios-instance'
import Logo from '../../assets/logo.png'
import CustomButton from '../../components/FormsUI/Button/CustomButton'
import ForgotPassBtn from '../../components/FormsUI/Button/ForgotPassBtn'
import PageTitle from '../../components/FormsUI/PageTitle/PageTitle'

const useStyles = makeStyles((theme) => ({
    formContainer: {
        marginTop: theme.spacing(15),
        padding: '4rem 2rem',
        background: '#FFF',
        margin: '2rem',
        maxWidth: 600,
        height: 'max-content',
        borderRadius: 10,
    },
    formWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8),
    },
    // alignAll: {
    //     display: 'flex',
    //     justifyContent: 'flex-end',
    //     alignItems: 'center',
    // },
    Leftside: {
        // height: '100vh',
        background: '#4E5054',
        textAlign: 'center',
    },
    Rightside: {
        background: '#F79256',
        display: 'flex',
        justifyContent: 'center',
    },
    logocenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '50%',
    },
    logoSize: {
        width: 50,
        marginRight: '1rem',
    },
    logodlpmsize: {
        width: 300,
    },
    titleLogo: {
        maxWidth: '90%',
        color: '#FFFFFF',
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        },
    },
    marginB: {
        marginBottom: 35,
    },
    logoContainer: {
        height: '100vh',
        [theme.breakpoints.down('sm')]: {
            height: '100%',
            padding: '1rem 0',
        },
    },
    dlpmlogo: {
        position: 'absolute',
        bottom: 10,
    },
}))

const Login = () => {
    const classes = useStyles()
    const history = useHistory()

    const INITIAL_FORM_STATE = {
        email: '',
        lozinka: '',
    }

    const FORM_VALIDATION = Yup.object().shape({
        email: Yup.string().required('Обавезно поље'),
        lozinka: Yup.string().required('Унесите лозинку'),
    })

    const handleLogin = async (userInfo, { setErrors, resetForm }) => {
        try {
            const res = await axiosInstance.post(`/api/user/login`, userInfo)
            resetForm(INITIAL_FORM_STATE)
            const { token, user } = res.data
            localStorage.setItem('token', token)

            setTimeout(() => {
                if (user.rola === 'Admin')
                    history.push('/app/aktivnosti-u-toku')
                else history.push('/app/')
            }, 200)
        } catch (err) {
            console.log(err)
            const error = err.response.data.message.includes('Korisnik')
                ? 'email'
                : 'lozinka'
            setErrors({
                [error]: err.response && err.response.data.message,
            })
        }
    }

    // ctx.handleAuthHeader(true)
    return (
        <Grid container className={classes.mainCointainer}>
            <Grid item xs={12} md={6} className={classes.Leftside}>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    className={classes.logoContainer}
                >
                    <Grid item xs={12} className={classes.logocenter}>
                        <img
                            src={Logo}
                            alt="logo"
                            className={classes.logoSize}
                        />
                        <div>
                            <Typography
                                variant="h2"
                                className={classes.titleLogo}
                            >
                                Министарство информисања и телекомуникација
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6} className={classes.Rightside}>
                <Grid
                    container
                    justifyContent="center"
                    className={classes.formContainer}
                >
                    <Grid item>
                        <Formik
                            initialValues={{
                                ...INITIAL_FORM_STATE,
                            }}
                            validationSchema={FORM_VALIDATION}
                            onSubmit={handleLogin}
                        >
                            {({ isSubmitting }) => (
                                <Form>
                                    <Grid container spacing={4}>
                                        <Grid item xs={12}>
                                            <PageTitle title="Пријава корисника" />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextfieldWrapper
                                                name="email"
                                                variant="outlined"
                                                label="Имејл"
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextfieldWrapper
                                                name="lozinka"
                                                type="password"
                                                variant="outlined"
                                                label="Лозинка"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ForgotPassBtn />
                                        </Grid>
                                        {/* <Grid
                                                item
                                                xs={6}
                                                className={classes.alignAll}
                                            >
                                                <Link
                                                    variant="body2"
                                                    component={RouterLink}
                                                    to="/forgot-password"
                                                >
                                                    {' '}
                                                    {intl.formatMessage({
                                                        id: 'forgotPassword',
                                                    })}
                                                </Link>{' '}
                                            </Grid> */}
                                        <Grid item xs={12}>
                                            <ButtonWrapper
                                                disabled={isSubmitting}
                                                type="submit"
                                                variant="contained"
                                                buttonStyles={{
                                                    height: 48,
                                                    fontSize: '1.2rem',
                                                    fontWeight: 900,
                                                }}
                                            >
                                                Улогуј се
                                            </ButtonWrapper>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                        <Grid item xs={12}>
                            <RouterLink
                                to="/registracija"
                                style={{ textDecoration: 'none' }}
                            >
                                <CustomButton>
                                    Немаш налог? региструј се
                                </CustomButton>
                            </RouterLink>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Login
