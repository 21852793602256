import React from 'react'
import { Button, Grid, Typography } from '@material-ui/core'
import TextfieldWrapper from '../../components/FormsUI/Textfield/TextfieldWrapper'
import ButtonWrapper from '../../components/FormsUI/Button/ButtonWrapper'
import Notification from './Notification'

const AddEditModal = ({ type, title, selected, closeModal, message }) => {
    const content = (
        <Grid container spacing={1}>
            <Grid item xs={7}>
                <Typography variant="h2">{title}</Typography>
            </Grid>
            <Grid item xs={12}>
                {type === 'podgrupa' ? (
                    <>
                        <TextfieldWrapper
                            name="naziv"
                            label="Назив"
                            value={
                                selected && selected.naziv ? selected.naziv : ''
                            }
                        />
                    </>
                ) : (
                    <>
                        <TextfieldWrapper
                            name="naziv"
                            label="Назив"
                            value={
                                selected && selected.naziv ? selected.naziv : ''
                            }
                        />
                        <TextfieldWrapper
                            name="broj"
                            label="Број"
                            value={
                                selected && selected.broj ? selected.broj : ''
                            }
                        />
                    </>
                )}
                <Grid container spacing={2} style={{ marginTop: '1.5rem' }}>
                    <Grid item xs={6}>
                        <ButtonWrapper
                            type="submit"
                            variant="contained"
                            fullWidth
                        >
                            Потврди
                        </ButtonWrapper>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="outlined"
                            fullWidth
                            onClick={() => closeModal()}
                        >
                            Откажи
                        </Button>
                    </Grid>
                </Grid>
                <Notification message={message} />
            </Grid>
        </Grid>
    )

    return content
}

export default AddEditModal
