import React from 'react'
import PropTypes from 'prop-types'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MailIcon from '@material-ui/icons/Mail'
import uuid from 'react-uuid'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import AssessmentIcon from '@material-ui/icons/Assessment'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'

import LocalLibraryIcon from '@material-ui/icons/LocalLibrary'
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined'
import LocalActivityIcon from '@material-ui/icons/LocalActivity'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'
import TextSnippetIcon from '@material-ui/icons/TextSnippet'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import HomeWorkIcon from '@material-ui/icons/HomeWork'
import ContactsIcon from '@material-ui/icons/Contacts'
import Skeleton from '@material-ui/lab/Skeleton'
import { makeStyles } from '@material-ui/core/styles'
// import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import InventoryIcon from '@material-ui/icons/Inventory'

const styles = () => ({
    navlinks: {
        width: '100%',
        color: '#000',
    },
    toolbar: {
        marginTop: 30,
    },
    selectedListItem: {
        backgoundColor: 'yellow',
    },
})

const SideBarConfig = ({ role }) => {
    const history = useHistory()
    const location = useLocation()
    const useStyles = makeStyles(styles)
    const classes = useStyles()
    const myInfo = useSelector((state) => state.myrole)

    // console.log('history is sidebar', history.location.pathname)
    const navLinksAdmin = [
        {
            text: 'Активности у току',
            icon: <AssignmentOutlinedIcon />,
            onClick: () => history.push('/app/aktivnosti-u-toku'),
            divider: false,
            path: '/app/aktivnosti-u-toku',
        },
        {
            text: 'Корисници',
            icon: <PeopleAltIcon />,
            onClick: () => history.push('/app/korisnici'),
            divider: false,
            path: '/app/korisnici',
        },
        {
            text: 'Роле',
            icon: <ContactsIcon />,
            onClick: () => history.push('/app/role'),
            divider: false,
            path: '/app/role',
        },
        {
            text: 'Локације',
            icon: <LocationOnIcon />,
            onClick: () => history.push('/app/lokacije'),
            divider: false,
            path: '/app/lokacije',
        },
        {
            text: 'Групе',
            icon: <HomeWorkIcon />,
            onClick: () => history.push('/app/grupe'),
            divider: false,
            path: '/app/grupe',
        },
        {
            text: 'Уговори',
            icon: <ListAltOutlinedIcon />,
            onClick: () => history.push('/app/ugovori'),
            divider: true,
            path: '/app/ugovori',
        },
        {
            text: 'Активности',
            icon: <LocalActivityIcon />,
            onClick: () => history.push('/app/aktivnosti'),
            divider: false,
            path: '/app/aktivnosti',
        },
        {
            text: 'Инвентар',
            icon: <InventoryIcon />,
            onClick: () => history.push('/app/inventar'),
            divider: false,
            path: '/app/inventar',
        },
        {
            text: 'Извештаји',
            icon: <AssessmentIcon />,
            onClick: () => history.push('/app/izvestaji'),
            divider: false,
            path: '/app/izvestaji',
        },

        {
            text: 'Шифрарници',
            icon: <LocalLibraryIcon />,
            onClick: () => history.push('/app/sifrarnici'),
            divider: false,
            path: '/app/sifrarnici',
        },

        {
            text: 'СМТП',
            icon: <MailIcon />,
            onClick: () => history.push('/app/emailconfig'),
            divider: false,
            path: '/app/emailconfig',
        },

        {
            text: 'Логови',
            icon: <HomeWorkIcon />,
            onClick: () => history.push(`/app/logovi`),
            divider: false,
            path: '/app/logovi',
        },
        {
            text: 'Мој налог',
            icon: <AccountBoxIcon />,
            onClick: () => history.push('/app/moj-nalog'),
            divider: false,
            path: '/app/moj-nalog',
        },
        {
            text: 'Коментари',
            icon: <TextSnippetIcon />,
            onClick: () => history.push('/app/komentari'),
            divider: false,
            path: '/app/komentari',
        },
    ]

    const navLinksUser = [
        {
            text: 'Обавештења',
            icon: <NotificationsActiveIcon />,
            onClick: () => history.push('/app'),
            path: '/app',
        },
        {
            text: 'Локације',
            icon: <PeopleAltIcon />,
            onClick: () => history.push(`/app/lokacije-user`),
            path: `/app/lokacije-user`,
        },

        {
            text: 'Извештаји',
            icon: <AssessmentIcon />,
            onClick: () => history.push(`/app/izvestaji/`),
            path: `/app/izvestaji/`,
        },
        {
            text: 'Логови',
            icon: <HomeWorkIcon />,
            onClick: () => history.push(`/app/logovi`),
            path: `/app/logovi`,
        },
        {
            text: 'Мој налог',
            icon: <AccountBoxIcon />,
            onClick: () => history.push('/app/moj-nalog'),
            path: '/app/moj-nalog',
        },
        {
            text: 'Коментари',
            icon: <TextSnippetIcon />,
            onClick: () => history.push('/app/komentari'),
            path: '/app/komentari',
        },
    ]

    if (myInfo?.user?.grupa?.tip === 'MTT') {
        navLinksUser.splice(3, 0, {
            text: 'Инвентар',
            icon: <InventoryIcon />,
            onClick: () => history.push('/app/inventar'),
            divider: false,
            path: '/app/inventar',
        })
    }

    const navlinksManager = [
        {
            text: 'Корисници',
            icon: <PeopleAltIcon />,
            onClick: () => history.push('/app/korisnici'),
            divider: false,
            path: '/app/korisnici',
        },
        {
            text: 'Роле',
            icon: <ContactsIcon />,
            onClick: () => history.push('/app/role'),
            divider: false,
            path: '/app/role',
        },
        {
            text: 'Групе',
            icon: <HomeWorkIcon />,
            onClick: () => history.push('/app/grupe'),
            divider: false,
            path: '/app/grupe',
        },
        {
            text: 'Обавештења',
            icon: <NotificationsActiveIcon />,
            onClick: () => history.push('/app'),
            divider: false,
            path: '/app',
        },
        {
            text: 'Локације',
            icon: <PeopleAltIcon />,
            onClick: () => history.push(`/app/lokacije-user`),
            path: '/app/lokacije-user',
        },
        {
            text: 'Извештаји',
            icon: <AssessmentIcon />,
            onClick: () => history.push('/app/izvestaji'),
            divider: false,
            path: '/app/izvestaji',
        },
        {
            text: 'Логови',
            icon: <HomeWorkIcon />,
            onClick: () => history.push(`/app/logovi`),
            path: '/app/logovi',
        },
        {
            text: 'Мој налог',
            icon: <AccountBoxIcon />,
            onClick: () => history.push('/app/moj-nalog'),
            path: '/app/moj-nalog',
        },
        {
            text: 'Коментари',
            icon: <TextSnippetIcon />,
            onClick: () => history.push('/app/komentari'),
            path: '/app/komentari',
        },
    ]
    return (
        <div>
            <div className={classes.toolbar} />

            <List>
                {role === 'Admin' &&
                    navLinksAdmin.map((rout) => {
                        const { text, icon, onClick, path } = rout
                        return (
                            <ListItem
                                button
                                className={classes.navlinks}
                                key={uuid()}
                                onClick={onClick}
                                selected={path === location.pathname}
                            >
                                {icon && <ListItemIcon>{icon}</ListItemIcon>}

                                <ListItemText primary={text} />
                            </ListItem>
                        )
                    })}
                {role === 'Zaposleni' &&
                    navLinksUser.map((rout) => {
                        const { text, icon, onClick, path } = rout
                        return (
                            <ListItem
                                button
                                className={classes.navlinks}
                                key={uuid()}
                                onClick={onClick}
                                selected={path === location.pathname}
                            >
                                {icon && <ListItemIcon>{icon}</ListItemIcon>}

                                <ListItemText primary={text} />
                            </ListItem>
                        )
                    })}
                {role === 'Rukovodilac' &&
                    navlinksManager.map((rout) => {
                        const { text, icon, onClick, path } = rout
                        return (
                            <ListItem
                                button
                                className={classes.navlinks}
                                key={uuid()}
                                onClick={onClick}
                                selected={path === location.pathname}
                            >
                                {icon && <ListItemIcon>{icon}</ListItemIcon>}

                                <ListItemText primary={text} />
                            </ListItem>
                        )
                    })}
                {role === 'Gost' && <></>}
                {!role && <Skeleton />}
            </List>
        </div>
    )
}

export default SideBarConfig

SideBarConfig.propTypes = {
    role: PropTypes.string.isRequired,
}
