import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
// import { useDebounce } from 'use-debounce'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, TablePagination, Button, Snackbar } from '@material-ui/core'
import { DataTable } from 'primereact/datatable'
import { useDebounce } from 'use-debounce'
// import { InputText } from 'primereact/inputtext'
import { Column } from 'primereact/column'
import moment from 'moment'
// import WarningIcon from '@material-ui/icons/Warning'
import { Alert } from '@material-ui/lab'
import { axiosAuth as axios } from '../../util/axios-instance'

// import AdminTable from '../../components/tables/AdminTable'
import PageTitle from '../../components/FormsUI/PageTitle/PageTitle'
import SimpleModal from '../../components/Modal/Modal'
import SearchBar from '../../components/SearchBar/SearchBar'

const useStyles = makeStyles({
    pageContainer: {
        padding: '50px 50px',
    },
    connectButton: {
        margin: '5px 0',
    },
})

const Logs = () => {
    const classes = useStyles()
    const [logs, setLogs] = useState({ totalLogs: '', logs: [] })
    const [page, setPage] = useState(0)
    // eslint-disable-next-line
    const [load, setLoad] = useState(false)
    const [severity, setSeverity] = useState('info')
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [selection, setSelection] = useState([])
    const [deleted, setDeleted] = useState(false)
    const myRole = useSelector((state) => state.myrole)
    // const [errlogs, setErrLogs] = useState('')
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
    })

    // console.log(logs)

    const [search, setSearch] = useState({ eng: '', key: '', srb: '' })
    const [searchDebounced] = useDebounce(search, 2000)

    const selectedIds = selection ? selection.map((item) => item.id) : []

    useEffect(() => {
        const getLogs = async () => {
            try {
                setLoad(true)
                const url =
                    searchDebounced.eng === ''
                        ? `/api/log?page=${page + 1}&limit=${rowsPerPage}`
                        : `/api/search/log?eng=${searchDebounced.eng}&srb=${
                              searchDebounced.srb
                          }&page=${page + 1}&limit=${rowsPerPage}`

                const { data } = await axios.get(url)

                const tableData = data?.logs?.reduce((acc, currentEl) => {
                    const timeFormat = moment(currentEl?.createdAt).format(
                        'HH:mm:ss DD/MM/YYYY'
                    )
                    const addElement = {
                        ime:
                            (currentEl?.user &&
                                `${
                                    currentEl?.user?.ime
                                        ? currentEl.user.ime
                                        : currentEl.user[0].ime
                                } ${
                                    currentEl?.user?.prezime
                                        ? currentEl.user.prezime
                                        : currentEl.user[0].prezime
                                }`) ||
                            '-',
                        ip: currentEl?.ip,
                        poruka: currentEl?.message,
                        url: currentEl?.url,
                        id: currentEl?._id,
                        grupa: currentEl?.group?.cirilica || '-',
                        vreme: timeFormat,
                    }
                    acc.push(addElement)
                    return acc
                }, [])

                // console.log(tableData)

                setLogs({
                    totalLogs: data.totalLogs || data.totalResults,
                    logs: tableData,
                })
                setLoad(false)
            } catch (error) {
                setLoad(false)
            }
        }

        getLogs()
    }, [page, deleted, rowsPerPage, searchDebounced.eng])

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const footer = (
        <Grid item xs={12}>
            <Grid container justifyContent="space-between">
                <Grid item xs={12}>
                    <Grid container justifyContent="center">
                        <TablePagination
                            component="div"
                            count={parseInt(logs.totalLogs, 10) ?? 0}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage="редова по страници"
                            labelDisplayedRows={({ from, to, count }) =>
                                `приказ страница ${from}-${to} od ${count}`
                            }
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )

    // const onRowClick = (rowData) => {
    //     if (rowData.originalEvent.target.nodeName === 'TD') {
    //         history.push(`/app/korisnici/${rowData.data._id}`)
    //     }

    //     if (rowData.originalEvent.target.parentNode.name === 'izmeni') {
    //         setSelectedUser({ action: 'izmeni', currentUser: rowData.data })
    //         handleOpenEditUserModal()
    //         console.log(openEditUserModal)
    //         console.log(rowData.originalEvent.target.parentNode.name)
    //     }
    //     if (rowData.originalEvent.target.parentNode.name === 'obrisi') {
    //         setSelectedUser({ action: 'obrisi', currentUser: rowData.data })
    //         handleOpenDeleteUserModal()
    //         console.log(rowData.originalEvent.target.parentNode.name)
    //     }
    // }

    const handleDeleteLogs = async () => {
        try {
            // eslint-disable-next-line
            const res = await axios.delete(`/api/log/`, {
                data: {
                    logIds: selectedIds,
                },
            })
            setSeverity('success')
            setSnackbar({
                open: true,
                message: 'Успешно обрисан/и логови ',
            })
            setSelection([])
            setDeleted(!deleted)
        } catch (err) {
            setSeverity('warning')
            setSnackbar({
                open: true,
                message: err.response.data.message,
            })
        }
    }

    const myRoleCheck =
        myRole && myRole.user && myRole.user.rola === 'Admin' ? 'multiple' : ''

    const handleSelection = (e) => {
        setSelection(e.value)
    }

    // Snackbar logic
    const handleSnackBarOpen = () => {
        setSnackbar((previous) => ({
            ...previous,
            open: false,
        }))
    }
    return (
        <Grid container className={classes.pageContainer}>
            <Snackbar
                open={snackbar.open}
                onClose={handleSnackBarOpen}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
                key="key"
            >
                <Alert onClose={handleSnackBarOpen} severity={severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            <Grid container>
                <Grid container justifyContent="center">
                    <PageTitle title="Логови" />
                </Grid>

                <Grid container justifyContent="space-between">
                    <Grid item>
                        {selection.length > 0 &&
                        myRole?.user?.rola === 'Admin' ? (
                            <Button
                                className={classes.connectButton}
                                // onClick={() => connectLocations(selection)}
                                variant="contained"
                                color="secondary"
                                onClick={
                                    myRole.user.rola === 'Admin'
                                        ? handleDeleteLogs
                                        : null
                                }
                            >
                                Обриши селектоване логове
                            </Button>
                        ) : (
                            <Button disabled className={classes.connectButton}>
                                {myRole?.user?.rola === 'Admin'
                                    ? 'Обриши селектоване логове'
                                    : null}
                            </Button>
                        )}
                    </Grid>
                    <Grid item>
                        <SearchBar
                            setSearch={setSearch}
                            search={search}
                            setPage={setPage}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    {load ? (
                        <SimpleModal />
                    ) : (
                        <DataTable
                            className={`${classes.table} blackTable`}
                            value={logs?.logs}
                            selection={selection}
                            onSelectionChange={
                                myRole?.user?.rola === 'Admin'
                                    ? handleSelection
                                    : ''
                            }
                            dataKey="id"
                            selectionMode="single"
                            // globalFilter={globalFilter}
                            footer={footer}
                            // onRowClick={onRowClick}
                            emptyMessage="Нема пронађених података."
                            setPage={setPage}
                        >
                            <Column
                                selectionMode={myRoleCheck}
                                headerStyle={{ width: '3em' }}
                            />
                            <Column field="ime" header="Име" sortable />

                            <Column field="poruka" header="Лог/Порука" />
                            <Column field="grupa" header="Група" sortable />
                            <Column field="vreme" header="Време" />
                        </DataTable>
                    )}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Logs
