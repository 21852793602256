import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDebounce } from 'use-debounce'
import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import download from 'downloadjs'
// import moment from 'moment'
import { Grid, Box, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import EditLocation from '../EditLocation/EditLocation'
// import SearchBar from '../../../components/SearchBar/SearchBar'
// import SelectWrapper from '../../../components/FormsUI/Select/SelectWrapper'
import { axiosAuth as axios } from '../../../util/axios-instance'
import AdminTable from '../../../components/tables/AdminTable'
import SimpleModal from '../../../components/Modal/Modal'
import CreateLocation from '../CreateLocation/CreateLocation'
import DeleteLocation from '../DeleteLocation'
import AddButton from '../../../components/FormsUI/Button/AddButton'
// import latToCyr from '../../../util/latToCyr'
import InfoPanel from '../../../components/FormsUI/Info/InfoPanel'
import latToCyr from '../../../util/latToCyr'

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    createbutton: {
        padding: 8,
        margin: '20px 0px',
    },
    root: {
        margin: 12,
        textAlign: 'left',
    },
    table: {
        margin: 8,
    },
    editButton: {
        backgroundColor: '#059D2A',
        color: 'white',
        '&:hover': {
            background: '#059D2A',
        },
        border: '2px solid white',
    },
    deleteButton: {
        backgroundColor: '#D86565',
        color: 'white',
        '&:hover': {
            background: '#D86565',
        },
        border: '2px solid white',
    },
    buttonW: {
        margin: '1rem 0',
    },
}))

const AllLocations = () => {
    const history = useHistory()
    const classes = useStyles()
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [locations, setLocations] = useState([])
    const [totalResults, setTotalResults] = useState(0)
    const [load, setLoad] = useState(false)
    const [createOpen, setCreateOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false)
    const [deleteOpen, setDeleteOpen] = useState(false)
    const [editId, setEditId] = useState('')
    const [deleteId, setDeleteId] = useState('')
    const [refreshState, setRefreshState] = useState(false)

    const [search, setSearch] = useState({ eng: '', key: '', srb: '' })
    const [searchDebounced] = useDebounce(search, 2000)
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
    })
    const [severity, setSeverity] = useState('info')

    const handleSnackBarOpen = () => {
        setSnackbar((previous) => ({
            ...previous,
            open: false,
        }))
    }

    const getAllLocations = async () => {
        try {
            setLoad(true)
            const url =
                // prettier-ignore
                searchDebounced.eng === ''
                    ? `/api/location?page=${page + 1}&limit=${rowsPerPage}`
                    : `/api/search/location?eng=${searchDebounced.eng}&srb=${searchDebounced.srb
                    }&page=${page + 1}&limit=${rowsPerPage}`
            // console.log('URL za sve lokacije', url)
            const { data } = await axios.get(url)
            // console.log(data)

            const svevidece = await axios.get('/api/group/sve-videci/true')
            const svevideceArr = []
            svevidece.data.groups.map((el) => svevideceArr.push(el))

            const tableData =
                data &&
                data.locations.reduce((acc, currentEl) => {
                    const groupArray = [
                        ...(currentEl?.odgovoran ? currentEl.odgovoran : []),
                        ...svevideceArr,
                    ]
                        .filter((el) => el.tip === 'DOB')
                        .map((el) => el.naziv)
                        .join(', ')

                    // const act = data.aktivne.filter(
                    //     (a) => currentEl._id === a.lokacija
                    // )

                    const addElement = {
                        id: currentEl._id,
                        ustanova_ID: currentEl?.ustanova_ID
                            ? latToCyr(currentEl.ustanova_ID.toUpperCase())
                            : '',
                        lokacija_ID: currentEl?.lokacija_ID
                            ? latToCyr(currentEl.lokacija_ID.toUpperCase())
                            : '',
                        ustId: currentEl.ustanova_ID.split('u')[1],
                        lokId: currentEl.old_ID,
                        naziv: currentEl?.naziv ? currentEl.naziv : '',
                        adresa: currentEl?.adresa ? currentEl.adresa : '',
                        postanskiBroj: currentEl?.postanskiBroj
                            ? currentEl.postanskiBroj
                            : '',
                        naselje: currentEl?.naselje ? currentEl.naselje : '',
                        opstina: currentEl?.opstina ? currentEl.opstina : '',
                        ugovor: currentEl?.ugovor?.naziv
                            ? currentEl.ugovor.naziv
                            : '',
                        prioritet: currentEl?.prioritet?.naziv
                            ? currentEl.prioritet.naziv
                            : '',
                        izvodjacRadova: groupArray,
                        status: currentEl.status,
                        tlocrt: (
                            <span
                                data-name="download"
                                name="download"
                                style={{
                                    width: '100%',
                                    display: 'inline-block',
                                }}
                            >
                                Преузмите
                            </span>
                        ),
                    }
                    acc.push(addElement)
                    return acc
                }, [])

            setLoad(false)
            setLocations(tableData)

            const dataLength = parseInt(data.totalResults, 10)

            setTotalResults(dataLength)
        } catch (error) {
            console.log(error)
            setLoad(true)
            setTimeout(() => {
                setLoad(false)
            }, 400)
        }
    }

    const setRefresh = (state) => {
        setRefreshState(state)
    }

    useEffect(() => {
        if (!refreshState) getAllLocations()
    }, [
        page,
        rowsPerPage,
        refreshState,
        searchDebounced.eng,
        searchDebounced.srb,
    ])

    const actionBodyTemplate = () => (
        <Grid key="edit" container spacing={2}>
            <Grid item xs={6} className={classes.buttonWrapper}>
                <Button name="izmeni" fullWidth className={classes.editButton}>
                    {' '}
                    Измени
                </Button>
            </Grid>
            <Grid item xs={6}>
                <Button
                    name="obrisi"
                    color="secondary"
                    fullWidth
                    className={classes.deleteButton}
                >
                    {' '}
                    Обриши
                </Button>
            </Grid>
        </Grid>
    )

    const rowClass = (rowData) => {
        if (rowData.status === 'Aktivnosti u toku') {
            return { blueRow: true }
        }
        if (rowData.status === 'Aktivnosti završene') {
            return { greenRow: true }
        }
        return { white: true }
    }

    // eslint-disable-next-line
    const downloadFile = async (lokId, ustId) => {
        try {
            const fName = `${ustId}-${lokId}.zip`
            const response = await axios.get(
                `/api/files/tlocrt/${lokId}/${ustId}/${fName}`,
                {
                    responseType: 'blob',
                }
            )
            return download(response.data, fName)
        } catch (error) {
            if (error.response.status === 500) {
                setSeverity('warning')
                setSnackbar({
                    open: true,
                    message: 'На локацији нема тлоцрта',
                })
            }
        }
    }

    const onRowClick = (rowData) => {
        if (
            rowData.originalEvent.target.nodeName === 'TD' ||
            rowData.originalEvent.target.nodeName === 'DIV'
        ) {
            history.push(`/app/klaster/${rowData.data.id}`, {
                from: 'LOKACIJE',
            })
            // console.log('single')
        }
        if (
            rowData.originalEvent.target.nodeName === 'SPAN' &&
            rowData.originalEvent.target.parentNode.name === 'izmeni'
        ) {
            setEditOpen(true)
            setEditId(rowData.data.id)
            // console.log('edit')
        }
        if (
            rowData.originalEvent.target.nodeName === 'SPAN' &&
            rowData.originalEvent.target.parentNode.name === 'obrisi'
        ) {
            setDeleteOpen(true)
            setDeleteId(rowData.data.id)
        }
        if (
            rowData.originalEvent.target.nodeName === 'SPAN' &&
            rowData.originalEvent.target.parentNode.name === 'ugovori'
        ) {
            history.push(
                `/app/klaster/povezivanje-sa-ugovorom/${rowData.data.id}`
            )
        }
        if (rowData.originalEvent.target.dataset.name === 'download') {
            downloadFile(rowData.data.lokId, rowData.data.ustId)
        }
    }

    const setCreateModal = (state) => {
        setCreateOpen(state)
    }

    const setEditModal = (state) => {
        setEditOpen(state)
    }

    const setDeleteModal = (state) => {
        setDeleteOpen(state)
    }

    const handleAddLocation = () => {
        setCreateOpen(true)
    }

    const setSeverityHandler = (state) => {
        setSeverity(state)
    }

    const setSnackbarHandler = (state) => {
        setSnackbar(state)
    }

    return (
        <div className={classes.root}>
            <Snackbar
                open={snackbar.open}
                onClose={handleSnackBarOpen}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
                key="key"
            >
                <Alert onClose={handleSnackBarOpen} severity={severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            {createOpen ? (
                <CreateLocation
                    modalOpen={createOpen}
                    setModal={setCreateModal}
                    setRefresh={setRefresh}
                    setSeverityHandler={setSeverityHandler}
                    setSnackbarHandler={setSnackbarHandler}
                />
            ) : null}
            {editOpen && editId.length > 0 ? (
                <EditLocation
                    id={editId}
                    modalOpen={editOpen}
                    setModal={setEditModal}
                    setRefresh={setRefresh}
                    setSeverityHandler={setSeverityHandler}
                    setSnackbarHandler={setSnackbarHandler}
                />
            ) : null}
            {deleteOpen && deleteId.length > 0 ? (
                <DeleteLocation
                    id={deleteId}
                    modalOpen={deleteOpen}
                    setModal={setDeleteModal}
                    setRefresh={setRefresh}
                    setSeverityHandler={setSeverityHandler}
                    setSnackbarHandler={setSnackbarHandler}
                />
            ) : null}

            <div className={classes.table}>
                <Box className={classes.buttonW}>
                    <AddButton
                        label="НАПРАВИ НОВУ ЛОКАЦИЈУ"
                        click={handleAddLocation}
                    />
                </Box>
                {load ? (
                    <SimpleModal />
                ) : (
                    <AdminTable
                        model="location"
                        rowClass={rowClass}
                        data={locations}
                        title="Локације"
                        totalResults={totalResults}
                        page={page}
                        setPage={setPage}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        name="iktLocationTable"
                        onRowClick={onRowClick}
                        tableHeaders={[
                            {
                                field: 'ustanova_ID',
                                header: 'ИД установе',
                            },
                            { field: 'lokacija_ID', header: 'ИД локације' },
                            { field: 'naziv', header: 'Назив установе' },
                            { field: 'adresa', header: 'Адреса локације' },
                            {
                                field: 'postanskiBroj',
                                header: 'Поштански број',
                            },
                            { field: 'naselje', header: 'Насеље' },
                            { field: 'opstina', header: 'Општина' },
                            { field: 'ugovor', header: 'Уговор' },
                            { field: 'prioritet', header: 'Приоритет' },
                            {
                                field: 'izvodjacRadova',
                                header: 'Извођач радова',
                            },
                            { field: 'tlocrt', header: 'Тлоцрт' },
                            { field: 'edit', header: '' },
                        ]}
                        setSearch={setSearch}
                        search={search}
                        actionBodyTemplate={actionBodyTemplate}
                        editColumnwidth={300}
                        searchColumns={[
                            { datavalue: 'naziv', label: 'Назив' },
                            { datavalue: 'prioritet', label: 'Приоритет' },
                            { datavalue: 'odgovoran', label: 'Одговоран' },
                            { datavalue: 'status', label: 'Статус' },
                        ]}
                        withImport
                        sortField=""
                        exportFileName="Локације"
                    />
                )}
            </div>
            <InfoPanel />
        </div>
    )
}

export default AllLocations
