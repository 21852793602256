import {
    Button,
    CircularProgress,
    Grid,
    makeStyles,
    Paper,
    Tooltip,
    Typography,
} from '@material-ui/core'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import download from 'downloadjs'
import { useSelector } from 'react-redux'
import React, { useState } from 'react'
import PropTypes, { objectOf } from 'prop-types'
import { trigger } from 'swr'
import { axiosAuth } from '../../../util/axios-instance'

const ActivitiFilesCard = ({ dok, activity }) => {
    const [isSubmitting, setIsSubmiting] = useState(false)
    // const [contract, setContract] = useState('')
    const userInfo = useSelector((state) => state.myrole)

    const useStyles = makeStyles(() => ({
        docDiv: {
            height: '3.5rem',
            alignItems: 'center',
            justifyContent: 'flex-start',
            margin: '10px 0',
        },

        downloadButton: {
            backgroundColor: isSubmitting ? '#00395a' : '#1E8637',
            height: '3.5rem',

            color: '#fff',
            '&:hover': {
                backgroundColor: '#4a9e5e',
                color: '#fff',
            },
            '&:disabled': {
                color: '#fff',
            },
        },

        deleteButtonWrapper: {
            position: 'relative',
            height: '100%',
        },
        deleteButton: {
            position: 'absolute',
            color: '#a52408',
            top: -5,
            left: 5,
            '&:hover': {
                color: '#ffff',
                backgroundColor: '#f36344',
                borderRadius: '50%',
                cursor: 'pointer',
            },
            fontSize: '1.5rem',
        },
        fileName: {
            width: '100%',

            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            display: '-webkit-box',
            WebkitLineClamp: 1,
            WebkitBoxOrient: 'vertical',
        },
    }))

    const classes = useStyles()
    const getFilesUrl = (activityToCheck, fName) => {
        if (activityToCheck.kratakNaziv === 'A1')
            return `/api/files/tlocrt/${activityToCheck.lokacija.old_ID}/${activityToCheck.lokacija.ustanova}/${dok}`
        if (activityToCheck.kratakNaziv === 'A2')
            return `/api/files/contract/${activityToCheck.lokacija?.ugovor.old_ID}/${fName}`
        return `/api/files/activity/${activityToCheck.lokacija.old_ID}/${activityToCheck.kratakNaziv}/${dok}`
    }
    const url =
        activity.kratakNaziv === 'A1'
            ? `/api/files/tlocrt/${activity.lokacija.old_ID}/${activity.lokacija.ustanova}/${dok}`
            : `/api/files/activity/${activity.lokacija.old_ID}/${activity.kratakNaziv}/${dok}`

    /* eslint-disable */
    const downloadFile = async (fName) => {
        try {
            const response = await axiosAuth.get(getFilesUrl(activity, fName), {
                responseType: 'blob',
            })

            return download(response.data, fName)
        } catch (error) {
            console.log(error.response)
        }
    }

    const handleDeleteFile = async () => {
        try {
            setIsSubmiting(true)
            await axiosAuth.delete(url)

            trigger(
                activity.kratakNaziv === 'A1'
                    ? `/api/files/get/all/files/tlocrt/${activity.lokacija.old_ID}/${activity.lokacija.ustanova}`
                    : `/api/files/get/all/files/activity/${activity.lokacija.old_ID}/${activity.kratakNaziv}`
            )
            setTimeout(() => {
                setIsSubmiting(false)
            }, 1000)
        } catch (error) {
            setIsSubmiting(false)
            console.log(error)
        }
    }

    return (
        <Grid container className={classes.docDiv} key={dok}>
            <Grid item xs={12}>
                <Paper>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item xs={9} className={classes.fileName}>
                            <Tooltip title={dok}>
                                <Typography
                                    style={{
                                        textAlign: 'left',
                                        marginLeft: '10px',
                                    }}
                                >
                                    {dok}
                                </Typography>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={3}>
                            <Button
                                className={classes.downloadButton}
                                disabled={isSubmitting}
                                startIcon={
                                    isSubmitting ? (
                                        <CircularProgress size="0.9rem" />
                                    ) : undefined
                                }
                                fullWidth
                                onClick={() => {
                                    downloadFile(dok)
                                }}
                                // endIcon={
                                //     <GetAppOutlinedIcon />
                                // }
                            >
                                {isSubmitting ? 'Брисање...' : 'Преузми'}
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            {activity.status !== 'Završena' &&
                activity.kratakNaziv !== 'A2' &&
                userInfo.user.rola === 'Zaposleni' &&
                (activity.returned === 0 ||
                    dok.includes(`revizija=${activity.returned}`)) && (
                    <Grid item className={classes.deleteButtonWrapper}>
                        <HighlightOffIcon
                            className={classes.deleteButton}
                            onClick={() => {
                                handleDeleteFile()
                            }}
                        />
                    </Grid>
                )}
            {/* </div> */}
        </Grid>
    )
}

export default ActivitiFilesCard

ActivitiFilesCard.propTypes = {
    activity: objectOf(PropTypes.any).isRequired,
    dok: PropTypes.string.isRequired,
}
