import React, { useEffect, useState } from 'react'
import MomentUtils from '@date-io/moment'

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import { useField, useFormikContext } from 'formik'
import PropTypes from 'prop-types'
import 'moment/locale/sr-cyrl'
import moment from 'moment'

moment.locale('sr-cyrl')
const DatePickerWrapper = ({ name, currentValue, ...otherProps }) => {
    const { setFieldValue } = useFormikContext()
    const [field, meta] = useField(name)
    // const classes = useStyles()
    const [selectedDate, setSelectedDate] = useState(currentValue || new Date())

    const handleDateChange = (date) => {
        setSelectedDate(date)
    }
    const configTextfield = {
        ...field,
        ...otherProps,
        fullWidth: true,
    }

    if (meta && meta.touched && meta.error) {
        configTextfield.error = true
        configTextfield.helperText = meta.error
    }

    useEffect(() => {
        if (name && selectedDate) {
            // const formatedDate = moment(selectedDate).format('D/MMMM/YYYY')

            setFieldValue(name, selectedDate)
        }
    }, [name, selectedDate, setFieldValue])

    return (
        <MuiPickersUtilsProvider utils={MomentUtils} locale="sr-cyrl">
            <DatePicker
                variant="inline"
                format="D/MMMM/YYYY"
                margin="normal"
                id="date-picker-inline"
                label="Датум обављања анкете"
                value={selectedDate}
                autoOk
                onChange={handleDateChange}
            />
        </MuiPickersUtilsProvider>
    )
}

DatePickerWrapper.propTypes = {
    name: PropTypes.string.isRequired,
    currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    // value: PropTypes.string,
}

DatePickerWrapper.defaultProps = {
    currentValue: '',
}

export default DatePickerWrapper
