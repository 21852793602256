import React from 'react'
import AddIcon from '@material-ui/icons/Add'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

const useStyles = makeStyles(() => ({
    icon: {
        marginLeft: 5,
    },
    flex: {
        display: 'flex',
        justifyContent: 'flex-start',
    },

    createButton: {
        backgroundColor: '#ba000d',
        color: 'white',
        '&:hover': {
            backgroundColor: '#d9534f',
        },
        width: '180px',
        fontWeight: 700,
        marginBottom: '40px',
    },
    pageContainer: {
        margin: '0px 0',
    },
    relative: {
        position: 'relative',
    },
    tableDiv: {
        width: '85%',
        margin: '1rem auto',
        marginBottom: '2rem',
    },
    h4: {
        textAlign: 'left',
        marginLeft: '7.5%',
    },
    addBtnDiv: {
        display: 'flex',
        flexDirection: 'flex-start',
        paddingLeft: '7.5%',
        marginBottom: '10px',
    },
    addBtn: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        minWidth: '64px',
        backgroundColor: '#417AB4',
        color: 'white',
        boxShadow:
            '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        padding: '6px 16px',
        outline: '0',
        border: '0',
        cursor: 'pointer',
        fontFamily: 'Roboto',
        fontSize: '0.75rem',
        borderRadius: '5px',
        transition: '250ms ease-in',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: '#125386',
        },
    },
}))

const Networks = ({
    networks,
    onRowClick,
    actionBodyTemplate,
    handleOpenAddModal,
}) => {
    const classes = useStyles()

    return (
        <>
            <h3 className={classes.h4}>Амрес</h3>
            <div className={classes.addBtnDiv}>
                <button
                    onClick={handleOpenAddModal}
                    type="button"
                    value="мрежа"
                    className={classes.addBtn}
                >
                    Направи Амрес
                    <AddIcon />
                </button>
            </div>
            <div className={classes.tableDiv}>
                <DataTable
                    value={networks}
                    onRowClick={onRowClick}
                    showGridlines
                    className="blackTable"
                    emptyMessage="Нема пронађених података."
                >
                    <Column field="naziv" header="Назив" />
                    <Column
                        field={actionBodyTemplate}
                        header="Управљање"
                        style={{ width: '500px' }}
                    />
                </DataTable>
            </div>
        </>
    )
}

export default Networks

Networks.propTypes = {
    networks: PropTypes.arrayOf(PropTypes.any).isRequired,
    actionBodyTemplate: PropTypes.func.isRequired,
    onRowClick: PropTypes.func.isRequired,
    handleOpenAddModal: PropTypes.func.isRequired,
}
