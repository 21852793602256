import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import PropTypes from 'prop-types'
import { Button, CircularProgress, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import TextfieldWrapper from '../../components/FormsUI/Textfield/TextfieldWrapper'
import ButtonWrapper from '../../components/FormsUI/Button/ButtonWrapper'
// import SimpleModal from '../../components/Modal/Modal'
// import SelectWrapper from '../../components/FormsUI/Select/SelectWrapper'
import { axiosAuth as axios } from '../../util/axios-instance'
import CheckboxWrapper from '../../components/FormsUI/Checkbox/CheckboxWrapper'
import TypeSelectWrapper from '../../components/FormsUI/Select/TypeSelectWrapper'

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    }
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    title: {
        textAlign: 'center',
    },
    formField: {
        padding: 10,
    },
    dateDiv: {
        width: '100%',
        padding: 10,
    },
    removeIcon: {
        cursor: 'pointer',
        color: 'red',
        fontSize: 20,
        margin: '0 0 0 10px',
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'column',
    },
}))

const FORM_VALIDATION = Yup.object().shape({
    nadredjena: Yup.string().nullable(true),
    naziv: Yup.string().required('Поље је обавезно'),
    kratakNaziv: Yup.string().required('Поље је обавезно'),
    tip: Yup.string()
        .typeError('Поље је обавезно')
        .required('Поље је обавезно'),
    svi: Yup.bool(),
})

export default function CreateGroup({
    modalOpen,
    setModal,
    setRefresh,
    setSeverityHandler,
    setSnackbarHandler,
}) {
    const classes = useStyles()
    const [modalStyle] = React.useState(getModalStyle)
    const [groups, setGroups] = useState([])
    const [types, setTypes] = useState([])
    const [tipNadredjene, setTipNadredjene] = useState('')
    const [type, setType] = useState('')
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
    })
    const [severity, setSeverity] = useState('info')

    const handleSnackBarOpen = () => {
        setSnackbar((previous) => ({
            ...previous,
            open: false,
        }))
    }

    const createGroup = async (values) => {
        try {
            setRefresh(true)
            await axios.post('/api/group/create', {
                nadredjenaId: values.nadredjena,
                naziv: values.naziv,
                kratakNaziv: values.kratakNaziv,
                tip: values.tip,
                sveVideci: values.svi,
            })
            setModal(false)
            setSeverityHandler('success')
            setSnackbarHandler({
                open: true,
                message: 'Успешно сте направили групу.',
            })
            setRefresh(false)
        } catch (error) {
            setSeverity('warning')
            setSnackbar({
                open: true,
                message: error?.response?.data?.message
                    ? error.response.data.message
                    : 'Креирање групе неуспешно.',
            })
        }
    }

    const getAllGroups = async () => {
        try {
            const response = await axios.get('/api/group?limit=5000')
            setGroups(response.data.groups)
        } catch (error) {
            console.log(error)
        }
    }

    const getAllTypes = async () => {
        try {
            const response = await axios.get('/api/types')
            setTypes(response.data.types)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getAllGroups()
        getAllTypes()
    }, [])

    const body = (
        <>
            <Snackbar
                open={snackbar.open}
                onClose={handleSnackBarOpen}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
                key="key"
            >
                <Alert onClose={handleSnackBarOpen} severity={severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            <div style={modalStyle} className={classes.paper}>
                <h2 className={classes.title}>Нова група</h2>
                {/* {groups.length > 0 ? ( */}
                <Formik
                    initialValues={{
                        nadredjena: null,
                        naziv: '',
                        kratakNaziv: '',
                        tip: '',
                        svi: false,
                    }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values) => createGroup(values)}
                >
                    {({ isSubmitting, setFieldValue, values }) => (
                        <Form>
                            <div className={classes.formDiv}>
                                <div className={classes.tableRow}>
                                    <span className={classes.tableRowLabel}>
                                        Надређена група
                                    </span>
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Select
                                            native
                                            onChange={(event) => {
                                                setFieldValue(
                                                    'nadredjena',
                                                    event.target.value
                                                )
                                                setTipNadredjene(
                                                    event.target.selectedOptions[0].getAttribute(
                                                        'tip'
                                                    )
                                                )
                                                setFieldValue(
                                                    'tip',
                                                    event.target.selectedOptions[0].getAttribute(
                                                        'tip'
                                                    )
                                                )
                                                setType(
                                                    event.target.selectedOptions[0].getAttribute(
                                                        'tip'
                                                    )
                                                )
                                            }}
                                            inputProps={{
                                                name: 'age',
                                                id: 'age-native-simple',
                                            }}
                                        >
                                            <option
                                                aria-label="None"
                                                value=""
                                                tip=""
                                            >
                                                Изаберите надређену групу
                                            </option>
                                            {groups
                                                .filter(
                                                    (el) =>
                                                        el.naziv !== 'Админ' &&
                                                        el.naziv !== 'Admin'
                                                )
                                                .map((group) => (
                                                    <option
                                                        key={group._id}
                                                        value={group._id}
                                                        tip={group.tip}
                                                    >
                                                        {group.naziv}
                                                    </option>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className={classes.tableRow}>
                                    <span className={classes.tableRowLabel}>
                                        Назив групе
                                    </span>
                                    <TextfieldWrapper name="naziv" />
                                </div>
                                <div className={classes.tableRow}>
                                    <span className={classes.tableRowLabel}>
                                        Кратак назив групе
                                    </span>
                                    <TextfieldWrapper name="kratakNaziv" />
                                </div>
                                <div className={classes.tableRow}>
                                    <span className={classes.tableRowLabel}>
                                        Тип
                                    </span>
                                    {/* <FormControl
                                        className={classes.formControl}
                                        disabled={tipNadredjene !== ''}
                                        required
                                    > */}
                                    {/* <Select
                                            native
                                            onChange={(event) => {
                                                setFieldValue(
                                                    'tip',
                                                    event.target.value
                                                )
                                                setType(event.target.value)
                                            }}
                                            inputProps={{
                                                name: 'age',
                                                id: 'age-native-simple',
                                            }}
                                            value={type}
                                        >
                                            <option aria-label="None" value="">
                                                Изаберите тип
                                            </option>
                                            {types
                                                .filter(
                                                    (t) => t.naziv !== 'Admin'
                                                )
                                                .map((t) => (
                                                    <option
                                                        value={t.naziv}
                                                        key={t._id}
                                                    >
                                                        {t.cirilica}
                                                    </option>
                                                ))}
                                        </Select> */}
                                    <TypeSelectWrapper
                                        name="tip"
                                        label="Изаберите тип"
                                        currentValue={type}
                                        options={types}
                                        onChange={(event) => {
                                            setFieldValue(
                                                'tip',
                                                event.target.value
                                            )
                                            setType(event.target.value)
                                        }}
                                        disabled={tipNadredjene !== ''}
                                    />
                                    {/* </FormControl> */}
                                </div>

                                <CheckboxWrapper
                                    name="svi"
                                    label="Види све локације"
                                    disabled={tipNadredjene !== ''}
                                    currentValue={values.svi}
                                />

                                <ButtonWrapper
                                    type="submit"
                                    fullWidth
                                    disabled={isSubmitting}
                                    startIcon={
                                        isSubmitting ? (
                                            <CircularProgress size="0.9rem" />
                                        ) : undefined
                                    }
                                    variant="contained"
                                    style={{
                                        // backgroundColor: '#337ab7',
                                        // color: 'white',
                                        // height: 'unset',
                                        // fontSize: '0.75rem',
                                        // // padding: '8px 12px',
                                        // fontWeight: 'bold',
                                        margin: '10px 0',
                                    }}
                                >
                                    Направи групу
                                </ButtonWrapper>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    fullWidth
                                    onClick={() => setModal(false)}
                                >
                                    Одустани
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
                {/* ) : (
                    <SimpleModal />
                )} */}
            </div>
        </>
    )

    return (
        <div>
            <Modal
                open={modalOpen}
                onClose={() => setModal(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </div>
    )
}

CreateGroup.propTypes = {
    modalOpen: PropTypes.bool,
    setModal: PropTypes.func,
    setRefresh: PropTypes.func,
    setSeverityHandler: PropTypes.func,
    setSnackbarHandler: PropTypes.func,
}

CreateGroup.defaultProps = {
    modalOpen: false,
    setModal: () => {},
    setRefresh: () => {},
    setSeverityHandler: () => {},
    setSnackbarHandler: () => {},
}
