import React from 'react'
import { Formik, Form } from 'formik'

import * as Yup from 'yup'
import { Button, Grid, makeStyles, CircularProgress } from '@material-ui/core'

import PropTypes from 'prop-types'

import { useParams } from 'react-router-dom'
import { trigger } from 'swr'
import { axiosAuth } from '../../../util/axios-instance'
import TextfieldWrapper from '../../../components/FormsUI/Textfield/TextfieldWrapper'

const useStyles = makeStyles(() => ({
    addAll: {
        marginTop: 30,
    },
}))

const A54FirstStep = ({
    setSeverity,
    setSnackbar,
    setError,
    handleNext,
    activity,
}) => {
    const classes = useStyles()
    const { id } = useParams()

    const INITIAL_FORM_STATE = {
        naziv: 'Цена радова и опреме',
        vrednost: '',
    }

    const FORM_VALIDATION = Yup.object().shape({
        naziv: Yup.string(),
        vrednost: Yup.number()
            .typeError('Морате унети број')
            .required('Унесите цену радова и опреме'),
    })

    const submitAdditionalData = async (values) => {
        try {
            await axiosAuth.patch(`/api/activity/${id}`, {
                podaci: values,
            })

            // console.log(data)
            setSeverity('success')
            setSnackbar({
                open: true,
                message: 'Успешно унесена цена радова и опреме.',
            })
            trigger(`/api/activity/${activity._id}`)
            handleNext()
        } catch (error) {
            setSeverity('warning')
            setSnackbar({
                open: true,
                message: 'Цена радова и опреме неуспешно додата!',
            })
            setError(error)
        }
    }
    return (
        <Grid container justifyContent="center">
            {/* <Paper className={classes.add} elevation={0}> */}
            <Formik
                initialValues={INITIAL_FORM_STATE}
                validationSchema={FORM_VALIDATION}
                onSubmit={submitAdditionalData}
            >
                {({ isSubmitting }) => (
                    <Form autoComplete="off">
                        <Grid container justifyContent="flex-start">
                            <Grid item xs={12}>
                                <TextfieldWrapper
                                    name="vrednost"
                                    label="Цена радова и опреме"
                                    variant="outlined"
                                    value={
                                        activity.podaci.length > 0 &&
                                        activity.podaci[0].vrednost !== 'NULL'
                                            ? activity.podaci[0].vrednost
                                            : ''
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    className={classes.addAll}
                                    disabled={isSubmitting}
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    startIcon={
                                        isSubmitting ? (
                                            <CircularProgress size="0.9rem" />
                                        ) : undefined
                                    }
                                >
                                    {isSubmitting ? 'Слање...' : 'Сачувај'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
            {/* </Paper> */}
        </Grid>
    )
}

export default A54FirstStep

A54FirstStep.propTypes = {
    setSeverity: PropTypes.func.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    setError: PropTypes.func.isRequired,
    handleNext: PropTypes.func.isRequired,
    activity: PropTypes.objectOf(PropTypes.any).isRequired,
}
