import React from 'react'
import { useFormikContext } from 'formik'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import TextfieldWrapper from '../../../components/FormsUI/Textfield/TextfieldWrapper'

/* eslint-disable */

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 800,
        height: 'auto',
        maxHeight: '90%',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        overflow: 'auto',
    },
    title: {
        textAlign: 'center',
    },
    formField: {
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
    },
    dateDiv: {
        width: '100%',
        padding: 10,
    },
}))

const Step4 = () => {
    const classes = useStyles()
    const { values, setFieldValue } = useFormikContext()

    return (
        <div>
            <div className={classes.formField}>
                <FormControl fullWidth className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">
                        Тип школе
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values.tipSkole}
                        label="Тип школе"
                        onChange={(event) => {
                            setFieldValue('tipSkole', event.target.value)
                            // setType(event.target.value)
                        }}
                    >
                        <MenuItem value="A">А</MenuItem>
                        <MenuItem value="B">Б</MenuItem>
                        <MenuItem value="C">Ц</MenuItem>
                        <MenuItem value="D">Д</MenuItem>
                        <MenuItem value="E">Е</MenuItem>
                        <MenuItem value="F">Ф</MenuItem>
                    </Select>
                </FormControl>
            </div>
            {/* 
                <span className={classes.tableRowLabel}>Тип школе</span>
                <TextfieldWrapper name="tipSkole" value={values.tipSkole} />
            */}
            <div className={classes.formField}>
                <span className={classes.tableRowLabel}>Број смена</span>
                <TextfieldWrapper name="brojSmena" value={values.brojSmena} />
            </div>
            <div className={classes.formField}>
                <span className={classes.tableRowLabel}>Дели везу</span>
                <FormControl component="fieldset">
                    <RadioGroup
                        name="deliVezu"
                        value={values.deliVezu}
                        onChange={(event) => {
                            setFieldValue('deliVezu', event.target.value)
                        }}
                    >
                        <FormControlLabel
                            value="true"
                            control={<Radio />}
                            label="Да"
                        />
                        <FormControlLabel
                            value="false"
                            control={<Radio />}
                            label="Не"
                        />
                    </RadioGroup>
                </FormControl>
            </div>
            <div className={classes.formField}>
                <span className={classes.tableRowLabel}>Домаћин</span>
                <TextfieldWrapper name="domacin" value={values.domacin} />
            </div>
            <div className={classes.formField}>
                <span className={classes.tableRowLabel}>Гост</span>
                <TextfieldWrapper name="gost" value={values.gost} />
            </div>
            <div className={classes.formField}>
                <span className={classes.tableRowLabel}>Укупно ученика</span>
                <TextfieldWrapper
                    name="ukupnoUcenika"
                    value={values.ukupnoUcenika}
                />
            </div>
        </div>
    )
}
// Step4.propTypes = {
//     contracts: PropTypes.arrayOf(PropTypes.any),
//     groups: PropTypes.arrayOf(PropTypes.any),
//     priorities: PropTypes.arrayOf(PropTypes.any),
// }

// Step4.defaultProps = {
//     contracts: [],
//     groups: [],
//     priorities: [],
// }

export default Step4
