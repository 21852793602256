import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import { useDebounce } from 'use-debounce'
import { axiosAuth as axios } from '../../../util/axios-instance'
import daysToDeadline from '../../../util/daysToDeadline'
import UserTable from '../../../components/tables/UserTable'
import SimpleModal from '../../../components/Modal/Modal'
import latToCyr from '../../../util/latToCyr'

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    createbutton: {
        padding: 8,
        margin: '20px 0px',
    },
    root: {
        margin: 12,
        textAlign: 'left',
    },
    table: {
        margin: 8,
    },
    editButton: {
        backgroundColor: '#059D2A',
        color: 'white',
        '&:hover': {
            background: '#059D2A',
        },
    },
    deleteButton: {
        backgroundColor: '#D86565',
        color: 'white',
        '&:hover': {
            background: '#D86565',
        },
    },
    buttonW: {
        margin: '1rem 0',
    },
}))

const ReportInProgress = () => {
    const classes = useStyles()
    const history = useHistory()
    const [actions, setActions] = useState('')
    const [page, setPage] = useState(0)
    // eslint-disable-next-line
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [load, setLoad] = useState(false)
    const [search, setSearch] = useState({ eng: '', key: '', srb: '' })
    const [searchNotification] = useDebounce(search, 2000)

    const getNotification = async () => {
        try {
            setLoad(true)
            const url =
                searchNotification.eng === ''
                    ? `/api/activity/notifications?page=${
                          page + 1
                      }&limit=${rowsPerPage}`
                    : `/api/search/notification?eng=${
                          searchNotification.eng
                      }&srb=${searchNotification.srb}&page=${
                          page + 1
                      }&limit=${rowsPerPage}`
            const { data } = await axios.get(url)
            // console.log('REAL DATA', data)

            const svevidece = await axios.get('/api/group/sve-videci/true')
            const svevideceArr = []
            svevidece.data.groups.map((el) => svevideceArr.push(el))

            const dateFormat = 'YYYY/DD/MM'

            const tableData = data?.activities?.reduce((acc, currentEl) => {
                // console.log(currentEl.odgovoran)
                // const isMyGroupType = currentEl.odgovoran.find(
                //     (el) => el === myGroupType.naziv
                // )

                // const startDateValid = moment(
                //     currentEl.datumPocetka
                // ).isValid()

                // const endDateValid = moment(
                //     currentEl.rokZavrsetka,
                //     moment.ISO_8601,
                //     true
                // ).isValid()

                const startDate = moment(currentEl.datumPocetka).format(
                    dateFormat
                )

                // const groupReliable = [
                //     ...currentEl.lokacija.odgovoran,
                //     ...svevideceArr,
                // ]
                //     .filter((el) => el.tip.includes('Izvođač'))
                //     .map((el) => el.naziv)
                //     .join(', ')

                // console.log(
                //     'SVE FIRME ZA AKTIVOST',
                //     [...currentEl.lokacija.odgovoran, ...svevideceArr]
                //         .filter((el) => el.tip.includes('Izvođač'))
                //         .map((el) => el.naziv)
                //         .join(', ')
                // )
                const groupArray = currentEl?.odgovoran
                    ?.map((el) => el)
                    .join(', ')
                const rokZavrsetka =
                    currentEl.rok === null
                        ? 'Неодређен'
                        : moment(currentEl?.rokZavrsetka).format('YYYY/DD/MM')
                const rokZavrsetkaNeformatiran = currentEl.rok
                    ? moment(currentEl?.datumPocetka).add(currentEl.rok, 'd')
                    : 'клизно'
                // const rokZavr = moment(currentEl.rok).format('DD/MM/YYYY')
                // const odgNazivi = [
                //     ...currentEl.lokacija.odgovoran,
                //     ...svevideceArr,
                // ]
                //     .filter((el) => groupArray.includes(el.cirilica))
                //     .map(
                //         (el) =>
                //             `${el.naziv} ${
                //                 el.nadredjenaGrupa
                //                     ? // prettier-ignore
                //                       `(${el.nadredjenaGrupa.naziv})`
                //                     : ''
                //             }`
                //     )
                //     .join(', ')

                const addElement = {
                    lokId: currentEl?.lokacija?.lokacija_ID
                        ? latToCyr(currentEl.lokacija.lokacija_ID.toUpperCase())
                        : '',
                    ustId: currentEl?.lokacija?.ustanova_ID
                        ? latToCyr(currentEl.lokacija.ustanova_ID.toUpperCase())
                        : '',
                    id: currentEl?._id,
                    lokacija: currentEl?.lokacija?.naziv,
                    aktivnost: currentEl?.kratakNaziv,
                    odgovoran: groupArray,
                    // nazivOdgGrupe: odgNazivi,
                    datumPocetka:
                        startDate === 'Invalid date'
                            ? 'Нема датум почетка'
                            : startDate,
                    datumZavrsetka: rokZavrsetka,
                    status: latToCyr(currentEl?.status),
                    brojDanaDoRoka: currentEl?.brojDanaDoRoka,
                    rok: currentEl?.rok,
                    datumZavrsetkaNeformatiran: rokZavrsetkaNeformatiran,
                }

                acc.push(addElement)
                return acc
            }, [])

            setActions({ totalResults: data.totalResults, actions: tableData })

            // const dataLength = parseInt(data.totalResults, 10)
            // setTotalResults(dataLength)
            setLoad(false)
        } catch (error) {
            console.log(error)
            setLoad(false)
        }
    }

    useEffect(() => {
        getNotification()
    }, [page, rowsPerPage, searchNotification.eng])
    // const dateFormat = 'DD/MM/YYYY'
    // const tableData =
    //
    //     data?.activities.reduce((acc, currentEl) => {
    //         const startDate = moment(currentEl.datumPocetka).format(dateFormat)
    //         const groupArray = currentEl.odgovoran?.map((el) => el).join(', ')

    //         const rokZavr =
    //             currentEl.rok === 'клизно'
    //                 ? 'клизно'
    //                 : moment(currentEl.rokZavrsetka).format('DD/MM/YYYY')

    //         const addElement = {
    //             id: currentEl._id,
    //             lokacija: currentEl.lokacija.naziv,
    //             aktivnost: currentEl.kratakNaziv,
    //             odgovoran: groupArray,
    //             datumPocetka: startDate,
    //             datumZavrsetka:
    //                 currentEl.status === 'Nije započeta'
    //                     ? 'Незапочета активност'
    //                     : rokZavr,
    //             status: latToCyr(currentEl.status),
    //             brojDanaDoRoka: currentEl.brojDanaDoRoka,
    //             rok: currentEl.rok,
    //         }

    //         acc.push(addElement)
    //         return acc
    //     }, [])

    //     const groupArray = currentEl.odgovoran?.map((el) => el).join(', ')

    //     const addElement = {
    //         aktivnost: `${currentEl.kratakNaziv} - ${currentEl.naziv}`,
    //         lokacija: currentEl?.lokacija?.naziv,
    //         odgovoran: groupArray,
    //         status: currentEl.status,
    //         brojDanaDoRoka: currentEl.brojDanaDoRoka,
    //         rok: currentEl.rok,
    //         datumZavrsetka: currentEl.rokZavrsetka,
    //     }
    //     acc.push(addElement)
    //     return acc
    // }, [])

    const rowClass = (rowData) => {
        // const today = moment(new Date())
        // const endDate = moment(rowData.datumZavrsetka).format('MM/DD/YYYY')

        // const duration = moment.duration(endDate.diff(today))
        // const tillEndDate = duration.asDays()
        // var m = moment("2011-10-10T10:20:90");
        // m.isValid(); // false
        // const tenPercentToDeadLine = (rowData.brojDanaDoRoka * 1) / rowData.rok
        // // console.log(tenPercentToDeadLine)
        // if (
        //     (rowData.brojDanaDoRoka * 1 > 0 && tenPercentToDeadLine > 0.1) ||
        //     rowData.datumZavrsetka === 'клизно'
        // )
        //     return { blueRow: true }
        // if (rowData.brojDanaDoRoka * 1 >= 0 && tenPercentToDeadLine < 0.1) {
        //     return { orangeRow: true }
        // }
        // if (rowData.brojDanaDoRoka * 1 < 0) {
        //     return { redRow: true }
        // }
        // console.log(rowData)
        // return { white: true }
        const daysLeft = daysToDeadline(
            new Date(),
            rowData.datumZavrsetkaNeformatiran
        )

        const tenPercentToDeadLine = (daysLeft * 1) / rowData.rok

        if (
            (daysLeft * 1 > 0 && tenPercentToDeadLine > 0.1) ||
            rowData.datumZavrsetka === 'клизно'
        )
            return { blueRow: true }
        if (daysLeft * 1 >= 0 && tenPercentToDeadLine < 0.1) {
            return { orangeRow: true }
        }
        if (daysLeft * 1 < 0) {
            return { redRow: true }
        }
        if (
            rowData.status === 'У току' &&
            rowData.datumPocetka === 'Нема датум почетка' &&
            rowData.datumZavrsetka === 'Неодређен'
        ) {
            return { blueRow: true }
        }

        return { white: true }
    }

    const onRowClick = (rowData) => {
        // console.log(rowData.originalEvent.target.dataset.name === 'comments')
        if (
            rowData.originalEvent.target.nodeName === 'TD' ||
            rowData.originalEvent.target.nodeName === 'DIV'
        ) {
            history.push(`/app/aktivnost/${rowData.data.id}`)
        }
    }

    // console.log(actions.actions)
    return (
        <div className={classes.root}>
            <div className={classes.table}>
                {load && <SimpleModal />}

                {actions && (
                    <UserTable
                        model="activity/notifications"
                        rowClass={rowClass}
                        title="Листа активности у току"
                        data={actions?.actions}
                        totalResults={actions?.totalResults}
                        page={page}
                        setPage={setPage}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        onRowClick={onRowClick}
                        name="iktReportprogress"
                        tableHeaders={[
                            {
                                field: 'ustId',
                                header: 'ИД Установе',
                            },
                            {
                                field: 'lokId',
                                header: 'ИД Локације',
                            },
                            { field: 'aktivnost', header: 'Активност' },
                            { field: 'lokacija', header: 'Назив локације' },
                            { field: 'status', header: 'Статус' },
                            {
                                field: 'odgovoran',
                                header: 'Одговоран',
                            },
                            // {
                            //     field: 'nazivOdgGrupe',
                            //     header: 'Одговоран извођач',
                            // },
                            {
                                field: 'datumPocetka',
                                header: 'Датум почетка',
                            },
                            {
                                field: 'datumZavrsetka',
                                header: 'Рок за завршетак',
                            },
                            // { field: 'rok', header: 'Рок' },
                        ]}
                        setSearch={setSearch}
                        search={search}
                        exportFileName="Активности-активне"
                        withSearch
                    />
                )}
            </div>
        </div>
    )
}

export default ReportInProgress

// ReportInProgress.propTypes = {
//     table: PropTypes.objectOf(PropTypes.any).isRequired,
//     load: PropTypes.bool.isRequired,
// }
