import { Autocomplete, createFilterOptions } from '@material-ui/lab'
import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { Button, CircularProgress, Modal, TextField } from '@material-ui/core'
import { axiosAuth as axios } from '../../../../util/axios-instance'
import ButtonWrapper from '../../../../components/FormsUI/Button/ButtonWrapper'

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    }
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 700,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        maxHeight: '90%',
        overflow: 'auto',
    },
    title: {
        textAlign: 'center',
    },
    formField: {
        padding: 10,
    },
}))

const FORM_VALIDATION = Yup.object().shape({})

const AddLocationsToContract = ({
    locations,
    // value,
    modalOpen,
    setModal,
    setRefresh,
    setSeverityHandler,
    setSnackbarHandler,
    contractId,
}) => {
    const classes = useStyles()
    const [modalStyle] = React.useState(getModalStyle)
    const loading = modalOpen && locations.length === 0
    const filterOptions = createFilterOptions({
        stringify: ({ lat, cyr }) => `${lat} ${cyr}`,
    })

    const addLocations = async (values) => {
        try {
            setRefresh(true)
            await axios.patch(`/api/link/location-to-contract/${contractId}`, {
                lokacije: values.lokacije.map((value) => value._id),
            })
            setModal(false)
            setSeverityHandler('success')
            setSnackbarHandler({
                open: true,
                message: 'Успешно додата локација на уговор',
            })
            setRefresh(false)
        } catch (error) {
            console.log(error)
            setSeverityHandler('warning')
            setSnackbarHandler({
                open: true,
                message: 'Додавање локација неуспешно',
            })
        }
    }

    const body = (
        <>
            <div style={modalStyle} className={classes.paper}>
                <h2 className={classes.title}>Додај локације</h2>
                <Formik
                    initialValues={{
                        lokacije: [],
                    }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values) => addLocations(values)}
                >
                    {({ isSubmitting, setFieldValue, values }) => (
                        <Form>
                            <div className={classes.formDiv}>
                                <Autocomplete
                                    multiple
                                    loadingText="Учитавање..."
                                    noOptionsText="Нема пронађених резултата"
                                    id="combo-box-demo"
                                    options={locations}
                                    getOptionLabel={({ nazivLokacije, cyr }) =>
                                        nazivLokacije || cyr
                                    }
                                    filterOptions={filterOptions}
                                    filterSelectedOptions
                                    getOptionSelected={(option, val) =>
                                        option._id === val._id
                                    }
                                    value={values.lokacije}
                                    onChange={(e, value) =>
                                        setFieldValue('lokacije', value)
                                    }
                                    loading={loading}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Локације"
                                            variant="outlined"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {loading &&
                                                        modalOpen ? (
                                                            <CircularProgress
                                                                color="inherit"
                                                                size={20}
                                                            />
                                                        ) : null}
                                                        {
                                                            params.InputProps
                                                                .endAdornment
                                                        }
                                                    </>
                                                ),
                                            }}
                                        />
                                    )}
                                />

                                <ButtonWrapper
                                    type="submit"
                                    fullWidth
                                    disabled={isSubmitting}
                                    startIcon={
                                        isSubmitting ? (
                                            <CircularProgress size="0.9rem" />
                                        ) : undefined
                                    }
                                    variant="contained"
                                    style={{
                                        margin: '10px 0',
                                    }}
                                >
                                    Додај
                                </ButtonWrapper>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    fullWidth
                                    onClick={() => setModal(false)}
                                >
                                    Одустани
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    )

    return (
        <div>
            <Modal
                open={modalOpen}
                onClose={() => setModal(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </div>
    )
}

export default AddLocationsToContract

AddLocationsToContract.propTypes = {
    contractId: PropTypes.string,
    locations: PropTypes.arrayOf(PropTypes.any),
    modalOpen: PropTypes.bool,
    setModal: PropTypes.func,
    setRefresh: PropTypes.func,
    setSeverityHandler: PropTypes.func,
    setSnackbarHandler: PropTypes.func,
}

AddLocationsToContract.defaultProps = {
    locations: [],
    contractId: '',
    modalOpen: false,
    setModal: () => {},
    setRefresh: () => {},
    setSeverityHandler: () => {},
    setSnackbarHandler: () => {},
}
