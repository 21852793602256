import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import { axiosAuth as axios } from '../../util/axios-instance'
import cyrToLat from '../../util/cyrToLat'
import latToCyr from '../../util/latToCyr'
import AutocompleteFilterCard from './AutocompleteFilterCard'

const SummaryFilters = ({ setQueryParams }) => {
    const [state, setState] = useState({
        naselje: {},
        lokacija_ID: {},
        ustanova_ID: {},
        naziv: {},
        opstina: {},
        skolskaUprava: {},
    })

    const [open, setOpen] = useState(false)
    const [open1, setOpen1] = useState(false)
    const [open2, setOpen2] = useState(false)
    const [open3, setOpen3] = useState(false)
    const [open4, setOpen4] = useState(false)
    const [open5, setOpen5] = useState(false)
    const [open6, setOpen6] = useState(false)
    const [options, setOptions] = useState([])
    // const [params, setParams] = useState('')

    const getValues = async (value) => {
        let active = true

        const response = await axios.get(
            `api/location/get/autocomplete?value=${value}`
        )

        if (active) {
            const arr = response.data.values
                .filter((val) => val)
                .map((val) => ({
                    srb: latToCyr(val),
                    eng: cyrToLat(val),
                }))
            setOptions(arr)
        }

        return () => {
            active = false
        }
    }

    useEffect(() => {
        if (!open) {
            setOptions([])
        }
    }, [open])

    useEffect(() => {
        /* eslint-disable */
        let arr = []
        for (const n in state) {
            if (
                state[n].eng &&
                state[n].srb &&
                state[n].srb !== '' &&
                state[n].eng !== ''
            ) {
                if (n === 'lokacija_ID' || n == 'ustanova_ID') {
                    arr.push(`${n}=${state[n].eng}`)
                } else {
                    arr.push(`${n}=${state[n].srb}`)
                }
            }
        }

        if (arr.join('&').length > 0) {
            setQueryParams(`&${arr.join('&')}`)
        } else {
            setQueryParams('')
        }
    }, [state])

    return (
        <Grid container spacing={2} style={{ marginBottom: 10 }}>
            <Grid item xs={2}>
                <AutocompleteFilterCard
                    options={options}
                    getOptionLabel={({ srb, eng }) => srb || ''}
                    value={state.lokacija_ID}
                    onChange={(event, newValue) =>
                        setState((prevState) => ({
                            ...prevState,
                            lokacija_ID: {
                                eng: newValue?.eng,
                                srb: newValue?.srb,
                            },
                        }))
                    }
                    openTest={open2}
                    onOpen={() => {
                        setOpen2(true)
                        setOpen(true)
                        getValues('lokacija_ID')
                    }}
                    onClose={() => {
                        setOpen2(false)
                        setOpen(false)
                        setOptions([])
                    }}
                    label="ИД локације"
                />
            </Grid>
            <Grid item xs={2}>
                <AutocompleteFilterCard
                    options={options}
                    getOptionLabel={({ srb, eng }) => srb || ''}
                    value={state.ustanova_ID}
                    onChange={(event, newValue) =>
                        setState((prevState) => ({
                            ...prevState,
                            ustanova_ID: {
                                eng: newValue?.eng,
                                srb: newValue?.srb,
                            },
                        }))
                    }
                    openTest={open3}
                    onOpen={() => {
                        setOpen3(true)
                        setOpen(true)
                        getValues('ustanova_ID')
                    }}
                    onClose={() => {
                        setOpen3(false)
                        setOpen(false)
                        setOptions([])
                    }}
                    label="ИД установе"
                />
            </Grid>
            <Grid item xs={2}>
                <AutocompleteFilterCard
                    options={options}
                    getOptionLabel={({ srb, eng }) => srb || ''}
                    value={state.naziv}
                    onChange={(event, newValue) =>
                        setState((prevState) => ({
                            ...prevState,
                            naziv: {
                                eng: newValue?.eng,
                                srb: newValue?.srb,
                            },
                        }))
                    }
                    openTest={open4}
                    onOpen={() => {
                        setOpen4(true)
                        setOpen(true)
                        getValues('naziv')
                    }}
                    onClose={() => {
                        setOpen4(false)
                        setOpen(false)
                        setOptions([])
                    }}
                    label="Назив"
                />
            </Grid>
            <Grid item xs={2}>
                <AutocompleteFilterCard
                    options={options}
                    getOptionLabel={({ srb, eng }) => srb || ''}
                    value={state.naselje}
                    onChange={(event, newValue) =>
                        setState((prevState) => ({
                            ...prevState,
                            naselje: {
                                eng: newValue?.eng,
                                srb: newValue?.srb,
                            },
                        }))
                    }
                    openTest={open1}
                    onOpen={() => {
                        setOpen1(true)
                        setOpen(true)
                        getValues('naselje')
                    }}
                    onClose={() => {
                        setOpen1(false)
                        setOpen(false)
                        setOptions([])
                    }}
                    label="Насеље"
                />
            </Grid>
            <Grid item xs={2}>
                <AutocompleteFilterCard
                    options={options}
                    getOptionLabel={({ srb, eng }) => srb || ''}
                    value={state.opstina}
                    onChange={(event, newValue) =>
                        setState((prevState) => ({
                            ...prevState,
                            opstina: {
                                eng: newValue?.eng,
                                srb: newValue?.srb,
                            },
                        }))
                    }
                    openTest={open5}
                    onOpen={() => {
                        setOpen(true)
                        setOpen5(true)
                        getValues('opstina')
                    }}
                    onClose={() => {
                        setOpen(false)
                        setOpen5(false)
                        setOptions([])
                    }}
                    label="Општина"
                />
            </Grid>
            <Grid item xs={2}>
                <AutocompleteFilterCard
                    options={options}
                    getOptionLabel={({ srb, eng }) => srb || ''}
                    value={state.skolskaUprava}
                    onChange={(event, newValue) =>
                        setState((prevState) => ({
                            ...prevState,
                            skolskaUprava: {
                                eng: newValue?.eng,
                                srb: newValue?.srb,
                            },
                        }))
                    }
                    openTest={open6}
                    onOpen={() => {
                        setOpen6(true)
                        setOpen(true)
                        getValues('skolskaUprava')
                    }}
                    onClose={() => {
                        setOpen6(false)
                        setOpen(false)
                        setOptions([])
                    }}
                    label="Школска управа"
                />
            </Grid>
        </Grid>
    )
}

SummaryFilters.propTypes = {
    setQueryParams: PropTypes.func,
}

SummaryFilters.defaultProps = {
    setQueryParams: () => {},
}

export default SummaryFilters
