import React from 'react'
import PropTypes, { objectOf } from 'prop-types'
import { makeStyles, Grid } from '@material-ui/core'
import CommentIcon from '@material-ui/icons/Comment'
import BasicTable from '../../../components/tables/BasicTable'

const useStyles = makeStyles(() => ({
    comment: {
        margin: '3rem 0',
        marginLeft: 10,
    },
    contractTitle: {
        textAlign: 'left',
        margin: '20px 0px',
        fontSize: 25,
        color: '#417AB4',
        display: 'flex',
        alignItems: 'flex-end',
    },
    icon: {
        fontSize: 40,
        color: '#417AB4',
        marginRight: 10,
    },
}))
const ActivityComments = ({ activity }) => {
    const classes = useStyles()
    const filteredComments = activity?.komentari?.map((comment) => ({
        komentar: comment?.komentar,
        email: comment?.user?.email
            ? comment?.user?.email
            : 'Корисник је обрисан',
        ime: comment?.user
            ? `${comment?.user?.ime} ${comment?.user?.prezime}`
            : `Корисник је обрисан`,
    }))
    return (
        <Grid item className={classes.comment} xs={12}>
            <div className={classes.contractTitle}>
                <CommentIcon className={classes.icon} />
                <span>Коментари</span>
            </div>

            <BasicTable
                data={filteredComments}
                name="IktActivityComments"
                commentColumWidth="max-content"
                tableHeaders={[
                    { field: 'ime', header: 'Име' },
                    {
                        field: 'email',
                        header: 'Е-пошта',
                    },
                    {
                        field: 'komentar',
                        header: 'Коментар',
                    },
                ]}
            />
        </Grid>
    )
}

export default ActivityComments

ActivityComments.propTypes = {
    activity: objectOf(PropTypes.any).isRequired,
}
