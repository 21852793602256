import React, { useEffect, useState } from 'react'

import AddIcon from '@material-ui/icons/Add'
import { Grid, makeStyles } from '@material-ui/core'
import PageTitle from '../../components/FormsUI/PageTitle/PageTitle'

import EditBtn from '../../components/FormsUI/Button/EditBtn'
import DeleteBtn from '../../components/FormsUI/Button/DeleteBtn'
import SimpleModal from '../../components/Modal/Modal'
import DynamicModal from './DynamicModal'
import PriorityTable from './PriorityTable'
import GroupTypesTable from './GroupTypesTable'
import { axiosAuth } from '../../util/axios-instance'
import latToCyr from '../../util/latToCyr'
import cyrToLat from '../../util/cyrToLat'
import Networks from './Networks'
import { linkHandler } from './helpers'

const useStyles = makeStyles(() => ({
    icon: {
        marginLeft: 5,
    },
    flex: {
        display: 'flex',
        justifyContent: 'flex-start',
    },

    createButton: {
        backgroundColor: '#ba000d',
        color: 'white',
        '&:hover': {
            backgroundColor: '#d9534f',
        },
        width: '180px',
        fontWeight: 700,
        marginBottom: '40px',
    },
    pageContainer: {
        margin: '0px 0',
    },
    relative: {
        position: 'relative',
    },
    tableDiv: {
        width: '85%',
        margin: '1rem auto',
        marginBottom: '2rem',
    },
    h4: {
        textAlign: 'left',
        marginLeft: '7.5%',
    },
    addBtnDiv: {
        display: 'flex',
        flexDirection: 'flex-start',
        paddingLeft: '7.5%',
        marginBottom: '10px',
    },
    addBtn: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        minWidth: '64px',
        backgroundColor: '#417AB4',
        color: 'white',
        boxShadow:
            '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        padding: '6px 16px',
        outline: '0',
        border: '0',
        cursor: 'pointer',
        fontFamily: 'Roboto',
        fontSize: '0.75rem',
        borderRadius: '5px',
        transition: '250ms ease-in',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: '#125386',
        },
    },
}))

const PrioritiesTypes = () => {
    const classes = useStyles()
    const [priorities, setPriorities] = useState([])
    const [types, setTypes] = useState([])
    const [networks, setNetworks] = useState([])
    const [load, setLoad] = useState(false)
    const [open, setOpen] = useState(false)
    const [selected, setSelected] = useState({})
    const [action, setAction] = useState('')
    const [message, setMessage] = useState('')

    const handleOpenModal = (rowData) => {
        setMessage('')
        setOpen(true)
        setSelected(rowData.data)
    }

    const handleCloseModal = () => {
        setAction('')
        setOpen(false)
        setSelected({})
    }

    const handleOpenAddModal = (e) => {
        setMessage('')
        setAction(e.target.value)
        setSelected({})
        setOpen(true)
    }

    const handleSubmit = async (values) => {
        setMessage('')
        const link = linkHandler(selected, action)

        const valuesObj = values

        if (link === '/api/types/') {
            const validNaziv = cyrToLat(valuesObj.naziv)
            valuesObj.naziv = validNaziv
        }

        if (action === 'izmeni')
            axiosAuth
                .patch(link, valuesObj)
                .then((d) => {
                    setMessage(d.data.message)
                })
                .catch((err) => {
                    if (err.response) {
                        setMessage(err.response.data.message)
                        setTimeout(() => {
                            setMessage('')
                        }, 2000)
                    }
                })
        else
            axiosAuth
                .post(link, valuesObj)
                .then((d) => {
                    setMessage(d.data.message)
                })
                .catch((err) => {
                    if (err.response) {
                        setMessage(err.response.data.message)
                        setTimeout(() => {
                            setMessage('')
                        }, 2000)
                    }
                })

        setTimeout(() => {
            setOpen(false)
            setMessage('')
        }, 2000)
    }

    const handleDelete = async () => {
        const link = linkHandler(selected, action)

        axiosAuth
            .delete(link)
            .then((data) => {
                setMessage(data.data.message)
            })
            .catch((err) => {
                if (err.response) {
                    setMessage(err.response.data.message)
                    setTimeout(() => {
                        setMessage('')
                    }, 2000)
                }
            })

        setTimeout(() => {
            setOpen(false)
        }, 2000)
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoad(true)
                const prior = await axiosAuth.get('/api/priority')

                setPriorities(prior.data.priorities)

                const typ = await axiosAuth.get('/api/types')
                const typesArr = typ.data.types

                typesArr.forEach((t) => {
                    /* eslint-disable */
                    t.naziv = latToCyr(t.naziv)
                })

                const networks = await axiosAuth.get('/api/network')
                setNetworks(networks.data.networks)
                setTypes(typesArr)
                setTimeout(() => setLoad(false), 500)
            } catch (err) {
                console.log(err)
            }
        }
        if (!open) fetchData()
    }, [open])

    const actionBodyTemplate = () => (
        <Grid key="edit" container>
            <Grid item xs={6} className={classes.buttonWrapper}>
                <EditBtn name="izmeni">измени</EditBtn>
            </Grid>
            <Grid item xs={6} className={classes.buttonWrapper}>
                <DeleteBtn name="obrisi">обриши</DeleteBtn>
            </Grid>
        </Grid>
    )

    const onRowClick = (rowData) => {
        if (rowData.originalEvent.target.parentNode.name === 'izmeni') {
            setAction('izmeni')
            handleOpenModal(rowData)
        }
        if (rowData.originalEvent.target.parentNode.name === 'obrisi') {
            setAction('obrisi')
            handleOpenModal(rowData)
        }
    }

    return (
        <>
            {load ? (
                <SimpleModal />
            ) : (
                <>
                    <PageTitle title="шифрарници" />
                    <div>
                        <h3 className={classes.h4}>Приоритети</h3>
                        <div className={classes.addBtnDiv}>
                            <button
                                onClick={handleOpenAddModal}
                                type="button"
                                value="приоритет"
                                className={classes.addBtn}
                            >
                                Направи приоритет
                                <AddIcon />
                            </button>
                        </div>
                        <PriorityTable
                            priorities={priorities}
                            onRowClick={onRowClick}
                            actionBodyTemplate={actionBodyTemplate}
                            style={classes.tableDiv}
                        />
                        <h3 className={classes.h4}>Тип групе</h3>
                        <div className={classes.addBtnDiv}>
                            <button
                                onClick={handleOpenAddModal}
                                type="button"
                                value="подгрупа"
                                className={classes.addBtn}
                            >
                                Направи тип групе
                                <AddIcon />
                            </button>
                        </div>
                        <GroupTypesTable
                            types={types}
                            onRowClick={onRowClick}
                            actionBodyTemplate={actionBodyTemplate}
                            style={classes.tableDiv}
                        />
                        <Networks
                            networks={networks}
                            onRowClick={onRowClick}
                            actionBodyTemplate={actionBodyTemplate}
                            handleOpenAddModal={handleOpenAddModal}
                        />
                    </div>
                    <DynamicModal
                        openModal={open}
                        closeModal={handleCloseModal}
                        selected={selected}
                        action={action}
                        handleSubmit={handleSubmit}
                        handleDelete={handleDelete}
                        message={message}
                    />
                </>
            )}
        </>
    )
}

export default PrioritiesTypes
