import React, { useEffect, useState } from 'react'
import { Grid, LinearProgress } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import PropTypes, { objectOf } from 'prop-types'
import { trigger } from 'swr'
import FileHeader from './FileHeader'
import { axiosAuth } from '../../../util/axios-instance'
// import { axiosAuth } from '../../../util/axios-instance'

const SingleFileUploadWithProgress = ({
    file,
    onDelete,
    activity,
    setSeverity,
    setSnackbar,
    setError,
    setFiles,
}) => {
    // eslint-disable-next-line
    const { id } = useParams()
    const [progress, setProgress] = useState(0)

    useEffect(() => {
        const upload = async () => {
            try {
                const fileData = new FormData()

                fileData.append(`multi-files`, file.file)
                fileData.append(`fajl${1}`, file.file.name)

                const url =
                    activity.kratakNaziv === 'A1'
                        ? `/api/activity/tlocrt/${activity.lokacija.old_ID}/${activity.lokacija.ustanova}/0`
                        : `/api/activity/activity/${activity.lokacija.old_ID}/${activity.kratakNaziv}/${activity.returned}`

                await axiosAuth.patch(
                    url,
                    fileData,

                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                        onUploadProgress: (progressEvent) => {
                            setProgress(
                                Math.round(
                                    (progressEvent.loaded /
                                        progressEvent.total) *
                                        100
                                )
                            )
                        },
                    }
                )

                const filesUrl =
                    activity.kratakNaziv === 'A1'
                        ? `/api/files/get/all/files/tlocrt/${activity.lokacija.old_ID}/${activity.lokacija.ustanova}`
                        : `/api/files/get/all/files/activity/${activity.lokacija.old_ID}/${activity.kratakNaziv}`
                trigger(filesUrl)

                setTimeout(() => {
                    setFiles([])
                }, 500)
                setSeverity('success')
                setSnackbar({
                    open: true,
                    message: 'Успешно додат документ.',
                })
            } catch (error) {
                setSeverity('warning')
                setSnackbar({
                    open: true,
                    message: 'Неуспешно додавање документа!',
                })
                setError(error.response)
            }
        }
        upload()
    }, [])

    return (
        <Grid item>
            <FileHeader file={file.file} onDelete={onDelete} />
            <LinearProgress variant="determinate" value={progress} />
        </Grid>
    )
}

export default SingleFileUploadWithProgress
SingleFileUploadWithProgress.propTypes = {
    // file: PropTypes.instanceOf(File).isRequired,
    file: objectOf(PropTypes.any).isRequired,
    onDelete: PropTypes.func.isRequired,
    activity: objectOf(PropTypes.any).isRequired,
    setSeverity: PropTypes.func.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    setError: PropTypes.func.isRequired,
    setFiles: PropTypes.func.isRequired,
    // progress: PropTypes.number.isRequired,
}
