import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import moment from 'moment'
import { useDebounce } from 'use-debounce'
import { axiosAuth as axios } from '../../../util/axios-instance'
// import PropTypes from 'prop-types'
// import { DataTable } from 'primereact/datatable'
// import { InputText } from 'primereact/inputtext'
// import { Column } from 'primereact/column'
import UserTable from '../../../components/tables/UserTable'
import SimpleModal from '../../../components/Modal/Modal'
import PageTitle from '../../../components/FormsUI/PageTitle/PageTitle'
import latToCyr from '../../../util/latToCyr'

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    createbutton: {
        padding: 8,
        margin: '20px 0px',
    },
    root: {
        margin: 12,
        textAlign: 'left',
    },
    table: {
        margin: 8,
    },
    editButton: {
        backgroundColor: '#059D2A',
        color: 'white',
        '&:hover': {
            background: '#059D2A',
        },
    },
    deleteButton: {
        backgroundColor: '#D86565',
        color: 'white',
        '&:hover': {
            background: '#D86565',
        },
    },
    buttonW: {
        margin: '1rem 0',
    },
}))

const ReportFinished2 = () => {
    const classes = useStyles()
    const [load, setLoad] = useState(false)
    const [reports, setReports] = useState({ totalResult: 0, report: [] })
    // const [error, setError] = useState('')
    // eslint-disable-next-line
    const [page, setPage] = useState(0)
    // eslint-disable-next-line
    const [search, setSearch] = useState({ eng: '' })
    const [searchDebounced] = useDebounce(search, 2000)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    // const [locations, setLocations] = useState([])
    // const [totalResults, setTotalResults] = useState(0)

    // console.log(table)
    // const tableData =
    //     table &&
    //     table?.data?.reduce((acc, currentEl) => {
    //         // const groupArray = currentEl.odgovoran
    //         //     ?.map((el) => el.naziv)
    //         //     .join(', ')
    //         const addElement = {
    //             naziv: currentEl.kratakNaziv,
    //             // lokacija: currentEl.lokacija,
    //             status: currentEl.status,
    //         }
    //         acc.push(addElement)
    //         return acc
    //     }, [])

    // console.log(tableData)

    const getLastReport = async () => {
        try {
            setLoad(true)
            const url =
                // prettier-ignore
                searchDebounced.eng === ''
                ? `/api/report/finished?page=${page + 1}&limit=${rowsPerPage}`
                : `/api/report/finished?eng=${searchDebounced.eng}&srb=${searchDebounced.srb}&page=${page + 1}&limit=${rowsPerPage}`
            const { data } = await axios.get(
                url
                // `/api/report/finished?page=${page + 1}&limit=${rowsPerPage}`
            )

            const tableData = data?.locations.reduce((acc, currentEl) => {
                // const groupArray = currentEl.odgovoran
                //     ?.map((el) => el.naziv)
                //     .join(', ')

                const formatDate = moment(
                    currentEl?.completed?.datumZavrsetka
                ).format('DD/MM/YYYY')
                const addElement = {
                    nazivAktiv: currentEl.completed?.kratakNaziv,
                    lokacija: currentEl.naziv,
                    ugovor: currentEl?.ugovor?.naziv,
                    datumZav: formatDate,
                    status: currentEl?.completed?.status,
                    old_ID: latToCyr(currentEl?.lokacija_ID.toUpperCase()),
                    ustanova_ID: latToCyr(currentEl?.ustanova_ID.toUpperCase()),
                    opstina: currentEl.opstina ?? '-',
                    naselje: currentEl?.naselje ?? '-',
                    // status: currentEl.status,
                }
                acc.push(addElement)
                return acc
            }, [])

            setReports({
                totalResult: data.totalResults,
                report: tableData,
            })

            // const dataLength = parseInt(data.totalResults, 10)
            //  setTotalResults(dataLength)
            setLoad(false)
        } catch (err) {
            // setError(err)
            console.log(err.response)
            setLoad(true)
            setTimeout(() => {
                setLoad(false)
            }, 400)
        }
    }

    useEffect(() => {
        getLastReport()
    }, [page, rowsPerPage, searchDebounced])

    return (
        <div className={classes.root}>
            <div className={classes.table}>
                <Grid container justifyContent="center">
                    <PageTitle>Локације</PageTitle>
                </Grid>
                {load ? (
                    <SimpleModal />
                ) : (
                    <>
                        <UserTable
                            model="report/finished"
                            title="Листа завршених активности"
                            data={reports.report}
                            totalResults={reports.totalResult * 1}
                            page={page}
                            setPage={setPage}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                            onRowClick={() => {}}
                            name="iktReportfinished"
                            tableHeaders={[
                                {
                                    field: 'old_ID',
                                    header: 'ИД Локације',
                                },
                                {
                                    field: 'ustanova_ID',
                                    header: 'ИД Установе',
                                },
                                {
                                    field: 'lokacija',
                                    header: 'Назив локације',
                                },
                                {
                                    field: 'opstina',
                                    header: 'Општина',
                                },
                                {
                                    field: 'naselje',
                                    header: 'Насеље',
                                },
                                {
                                    field: 'nazivAktiv',
                                    header: 'Активност',
                                },
                                {
                                    field: 'ugovor',
                                    header: 'Уговор',
                                },
                                {
                                    field: 'datumZav',
                                    header: 'Датум завршетка',
                                },
                                {
                                    field: 'status',
                                    header: 'Статус',
                                },
                            ]}
                            setSearch={setSearch}
                            search={search}
                            exportFileName="Активности-завршене"
                            withSearch
                        />
                    </>
                )}
            </div>
        </div>
    )
}

export default ReportFinished2

// ReportLocations.propTypes = {
//     // load: PropTypes.bool.isRequired,
//     // activity: objectOf(PropTypes.any).isRequired,
//     table: PropTypes.objectOf(PropTypes.any).isRequired,
//     load: PropTypes.bool.isRequired,
// }
