const activities = [
    {
        id: 1,
        kratakNaziv: 'A1',
        naziv: 'Прибављање тлоцрта и коначно дефинисање типа школе (А-Ф)',
        naredna: ['A2', 'A5.3'],
        odgovoran: ['MTT'],
        rok: null,
        podaci: [],
        dokumenta: 'Тлоцрт',
        brojFajlova: 1,
    },

    {
        id: 2,
        kratakNaziv: 'A2',
        naziv: 'Закључeње уговора',
        prethodna: ['A1'],
        naredna: ['A3'],
        odgovoran: ['DOBT'],
        rok: null,
        podaci: [],
        dokumenta: null,
        brojFajlova: 0,
    },

    {
        id: 3,
        kratakNaziv: 'A3',
        naziv: 'Одобрењe за Site Survey (достављање упитника са контакт подацима и сагласношћу школе)',
        prethodna: ['A2'],
        naredna: ['A4'],
        odgovoran: ['MTT'],
        rok: null,
        podaci: [],
        dokumenta: 'Упитник',
        uputstvo:
            "MTT по пријему попуњеног и потписаног Упитника од школе додаје фајл са упитником у апликацију и затим поставља статус активности на 'Завршено'",
        brojFajlova: 1,
    },

    {
        id: 4,
        kratakNaziv: 'A4',
        naziv: 'Дефинисање датума Site survey-a и потврда истог са школом',
        prethodna: ['A3'],
        naredna: ['A5.1', 'A5.2'],
        podaci: ['Датум када ће бити обављен site survey.'],

        odgovoran: ['DOB'],
        rok: null,

        dokumenta: null,
        uputstvo:
            'Добављач договара датум обиласка школе са особом дефинисаном у Упитнику за тај део посла. ',
        brojFajlova: 0,
    },

    {
        id: 5,
        kratakNaziv: 'A5.1',
        naziv: 'Провера статуса АМРЕС линка у школи',
        prethodna: ['A4'],
        odgovoran: ['DOB'],
        rok: '90',
        podaci: [
            'Статус AМРЕС мреже Измерена брзина линка (https://www.speedtest.net/). Да ли је потребно измештање Л3 уређаја у орман са будућом активном опремом?',
        ],
        uputstvo:
            "Ова активност подразумева проверу статуса линка у школи приликом првог обиласка ради снимања стања. Добављач треба да постави следеће податке: Статус АМРЕС мреже (вредност се бира из шифарника) Да ли је потребно измештање Л3 уређаја у орман са будућом активном (да/не) Такође потребно је да унесе брзину линка измерену на локацији speedtest-om. Када све уради, може поставити статус активности на 'Завршено'",
        brojFajlova: 0,
    },

    {
        id: 6,
        kratakNaziv: 'A5.2',
        naziv: 'Обилазак школе - прикупљање свих информација неопходних за изради техничке документације за извођење',
        prethodna: ['A4'],
        naredna: ['A5.4'],
        odgovoran: ['DOB'],
        rok: '90',
        podaci: [],
        dokumenta:
            'Checklista: https://docs.google.com/spreadsheets/d/1nLvYAbhxI1X40anrwvKc9U5iPD8-dYAQ/edit?',
        uputstvo:
            'Овде можете скинути документ са инструкцијом односно чеклистом за снимање стања у школи.',
        brojFajlova: 1,
    },

    {
        id: 7,
        kratakNaziv: 'A5.3',
        naziv: 'Дефинисање (4 VLAN опсега за школу)',
        prethodna: ['A1'],
        odgovoran: ['AMR'],
        rok: '30',
        podaci: [],
        dokumenta: 'ИП адресе по ВЛАН',
        uputstvo:
            "АМРЕС уноси податке о дефинисаним ВЛАН-овима за одређену школу И поставља активност на статус 'Завршено' ",
        brojFajlova: 1,
    },

    {
        id: 8,
        sub: true,
        kratakNaziv: 'A5.4',
        naziv: 'Израда и достављање техничке документације за извођење (у складу са договореним темплејтом)',
        prethodna: ['A5.2'],
        naredna: ['A6'],
        odgovoran: ['DOB'],
        rok: '90',
        podaci: ['Ценa радова и опреме за школу према пројекту за извођење.'],
        dokumenta: 'ПЗИ',
        uputstvo:
            "Добављач уноси један или више фајлова који садрже ПЗИ (договорени темплејт, један или више PDF-ова плус XLS са предмером),вредност радова у школи са ПДВ-ом (опрема + материјал + радови) и поставља активност на статус 'Завршено' Темплејт ПЗИ: - један PDF са комплетним текстом и свим или делом цртежа. - опционо додатни цртежи у PDF формату - XLS са предмером и предрачуном (Ц1+Ц2+Ц3)",
        brojFajlova: 1,
    },

    {
        id: 9,
        kontrolna: true,
        kratakNaziv: 'A6',
        naziv: 'Одобрење техничке документације за извођење од стране стручног надзора',
        prethodna: ['A5.4'],
        naredna: ['A7'],
        odgovoran: ['NAD'],
        rok: '7',
        podaci: [],
        dokumenta: 'Примедбе на ПЗИ',
        brojFajlova: 1,
    },

    {
        id: 10,
        kratakNaziv: 'A7',
        naziv: 'Одобрење техничке документације за извођење од стране школе',
        prethodna: ['A6'],
        naredna: ['A8'],
        odgovoran: ['MTT'],
        rok: '7',
        podaci: [],
        dokumenta: null,
        brojFajlova: 0,
    },

    {
        id: 11,
        kratakNaziv: 'A8',
        naziv: 'Одобрење техничке документације за извођење од стране Наручиоца и одобрење за извођење радова',
        prethodna: ['A7'],
        naredna: ['A9', 'A12'],
        odgovoran: ['MTT'],
        rok: null,
        podaci: [],
        brojFajlova: 0,
    },
    {
        id: 12,
        kratakNaziv: 'A9',
        naziv: 'Договор око датума извођења радова са школом',
        prethodna: ['A8'],
        naredna: ['A10', 'A15'],
        odgovoran: ['DOB'],
        rok: '15',
        podaci: ['Датум извођења радова'],
        dokumenta: null,
        brojFajlova: 0,
    },

    {
        id: 13,
        kratakNaziv: 'A10',
        naziv: 'Потврда термина извођења радова од стране Наручиоца (обезбеђивање стручног надзора)',
        prethodna: ['A9'],
        naredna: ['A11'],
        odgovoran: ['MTT'],
        rok: null,
        podaci: [],
        dokumenta: null,
        brojFajlova: 0,
    },

    {
        id: 14,
        kratakNaziv: 'A11',
        naziv: 'Пријава радова и достављање одговарајуће документације Наручицу (пријава, решење именовању одговорног извођача)',
        prethodna: ['A10'],
        naredna: ['A13', 'A14'],
        odgovoran: ['DOB'],
        rok: '90',
        podaci: [],
        dokumenta:
            'Уноси се решење о именовању одговорног извођача; Пријава радова',
        uputstvo:
            'Уноси се решење о именовању одговорног извођача и пријава радова',
        brojFajlova: 1,
    },

    {
        id: 15,
        kratakNaziv: 'A12',
        naziv: 'Конфигурација активне опреме у складу са дефинисаним техничким решењем за повезивање шкослког ЛАН-а на АМРЕС',
        prethodna: ['A8'],
        odgovoran: ['DOB'],
        rok: '90',
        podaci: [],
        dokumenta: 'Конфигурација опреме',
        uputstvo:
            'Активност се може завршити без уноса фајлова са конфигурацијом опреме. Конфигурација се може додати накнадно.',
        brojFajlova: 0,
    },

    {
        id: 16,
        kratakNaziv: 'A13',
        naziv: 'Провера статуса АМРЕС линка у школи (приликом првог одласка у школу ради извођења како би се оставио простор за поправке)',
        prethodna: ['A11'],
        odgovoran: ['DOB'],
        rok: '90',
        podaci: [
            'Статус AМРЕС мреже. Измерена брзина линка (https://www.speedtest.net/).',
        ],
        dokumenta: '',
        uputstvo:
            'Уноси се статус АМРЕС линка или информација уколико недостаје рутер у школи.',
        brojFajlova: 0,
    },
    {
        id: 17,
        kratakNaziv: 'A14',
        naziv: 'Завршна мерења пасивног дела мреже',
        prethodna: ['A11'],
        naredna: ['A16'],
        odgovoran: ['DOB'],
        rok: '90',
        podaci: [],
        dokumenta: 'Мерење дела мреже',
        uputstvo:
            'Активност се може завршити без уноса фајлова са мерењима. Мерења се могу додати накнадно.',
        brojFajlova: 0,
    },

    {
        id: 18,
        kratakNaziv: 'A15',
        naziv: 'Отварање налога школских администратора за идентитете и додавање идентитета корисника у ИАМ',
        prethodna: ['A9'],
        odgovoran: ['MPN'],
        rok: '90',
        podaci: [],
        dokumenta: null,
        brojFajlova: 0,
    },

    {
        id: 19,
        kratakNaziv: 'A16',
        naziv: 'Израда дела документације изведеног стања и корисничких упутстава (део неопходан за употребу мреже од стране школе) (у складу са договореним темплејтом)',
        prethodna: ['A14'],
        naredna: ['A16.1', 'A17'],
        odgovoran: ['DOB'],
        rok: null,
        podaci: [],
        dokumenta: '',
        uputstvo:
            'У оквиру ове активности Добављач уноси упутство за употребу мреже и прилог са адресама активне опреме и креденцијалима за приступ опреми и WIFI мрежама у школи. Након завршене активности А16 очекујемо да видимо АП-ове на контролерима и та провера ће се радити кроз нову активност А16.1',
        brojFajlova: 1,
    },

    {
        id: 20,
        kratakNaziv: 'A16.1',
        naziv: 'Провера броја АП-ова асоцираних на контролер и могућности удаљеног приступа активној опреми у школи',
        prethodna: ['A16'],
        odgovoran: ['DOB'],
        rok: null,
        podaci: [
            'Број АП-ова асоцираних на контролер. Да ли број АП-ова који се виде на контролеру одговара броју АП-ова из ПЗИ? Да ли се може приступити удаљено свим свичевима? Да ли су креденцијали за свичеве исправно унети?',
        ],
        dokumenta:
            'Број АП-ова асоцираних на контролер Да ли број АП-ова који се виде на контролеру одговара броју АП-ова из ПЗИ? Да ли се може приступити удаљено свим свичевима? Да ли су креденцијали за свичеве исправно унети? Коментар ',
        uputstvo:
            'Добављач уноси потребне податке и завршава активност само ако је асоциран одговарајући број АП-ова и може се прићи удаљено свим свичевима. Уколико нешто није како треба Добављач уноси податке, али не завршава активност.',
        brojFajlova: 1,
    },

    {
        id: 21,
        kratakNaziv: 'A17',
        naziv: 'Пуштање мреже у рад (неформално)',
        prethodna: ['A16'],
        naredna: ['A18'],
        odgovoran: ['DOB'],
        rok: '3',
        podaci: [],
        dokumenta: 'Реверс o пријему опреме',
        uputstvo:
            'У оквиру ове активности Добављач качи реверс o пријему опреме на коришћење од стране школе',
        brojFajlova: 1,
    },

    {
        id: 22,
        sub: true,
        kratakNaziv: 'A18',
        naziv: 'Израда комплетне документације изведеног стања (у складу са договореним темплејтом)',
        prethodna: ['A17'],
        naredna: ['A19'],
        odgovoran: ['DOB'],
        rok: '90',
        podaci: [],
        dokumenta: 'ПИО',
        uputstvo: 'Добављач доставља ПИО.',
        brojFajlova: 1,
    },

    {
        id: 23,
        kontrolna: true,
        kratakNaziv: 'A19',
        naziv: 'Преглед документације изведеног стања и обилазак локације',
        prethodna: ['A18'],
        naredna: ['A20'],
        odgovoran: ['NAD'],
        rok: '7',
        podaci: [],
        dokumenta: 'Примедбе на ПИО',
        uputstvo: 'Евентуалне примедбе на ПИО из А18.',
        brojFajlova: 0,
    },

    {
        id: 24,
        kratakNaziv: 'A20',
        naziv: 'Овера ПИО и потписивање записника о примопредаји',
        prethodna: ['A19'],
        poslednja: true,
        odgovoran: ['NAD'],
        rok: '7',
        podaci: [],
        dokumenta: 'Записник о примопредаји',
        uputstvo: 'Надзор доставља записник о припмипредаји.',
        brojFajlova: 1,
    },
]

export default activities
