import React from 'react'
import { Formik, Form, Field, FieldArray } from 'formik'

import HighlightOffIcon from '@material-ui/icons/HighlightOff'

import {
    Button,
    Grid,
    Typography,
    makeStyles,
    CircularProgress,
    Paper,
} from '@material-ui/core'

import PropTypes from 'prop-types'
import { trigger } from 'swr'
import { TextField } from 'formik-material-ui'
import { array, string, object, number } from 'yup'
import AddIcon from '@material-ui/icons/Add'
import { useParams } from 'react-router-dom'
import { axiosAuth } from '../../../util/axios-instance'

/* eslint-disable */
const useStyles = makeStyles((theme) => ({
    errorColor: {
        color: theme.palette.error.main,
    },
    noWrap: {
        [theme.breakpoints.up('sm')]: {
            flexWrap: 'nowrap',
        },
    },
    fieldWrapper: {
        marginRight: '1rem',
        marginBottom: '1rem',
    },

    add: {
        padding: '20px 0',
        background: '#FCFCFC',
        margin: 0,
    },
    btnAdd: {
        margin: '10px 0',
        background: '#1E8637',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#4a9e5e',
            color: '#fff',
        },
    },
    addBtnWrapper: {
        marginBottom: '1rem',
    },
    addAll: {
        marginTop: 30,
    },

    delbtn: {
        color: '#a52408',
        fontSize: '1.7rem',
        '&:hover': {
            color: '#ffff',
            backgroundColor: '#f36344',
            borderRadius: '50%',
            cursor: 'pointer',
        },
    },
}))

const emptyData = {
    naziv: '',
    vrednost: '',
}

const AdditionalFields = ({
    setSeverity,
    setSnackbar,
    setError,
    handleNext,
    activity,
}) => {
    const classes = useStyles()
    const { id } = useParams()
    // console.log(
    //     'da li ima PODATAKa ',
    //     activity?.podaci.length > 0,
    //     'Ako nema onda',
    //     emptyData
    // )
    const initialDataValues = {
        additionalData:
            activity?.podaci.length > 0 ? activity.podaci : [emptyData],
    }
    const submitAdditionalData = async (values) => {
        // console.log(values)
        try {
            const { data } = await axiosAuth.patch(`/api/activity/${id}`, {
                podaci: values.additionalData,
            })

            // console.log(data)
            setSeverity('success')
            setSnackbar({
                open: true,
                message: 'Успешно додата додатна поља.',
            })
            trigger(`/api/activity/${id}`)
            handleNext()
        } catch (error) {
            setSeverity('warning')
            setSnackbar({
                open: true,
                message: 'Поља нису успешно додата!',
            })
            setError(error)
        }
    }

    // console.log(initialDataValues)
    return (
        <Grid container justifyContent="center">
            <Paper className={classes.add} elevation={0}>
                <Formik
                    initialValues={initialDataValues}
                    validationSchema={object({
                        additionalData: array(
                            object({
                                naziv: string()
                                    .required('обавезно поље')
                                    .min(1, 'минимално 1 карактер')
                                    .max(50, 'максимално 50 карактера'),
                                vrednost: number()
                                    .typeError('Морате унети број.')
                                    .required('обавезно поље'),
                            })
                        ),
                    })}
                    onSubmit={submitAdditionalData}
                >
                    {({ values, isSubmitting }) => (
                        <Form autoComplete="off">
                            <Grid container justifyContent="flex-start">
                                <Grid item>
                                    <FieldArray name="additionalData">
                                        {({ push, remove }) => (
                                            <>
                                                <Grid
                                                    container
                                                    justifyContent="flex-start"
                                                >
                                                    <Typography variant="body1">
                                                        Унос такси које је
                                                        потребно платити
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    container
                                                    justifyContent="flex-start"
                                                    className={
                                                        classes.addBtnWrapper
                                                    }
                                                >
                                                    <Button
                                                        variant="contained"
                                                        onClick={() =>
                                                            push(emptyData)
                                                        }
                                                        disabled={isSubmitting}
                                                        className={
                                                            classes.btnAdd
                                                        }
                                                        endIcon={<AddIcon />}
                                                    >
                                                        додај таксу
                                                    </Button>
                                                </Grid>

                                                {values.additionalData.map(
                                                    (data, idx) => (
                                                        <Grid
                                                            container
                                                            key={idx}
                                                            spacing={2}
                                                            alignItems="center"
                                                        >
                                                            <Grid
                                                                item
                                                                xs={5}
                                                                // className={
                                                                //     classes.gridFlex
                                                                // }
                                                            >
                                                                <Field
                                                                    name={`additionalData[${idx}].naziv`}
                                                                    component={
                                                                        TextField
                                                                    }
                                                                    label="Назив таксе"
                                                                    // className={
                                                                    //     classes.fieldWrapper
                                                                    // }
                                                                    variant="outlined"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                                <Field
                                                                    name={`additionalData.${idx}.vrednost`}
                                                                    component={
                                                                        TextField
                                                                    }
                                                                    label="Вредност таксе"
                                                                    // className={
                                                                    //     classes.fieldWrapper
                                                                    // }
                                                                    variant="outlined"
                                                                />
                                                            </Grid>

                                                            <Grid
                                                                item
                                                                xs={1}
                                                                className={
                                                                    classes.delBtnWrapper
                                                                }
                                                            >
                                                                <HighlightOffIcon
                                                                    className={
                                                                        classes.delbtn
                                                                    }
                                                                    onClick={() =>
                                                                        remove(
                                                                            idx
                                                                        )
                                                                    }
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                )}
                                            </>
                                        )}
                                    </FieldArray>
                                </Grid>
                                <Grid item xs={9}>
                                    <Button
                                        className={classes.addAll}
                                        disabled={
                                            isSubmitting ||
                                            values.additionalData.length === 0
                                        }
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        startIcon={
                                            isSubmitting ? (
                                                <CircularProgress size="0.9rem" />
                                            ) : undefined
                                        }
                                    >
                                        {isSubmitting
                                            ? 'Слање...'
                                            : 'унеси таксе'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Paper>
        </Grid>
    )
}

export default AdditionalFields

AdditionalFields.propTypes = {
    setSeverity: PropTypes.func.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    handleNext: PropTypes.func.isRequired,
    activity: PropTypes.objectOf(PropTypes.any).isRequired,

    setError: PropTypes.func.isRequired,
}
